import React from "react";
import Store from "../store/store";
import { getAllUsers } from "../store/store";
import { observer } from "mobx-react";
import axios from "axios";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal/Modal";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IRole } from "../parametersRoles/interfaces/IRole";
import Spinner from "../Spinner/Spinner";
import { IDBUser } from "../Workflow/interfaces/IWorkflow";
const styles = require("./styles/listUsers_styles.module.scss");
const modal = require("../../styles/modal.scss");

interface IModal {
  open?: boolean;
  id?: number;
  loading: {
    modal: boolean;
    deleteButton: boolean;
  },
  error: {
    role: boolean;
  },
  roleAssociatedToUser: IRole[];
  groupAssociatedToUser: IDBUser[];
}

@observer
export default class ParametersUser extends React.Component<RouteComponentProps<any>, IModal> {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      id: null,
      loading: {
        modal: false,
        deleteButton: false
      },
      error: {
        role: false
      },
      roleAssociatedToUser: [],
      groupAssociatedToUser: []
    };
    getAllUsers();
  }



  componentDidMount() {
  }

  public toolbox = (cell: any, row: any) => {
    return (
      <div className={styles.listActionContainer}>
        {Store.userConnected.typeUserLydoc == "admin" ?
          <div className={styles.iconEdit} onClick={() => this.editRow(row)}><Icon style={{ fontSize: 20 }}>mode_edit</Icon></div>
          : ""}
        {Store.userConnected.typeUserLydoc == "admin" ?
          <div className={styles.iconEdit} onClick={() => this.modalOpen(row.id)}><Icon style={{ fontSize: 20 }}>delete</Icon></div>
          : ""}

      </div>
    );
  }
  editRow = (row: any) => {
    Store.myUser = {
      id: row.id,
      civility: row.civility,
      lastname: row.lastname,
      firstname: row.firstname,
      matricule: row.matricule,
      email: row.email,
      login: row.login,
      password: row.password,
      id_service: row.id_terme_service, // john
      id_fonction: row.id_terme_fonction, // john
      id_profession: row.id_terme_profession, // john
      typeUserLydoc: row.typeUserLydoc,
      formRights: row.formRights,
      typeUser: row.typeUser,
      accessConsult: row.accessConsult, // john
      accessRef: row.accessRef, // john
      accessParam: row.accessParam,
      accessSignalement: row.accessSignalement,
      id_fonctionResponsable: { ActionReferent: [], InfoResponsable: [] }, // thomas
      id_professionResponsable: { ActionReferent: [], InfoResponsable: [] }, // Thomas
      id_serviceResponsable: { ActionReferent: [], InfoResponsable: [] }, // thomas
      id_riskResponsable: { ActionReferent: [], InfoResponsable: [] } // thomas */
    };
    Store.contextualmenu.content = "editUser";
    Store.contextualmenu.open = "extended";
    // axios
    //   .get(Store.wsPath + "/1/users/getUserById", { params: { id: id } })
    //   .then(response => {


    //   });
  };

  deleteUser = (id: number) => event => {
    this.setState({ loading: { ...this.state.loading, deleteButton: true } }, () => {
      axios.post(Store.wsPath + "/1/users/SetStatusUserbyId", {
        id: id,
        roleAssociated: this.state.roleAssociatedToUser.map(role => role.id)
      }).then((response) => {
        if (response.status == 200) {
          this.modalClose();
          // Snack Bar
          Store.snackBar = {
            message: "L'utilisateur à bien été supprimé",
            error: false,
            open: true
          };
          getAllUsers();
        }
        else {
          // Snack Bar
          Store.snackBar = {
            message: "Une erreur c'est produite lors de la suppression, veuillez réessayer",
            error: true,
            open: true
          };
          this.setState({ error: { ...this.state.error, role: true } });
        }
      }).catch(error => {
        console.error("Error 'deleteUser' : ", error);
        // Snack Bar
        Store.snackBar = {
          message: "Une erreur c'est produite lors de la suppression, veuillez réessayer",
          error: true,
          open: true
        };
        this.setState({ error: { ...this.state.error, role: true } });
      });
    });
  };

  modalClose = () => {
    this.setState({ open: false, id: null, loading: { modal: false, deleteButton: false }, error: { role: false }, roleAssociatedToUser: [] });
  };

  modalOpen = (id: number) => {
    this.setState({ open: true, id: id, loading: { deleteButton: false, modal: true } }, () => {
      // On get les roles associés aux groupes
      axios.get(Store.wsPath + '/1/roles/getAllRoleByIdUser', {
        params: {
          idUser: id
        }
      }).then(responseRole => {
        axios.get(Store.wsPath + '/1/paramGroupes/getAllGroupForAnUser', {
          params: {
            idUser: id
          }
        }).then(responseGroup => {
          this.setState({ roleAssociatedToUser: responseRole.data, groupAssociatedToUser: responseGroup.data, loading: { ...this.state.loading, modal: false } });
        }).catch(error => {
          console.error("Error 'modalOpen' : ", error);
          Store.snackBar = {
            message: "Une erreur c'est produite, veuillez réessayer",
            error: true,
            open: true
          };
          this.setState({ loading: { ...this.state.loading, modal: false }, error: { role: true } })
        });
      }).catch(error => {
        console.error("Error 'modalOpen' : ", error);
        Store.snackBar = {
          message: "Une erreur c'est produite, veuillez réessayer",
          error: true,
          open: true
        };
        this.setState({ loading: { ...this.state.loading, modal: false }, error: { role: true } })
      });
    });
  };

  addUser = () => {
    Store.myUser = Store.DefaultMyUser;
    Store.contextualmenu.content = "addUser";
    Store.contextualmenu.open = "extended";
  }
  public render() {
    const options = {
      sortIndicator: true,
      defaultSortName: 'firstname',
      noDataText: 'Aucun utilisateur à afficher',
      sizePerPage: 25,
    };

    return (
      <div className={styles.contentListUsers}>
        <div className={styles.usersTitle} style={{ background: Store.themeParam.backgroundPrimary }}><h5>Utilisateurs</h5></div>

        {(Store.userConnected.typeUserLydoc == "admin" || Store.userConnected.typeUserLydoc == "ged") && Store.authMode != "AAD" && Store.authMode != "AD" ?
          <div className={styles.buttonUserContent}>
            <Button
              onClick={this.addUser}
              variant="contained"
              color="primary"
            >
              <Icon>add</Icon> Ajouter un utilisateur
      </Button>
          </div>
          : ""}

        {Store.allUsers ?
          <div className={styles.usersContent}>
            <BootstrapTable
              multiColumnSearch
              data={Store.allUsers.filter(user => user.isGroup != true)}
              searchPlaceholder="Rechercher "
              search={true}
              pagination
              version="4"
              ref="table"
              options={options}
            >
              <TableHeaderColumn isKey={true} hidden dataField="id">
                Id
              </TableHeaderColumn>
              {/* <TableHeaderColumn headerAlign='center' dataAlign='center' width='10%' dataSort={true} dataField="civility">
                Civilité
              </TableHeaderColumn> */}
              <TableHeaderColumn headerAlign='center' dataAlign='center' dataSort={true} dataField="firstname">
                Prénom
                </TableHeaderColumn>
              <TableHeaderColumn headerAlign='center' dataAlign='center' dataSort={true} dataField="lastname">
                Nom
                </TableHeaderColumn>

              {/* <TableHeaderColumn headerAlign='center' dataAlign='center' dataSort={true} tdStyle={{ whiteSpace: 'normal' }} dataField="profession">
                Profession
                </TableHeaderColumn> */}
              {/* <TableHeaderColumn headerAlign='center' dataAlign='center' dataSort={true} tdStyle={{ whiteSpace: 'normal' }} dataField="service">
                Service
                
                </TableHeaderColumn> */}
              <TableHeaderColumn headerAlign='center' dataAlign='center' dataSort={true} dataField="email">
                Email
              </TableHeaderColumn>
              <TableHeaderColumn headerAlign='center' dataAlign='center' dataSort={true} dataField="login">
                Login
              </TableHeaderColumn>
              <TableHeaderColumn headerAlign='center' width={Store.userConnected.typeUserLydoc == "admin" ? '10%' : '5%'} dataField='id' tdStyle={{ padding: '0', verticalAlign: 'inherit' }} dataFormat={this.toolbox.bind(this)}>Actions</TableHeaderColumn>
            </BootstrapTable>
          </div>
          : ""}

        <Modal
          open={this.state.open}
          className={modal.modal}
          onClose={this.modalClose}
        >
          <div className={modal.modalContent}>
            {
              this.state.error.role == true ?
                <React.Fragment>
                  <Typography variant="h5">
                    ⚠️Erreur
                  </Typography>
                  <br />
                  <Typography variant="subtitle2">
                    Une erreur est survenue, veuillez réesayer...
                  </Typography>
                  <br />
                  <div className={modal.modalButton}>
                    <Button
                      onClick={this.modalClose}
                      className={modal.button}
                      variant="contained"
                      color="secondary"
                    >
                      Fermer
                    </Button>
                  </div>
                </React.Fragment>
                :
                this.state.loading.modal == true ?
                  <Spinner fullWidth={true} />
                  :
                  <React.Fragment>
                    <Typography variant="h5">
                      Suppression
                    </Typography>
                    <br />
                    {
                      this.state.roleAssociatedToUser.length > 0 ?
                        <React.Fragment>
                          <Typography variant="subtitle2">
                            Cet utilisateur est associé aux rôles suivants :
                          </Typography>
                          <ul>
                            {
                              this.state.roleAssociatedToUser.map(role => {
                                return (<li key={role.id + role.name}>{role.name}</li>);
                              })
                            }
                          </ul>
                          <Typography style={{ color: "red" }} variant="subtitle2">
                            Si vous supprimez cet utilisateur il sera également supprimé des rôles ci-dessus.
                          </Typography>
                          <Typography variant="subtitle2">
                            Vous devrez peut-être mettre à jour ces rôles avec un autre groupe/utilisateur.<br />
                            Tips: Vous pouvez aller mettre à jour les rôles ci-dessus avant de supprimer l'utilisateur.
                          </Typography>
                          <br />
                        </React.Fragment>
                        :
                        <React.Fragment />
                    }
                    {
                      this.state.groupAssociatedToUser.length > 0 ?
                        <React.Fragment>
                          <Typography variant="subtitle2">
                            Cet utilisateur fait parti des groupes suivants :
                          </Typography>
                          <ul>
                            {
                              this.state.groupAssociatedToUser.map(group => {
                                return (<li key={group.id + group.firstname}>{group.firstname}</li>);
                              })
                            }
                          </ul>
                          <Typography style={{ color: "red" }} variant="subtitle2">
                            Si vous supprimez cet utilisateur il sera également supprimé des groupes ci-dessus.
                          </Typography>
                          <Typography variant="subtitle2">
                            Si l'un des groupes se retrouve vide, le groupe sera supprimé des rôles ou il a été attribué.
                          </Typography>
                          <br />
                        </React.Fragment>
                        :
                        <React.Fragment />
                    }
                    <Typography variant="subtitle2">
                      Vous allez supprimer cet utilisateur. Etes vous sûres de cette action ?
                    </Typography>
                    <br />
                    <div className={modal.modalButton}>
                      <Button
                        onClick={this.modalClose}
                        className={modal.button}
                        variant="contained"
                        color="secondary"
                        disabled={this.state.loading.deleteButton}
                      >
                        Annuler
                      </Button>
                      <Button
                        className={modal.button}
                        onClick={this.deleteUser(this.state.id)}
                        variant="contained"
                        color="primary"
                        disabled={this.state.loading.deleteButton}
                      >
                        {
                          this.state.loading.deleteButton == true ?
                            <Spinner size={24} />
                            :
                            "Valider"
                        }
                      </Button>
                    </div>
                  </React.Fragment>
            }
          </div>
        </Modal>
      </div>
    );
  }
}
