import * as React from 'react';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import axios from 'axios';
import Store from '../store/store';
import TextField from '@material-ui/core/TextField';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Spinner from '../Spinner/Spinner';
import { getAllUsers } from '../store/store';
import cookie from "react-cookies";
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { IParamUser } from '../parametersUsers/interfaces/IUser';
const styles = require('./styles/Me.scss');

interface Props {

}
interface State {
  loading: {
    loadingInfo: boolean;
    loadingParams: boolean;
  };
  updatedUservalue: { lastname: string, firstname: string, email: string };
  openDeconnectModal: boolean;
  paramUser: IParamUser;
}

export default class Me extends React.Component<RouteComponentProps<any>, State>{
  constructor(props: any) {
    super(props)
    this.state = {
      loading: {
        loadingInfo: false,
        loadingParams: false
      },
      updatedUservalue: { lastname: Store.userConnected.lastname, firstname: Store.userConnected.firstname, email: Store.userConnected.email },
      paramUser: {
        stayOnProjectAfterOrientation: Store.userConnected.paramUser != undefined && Store.userConnected.paramUser != null && Store.userConnected.paramUser.stayOnProjectAfterOrientation != undefined ? Store.userConnected.paramUser.stayOnProjectAfterOrientation : false,
        mailOpenTask: Store.userConnected.paramUser != undefined && Store.userConnected.paramUser != null && Store.userConnected.paramUser.mailOpenTask != undefined ? Store.userConnected.paramUser.mailOpenTask : false,
        mailModifTask: Store.userConnected.paramUser != undefined && Store.userConnected.paramUser != null && Store.userConnected.paramUser.mailModifTask != undefined ? Store.userConnected.paramUser.mailModifTask : false,
        mailClotureTask: Store.userConnected.paramUser != undefined && Store.userConnected.paramUser != null && Store.userConnected.paramUser.mailClotureTask != undefined ? Store.userConnected.paramUser.mailClotureTask : false,
      },
      openDeconnectModal: false
    }
  }

  // Fonction appellée lors du click sur les options des params
  onChangeParams = status => event => {
    const paramUser = { ...this.state.paramUser };
    if (status == 'Open') {
      paramUser.mailOpenTask = event.target.checked;
    }
    else if (status == 'Modif') {
      paramUser.mailModifTask = event.target.checked;
    }
    else if (status == 'Close') {
      paramUser.mailClotureTask = event.target.checked;
    }
    else if (status == 'Orientation') {
      paramUser.stayOnProjectAfterOrientation = event.target.checked;
    }
    this.setState({ paramUser: paramUser });
  }

  // Fonction appelée sur le bouton pour mettre à jour les params sur le profil de l'utilisateur
  onEditParamUser = () => {
    this.setState({ loading: { ...this.state.loading, loadingParams: true } }, () => {
      axios.post(Store.wsPath + '/1/users/updateParamUser', {
        id: Store.userConnected.id,
        paramUser: this.state.paramUser
      }).then(response => {
        if (response.status == 200) {
          getAllUsers();
          Store.userConnected.paramUser = this.state.paramUser;
          this.setState({ loading: { ...this.state.loading, loadingParams: false } });
          // SnackBar
          Store.snackBar = {
            message: "Paramétrage enregistrés",
            error: false,
            open: true
          };
        }
        else {
          Store.snackBar = {
            message: "Erreur lors de la mise à jour, veuillez réessayer",
            error: true,
            open: true
          };
        }
      }).catch(error => {
        this.setState({ loading: { ...this.state.loading, loadingParams: false } });
        console.error("Error 'onEditParamUser' : ", error);
        Store.snackBar = {
          message: "Erreur lors de la mise à jour, veuillez réessayer",
          error: true,
          open: true
        };
      });
    });
  }

  // Fonction appelée pour mettre à jour les données de l'utilisateur connecté au click du bouton enregistrer
  public onEditUser = (event) => {
    event.preventDefault();
    this.setState({ loading: { ...this.state.loading, loadingInfo: true } }, () => {
      axios.post(Store.wsPath + '/1/users/updateUser', {
        id: Store.userConnected.id,
        lastname: this.state.updatedUservalue.lastname,
        firstname: this.state.updatedUservalue.firstname,
        login: Store.userConnected.login,
        email: this.state.updatedUservalue.email,
        civility: Store.userConnected.civility,
        typeUserLydoc: Store.userConnected.typeUserLydoc,
        formRights: Store.userConnected.formRights
      }).then(response => {
        getAllUsers();
        Store.userConnected.lastname = this.state.updatedUservalue.lastname;
        Store.userConnected.firstname = this.state.updatedUservalue.firstname;
        Store.userConnected.email = this.state.updatedUservalue.email;
        this.setState({ loading: { ...this.state.loading, loadingInfo: false } })
        // SnackBar
        Store.snackBar = {
          message: "Vos informations ont été mises à jour",
          error: false,
          open: true
        };
      }).catch(error => {
        console.error("Error 'onEditUser' : ", error);
        this.setState({ loading: { ...this.state.loading, loadingInfo: false } });
        Store.snackBar = {
          message: "Erreur lors de la mise à jour, veuillez réessayer",
          error: true,
          open: true
        };
      });
    });
  }

  // Fonction appelée sur le onChange des  infos de l'utilisateur
  onChangeUser = (name) => event => {
    const tempValue = this.state.updatedUservalue
    if (name == "firstname") {
      tempValue.firstname = event.target.value;
    } else if (name == "lastname") {
      tempValue.lastname = event.target.value;
    } else if (name == "email") {
      tempValue.email = event.target.value;
    }
    this.setState({ updatedUservalue: tempValue });
  }

  // Fonction appelée pour la deconnexion d'un utilisateur
  public onDeconnectUser = async () => {
    cookie.remove("token");
    if (Store.authMode == "AAD") {
      const idUser: number = Store.userConnected.id;
      await window.location.assign(Store.wsPath + "/1/authentication/aadLogout?idUser=" + idUser);
    } else {
      this.props.history.push("/");
      Store.userConnected = {};
    }
  };

  // Fonction pour ouvrir la modale de confirmation de déconnexion
  public openCloseDecoModal = (open?: boolean) => (event: any) => {
    if (open == true) {
      this.setState({ openDeconnectModal: true });
    }
    else {
      this.setState({ openDeconnectModal: false });
    }
  }

  public render() {
    return (
      <div className={styles.mainContent}>
        <div className={styles.titleParam} style={{ background: Store.themeParam.backgroundPrimary }}>
          <h5>Compte</h5>
        </div>
        <div style={{ margin: "0 20px 50px 20px" }}>
          <form autoComplete="false" onSubmit={this.onEditUser}>
            <div>
              <TextField
                variant='outlined'
                className={styles.field}
                required
                type="text"
                value={this.state.updatedUservalue.lastname}
                onChange={this.onChangeUser('lastname')}
                label="Nom"
              />
            </div>
            <div>
              <TextField
                name="firstname"
                value={this.state.updatedUservalue.firstname}
                label="Prénom"
                onChange={this.onChangeUser("firstname")}
                margin="normal"
                variant='outlined'
                type="text"
                className={styles.field}
              />
            </div>
            <div>
              <TextField
                name="email"
                value={this.state.updatedUservalue.email}
                label="Email de l'utilisateur"
                onChange={this.onChangeUser("email")}
                margin="normal"
                variant='outlined'
                required
                type="email"
                className={styles.field}
              />
            </div>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={this.state.loading.loadingInfo}
            >
              {this.state.loading.loadingInfo ?
                <Spinner label="Sauvegarde en cours..." size={16} />
                :
                "Enregistrer les modifications"
              }
            </Button>
          </form>

          <Button
            variant="contained"
            color="secondary"
            startIcon={<PowerSettingsNewIcon />}
            onClick={this.openCloseDecoModal(true)}
            style={{ marginTop: "15px" }}
          >
            Déconnexion
          </Button>


          {/* MODAL pour la confirmation de déconnexion */}
          <Dialog
            maxWidth='xs'
            open={this.state.openDeconnectModal}
            onClose={this.openCloseDecoModal}
            aria-labelledby="Déconnexion"
            aria-describedby="Fenêtre de déconnexion"
          >
            <DialogTitle>
              <div>Déconnexion</div>
            </DialogTitle>
            <DialogContent>
              <p>Voulez vous vraiment vous déconnecter ?</p>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={this.openCloseDecoModal()}>
                annuler
              </Button>
              <Button variant="contained" onClick={this.onDeconnectUser} color="secondary" autoFocus>
                Déconnexion
              </Button>
            </DialogActions>
          </Dialog>

        </div>

        <div className={styles.titleParam} style={{ background: Store.themeParam.backgroundPrimary }}>
          <h5>Paramètres du compte</h5>
        </div>
        <div>
          <div className={styles.listParams}>
            <h5>Mails</h5>
            <div>
              Envoyer un mail automatiquement lors de l'ouverture d'une de vos tâches ?
                  <Checkbox className={styles.paddingCheckbox} color='primary' checked={this.state.paramUser.mailOpenTask} onChange={this.onChangeParams('Open')} />
            </div>
            <div>
              Envoyer un mail automatiquement lors de la modification d'une de vos tâches ?
                  <Checkbox className={styles.paddingCheckbox} color='primary' checked={this.state.paramUser.mailModifTask} onChange={this.onChangeParams('Modif')} />
            </div>
            <div>
              Envoyer un mail automatiquement lors de la cloture d'une de vos tâches ?
                  <Checkbox className={styles.paddingCheckbox} color='primary' checked={this.state.paramUser.mailClotureTask} onChange={this.onChangeParams('Close')} />

            </div>
          </div>
          <div className={styles.listParams}>
            <h5>Orientation</h5>
            <div>
              Rester sur le circuit actif après validation d'une orientation ?
                  <Checkbox className={styles.paddingCheckbox} color='primary' checked={this.state.paramUser.stayOnProjectAfterOrientation} onChange={this.onChangeParams('Orientation')} />
            </div>
          </div>

          <div className={styles.button}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => { this.onEditParamUser() }}
              disabled={this.state.loading.loadingParams}
            >
              {this.state.loading.loadingParams ?
                <Spinner label="Sauvegarde en cours..." size={16} />
                :
                "Enregistrer les préférences"
              }
            </Button>
          </div>
        </div>

      </div>
    )
  }
}
