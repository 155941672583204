import React, { Component } from "react";
import { observer } from "mobx-react";
import Store from "../store/store";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import AppBar from "@material-ui/core/AppBar";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Icon from "@material-ui/core/Icon";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { IDelegation, IModelDelegation } from "./interfaces/delegation";
import { IDBWfTask } from "../Workflow/interfaces/IWorkflow";
import { useQuery } from "./../helper/helpers";
import axios from "axios";
import Spinner from "../Spinner/Spinner";
const styles = require('./styles/Delegation.scss');
const modal = require("../../styles/styles.module.scss");

interface Props {

}

interface State {
    displayList: IDelegation[];
    allDeleg: IDelegation[];
    modelsDeleg: IModelDelegation[];
    whatDisplay: string;
    selectedDeleg: IDelegation;
    selectedModel: IModelDelegation;
    delegedTasks: IDBWfTask[];
    modalDelete: boolean;
    showTasks: boolean;
    modalStop: boolean;
    modalStart: boolean;
    modalModelDelete: boolean;
    selectedDateBegin: Date;
    selectedDateEnd: Date;
    loadingModel: boolean;
    loadingDeleg: boolean;
    loadingTask: boolean;
    allTaskSelectedIds: number[];
    loadingStopDeleg: boolean;
    loadingStartDeleg: boolean;
    loadingDelete: boolean;
    toDisplay: any[];
}

@observer
class Delegation extends Component<RouteComponentProps<{}> & any, State>{
    public constructor(props: Props) {
        super(props);
        const display = useQuery().get("display")
        this.state = {
            displayList: [],
            whatDisplay: display != undefined ? display : "enCours",
            selectedDeleg: undefined,
            selectedModel: undefined,
            delegedTasks: [],
            modalDelete: false,
            modelsDeleg: [],
            allDeleg: [],
            showTasks: false,
            modalStop: false,
            modalStart: false,
            modalModelDelete: false,
            selectedDateBegin: undefined,
            selectedDateEnd: undefined,
            loadingDeleg: true,
            loadingModel: true,
            loadingTask: false,
            allTaskSelectedIds: [],
            loadingStopDeleg: false,
            loadingStartDeleg: false,
            loadingDelete: false,
            toDisplay: []
        };
    }
    componentDidMount(): void {
        this.getDelegation();
        this.getModelsDelegation();
    }
    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<State>, snapshot?: any): void {

    }
    getModelsDelegation = () => {
        axios.get(Store.wsPath + "/1/delegation/GetAllModelDelegationByUser", {
            params: {
                idUser: Store.userConnected.id
            }
        }).then(response => {
            const modeles = response.data.map((model: IModelDelegation) => {
                return {
                    ...model,
                    userToDisplay: this.getUserTo(model.UserTo, model),
                    userFromDisplay: this.getUserFrom(model.UserFrom, model),
                    circuitDisplay: this.getModelCircuit(model.WfModel, model)
                }
            })
            this.setState({
                loadingModel: false,
                modelsDeleg: modeles
            });
        }).catch(error => {
            this.setState({
                loadingModel: false,
            });
            Store.snackBar = {
                open: true,
                error: true,
                message: "Une erreur est survenue au chargement des modèles."
            }
            console.error('ERROR \'getModelsDelegations\': ', error)
        })
    }
    getDelegation = () => {
        axios.get(Store.wsPath + "/1/delegation/GetAllDelegation", {
            params: {
                idUser: Store.userConnected.id,
                typeUserLydoc: Store.userConnected.typeUserLydoc
            }
        }).then(response => {
            const delegs = response.data.map((deleg: IDelegation) => {
                return {
                    ...deleg,
                    userToDisplay: this.getUserTo(deleg.UserTo, deleg),
                    userFromDisplay: this.getUserFrom(deleg.UserFrom, deleg),
                    circuitDisplay: this.getModelCircuit(deleg.WfModel, deleg),
                    dateStartDisplay: this.getDateFormat(deleg.dateStart, deleg),
                    dateEndDisplay: this.getDateFormat(deleg.dateEnd, deleg)
                }
            })
            this.setState({
                loadingDeleg: false,
                allDeleg: delegs
            });
            Store.allDeleg = delegs;
        }).catch(error => {
            this.setState({
                loadingDeleg: false,
            });
            Store.snackBar = {
                open: true,
                error: true,
                message: "Une erreur est suvenue au chargement des délégations."
            }
            console.error('ERROR \'getDelegation\': ', error)
        })// WS GET 

    }

    // Get de toutes les tâches déléguées par rapport à une délégation
    getAllTasksByDelegationId = (delegationId: number) => {
        this.setState({ loadingTask: true, delegedTasks: [] }, () => {
            axios.get(Store.wsPath + "/1/delegation/GetAllTaskByDelegationId", {
                params: {
                    idDelegation: delegationId
                }
            }).then(response => {
                this.setState({
                    loadingTask: false,
                    delegedTasks: response.data
                });
            }).catch(error => {
                this.setState({
                    loadingTask: false,
                });
                Store.snackBar = {
                    open: true,
                    error: true,
                    message: "Une erreur est suvenue au chargement des tâches."
                }
                console.error('ERROR \'getAllTasksByDelegationId\': ', error)
            });
        });
    }

    // BOOTSTRAPTABLE dataformat Begin
    getModelCircuit = (cell, row: any) => {
        if (row.idModel == null) {
            return "Tous les modèles";
        }
        if (row.WfModel == undefined){
            return "Aucun modèle trouvé"
        }
        return row.WfModel.name;
    }
    getUserFrom = (cell, row: any) => {
        if (row.UserFrom == undefined || row.UserFrom == null) {
            return "Utilisateur introuvable";
        }
        return row.UserFrom.firstname + " " + row.UserFrom.lastname;
    }
    getUserTo = (cell, row: any) => {
        if (row.UserTo == undefined || row.UserTo == null) {
            return "Utilisateur introuvable";
        }
        return row.UserTo.firstname + " " + row.UserTo.lastname;
    }
    getDateFormat = (cell, row: IDelegation) => {
        let dateFormated: string = "Pas de date";
        if (cell != null) {
            dateFormated = new Date(cell).toLocaleDateString();
        }
        return dateFormated;
    }
    getCircuit = (cell, row: IDBWfTask) => {
        return <div className={styles.tdElement}>{row.WfStep.WfProject.name}</div>;
    }
    getActions = (type) => (cell, row) => {
        if (type == "modele") {
            return (
                <div className={styles.actionTable}>
                    <div className={styles.actionVert} title="Démarrer"><Icon className={styles.iconAction} onClick={this.onModelStart(row)}>play_arrow</Icon></div>
                    <div className={styles.actionBleu} title="Programmer"><Icon className={styles.iconAction} onClick={this.onModelProgram(row)}>watch_later</Icon></div>
                    <div className={styles.actionJaune} title="Modifier"><Icon className={styles.iconAction} onClick={this.onEditModel(row)}>edit</Icon></div>
                    <div className={styles.actionRouge} title="Supprimer"><Icon className={styles.iconAction} onClick={this.openModalModelDelete(row)}>delete</Icon></div>
                </div>
            )
        } else if (type === "statut" && (row.delegateFrom === Store.userConnected.id || Store.userConnected.typeUserLydoc === "ged" || Store.userConnected.typeUserLydoc === "admin")) {
            switch (row.status) {
                case "programmée":
                    return (
                        <div className={styles.actionTable}>
                            <div className={styles.actionVert} title="Démarrer"><Icon className={styles.iconAction} onClick={this.openModalStart(row)}>play_arrow</Icon></div>
                            <div className={styles.actionJaune} title="Modifier"><Icon className={styles.iconAction} onClick={this.onEditDeleg(row)}>edit</Icon></div>
                            <div className={styles.actionRouge} title="Supprimer"><Icon className={styles.iconAction} onClick={this.openModalDelete(row)}>delete</Icon></div>
                        </div>
                    );
                case "enCours":
                    return (
                        <div className={styles.actionTable}>
                            <div className={styles.actionRouge} title="Arrêter"><Icon className={styles.iconAction} onClick={this.openModalStop(row)}>stop</Icon></div>
                            <div className={styles.actionJaune} title="Modifier"><Icon className={styles.iconAction} onClick={this.onEditDeleg(row)}>edit</Icon></div>
                            <Badge
                                className={styles.badge}
                                badgeContent={row.WfTasks.filter(t => t.state == "En cours" && t.isDelegate).length}
                                variant="dot"
                                color="primary"
                            >
                                <div className={styles.actionBleu} title="Afficher les tâches"><Icon className={styles.iconAction} onClick={this.openModalTask(row)}>visibility</Icon></div>
                            </Badge>
                        </div>
                    );
                case "terminée":
                    return (
                        <div className={styles.actionTable}>
                            <Badge
                                className={styles.badge}
                                badgeContent={row.WfTasks.filter(t => t.state == "En cours" && t.isDelegate).length}
                                variant="dot"
                                color="primary"
                            >
                                <div className={styles.actionBleu} title="Afficher les tâches"><Icon className={styles.iconAction} onClick={this.openModalTask(row)}>visibility</Icon></div>
                            </Badge>
                        </div>
                    );
            }
        }
    }
    getRedirectTask = (cell, row) => {
        return (
            <div style={{ cursor: 'pointer' }} title="Rediriger vers le circuit"><Icon onClick={this.onSelectTask(row)}>visibility</Icon></div>
        )
    }
    // BOOTSTRAPTABLE dataformat End

    onChangeDisplay = (event, newValue) => {
        this.setState({
            whatDisplay: newValue,
        }, () => {
            const query = useQuery();
            const display = query.get('display');
            if (display != undefined && display != null && display.length > 0) {
                query.set("display", newValue);
            }
            else {
                query.append("display", newValue);
            }
            window.history.replaceState(null, null, '/delegation?' + query);
        });
    }
    onSelectTask = (row) => event => {
        const query = useQuery();
        query.append("s", row.WfStep.id.toString());
        query.append("t", row.id.toString());
        query.append("w", row.WfStep.projetId.toString());
        this.props.history.push("/document?" + query);
    }
    onEditModel = (row) => event => {
        Store.modelDelegation = {
            model: row,
            all: this.state.modelsDeleg
        }
        Store.contextualmenu = {
            content: "editModelDeleg",
            open: "extended"
        };
    }
    onEditDeleg = (row) => event => {
        Store.delegation = row;
        Store.contextualmenu = {
            content: "editDeleg",
            open: "extended"
        };
    }
    onNewDeleg = () => {
        Store.delegation = undefined;
        Store.contextualmenu = {
            content: "addNewDeleg",
            open: "extended"
        };
    }
    onNewModel = () => {
        Store.delegation = undefined;
        Store.modelDelegation = {
            model: undefined,
            all: this.state.modelsDeleg
        }
        Store.contextualmenu = {
            content: "addNewModelDeleg",
            open: "extended"
        };
    }
    onModelStart = (row: IModelDelegation) => event => {
        if(row.idModel === null){
            row.idModel = 0;
        }
        Store.modelDelegation.model = row;
        Store.contextualmenu = {
            content: "startDeleg",
            open: "extended"
        };
    }
    onModelProgram = (row: IModelDelegation) => event => {
        Store.modelDelegation.model = row;
        Store.contextualmenu = {
            content: "programDeleg",
            open: "extended"
        };
    }
    startDeleg = (delegSelected: IDelegation) => event => {
        this.setState({ loadingStartDeleg: true }, () => {
            axios.post(Store.wsPath + "/1/delegation/LaunchDelegationById", {
                idDelegation: delegSelected.id
            }).then(response => {
                // On close la modal
                this.onCloseModalStart();
                Store.snackBar = {
                    open: true,
                    error: false,
                    message: "Délégation démarée."
                }
                // Et on récupére toutes les délégations pour mettre à jour la liste
                this.getDelegation();
            }).catch(error => {
                this.setState({
                    loadingStartDeleg: false,
                });
                Store.snackBar = {
                    open: true,
                    error: true,
                    message: "Une erreur est suvenue lors du démarrage de la délégation."
                }
                console.error('ERROR \'startDeleg\': ', error)
            });
        });
    }
    stopDeleg = (delegation: IDelegation, taskSelectedIds: number[]) => event => {
        // WS
        if (delegation != undefined && delegation != null) {
            this.setState({ loadingStopDeleg: true }, () => {
                axios.post(Store.wsPath + "/1/delegation/StopDelegationById", {
                    idDelegation: delegation.id,
                    tasksIds: taskSelectedIds
                }).then(response => {
                    // On close la modal
                    this.onCloseModalStop();
                    Store.snackBar = {
                        open: true,
                        error: false,
                        message: "Délégation terminée."
                    }
                    // Et on récupére toutes les délégations pour mettre à jour la liste
                    this.getDelegation();
                }).catch(error => {
                    this.setState({
                        loadingStopDeleg: false,
                    });
                    Store.snackBar = {
                        open: true,
                        error: true,
                        message: "Une erreur est suvenue lors de la clôture de la délégation."
                    }
                    console.error('ERROR \'stopDeleg\': ', error)
                });
            });
        }
    }
    deleteDeleg = (delegation: IDelegation) => event => {
        if (delegation != undefined && delegation != null) {
            this.setState({ loadingDelete: true }, () => {
                axios.get(Store.wsPath + "/1/delegation/DeleteDelegationById", {
                    params: {
                        idDelegation: delegation.id
                    }
                }).then(response => {
                    // On close la modal
                    this.onCloseModalDelete();
                    Store.snackBar = {
                        open: true,
                        error: false,
                        message: "Délégation supprimée."
                    }
                    //update la liste des délégations
                    this.getDelegation()
                }).catch(error => {
                    this.setState({
                        loadingDelete: false,
                    });
                    Store.snackBar = {
                        open: true,
                        error: true,
                        message: "Une erreur est suvenue lors de la supression de la délégation."
                    }
                    console.error('ERROR \'deleteDeleg\': ', error)
                });
            });
        }
    }
    deleteModel = (model: IModelDelegation) => event => {
        if (model != undefined && model != null) {
            this.setState({ loadingDelete: true }, () => {
                axios.get(Store.wsPath + "/1/delegation/DesactivateModelDelegationById", {
                    params: {
                        idModelDelegation: model.id
                    }
                }).then(response => {
                    // On close la modal
                    this.onCloseModalModelDelete();
                    Store.snackBar = {
                        open: true,
                        error: false,
                        message: "Modèle supprimé."
                    }
                    //update la liste des modeles
                    let temp = this.state.modelsDeleg.filter(mod => mod.id != model.id);
                    this.setState({ modelsDeleg: temp });
                }).catch(error => {
                    this.setState({
                        loadingDelete: false,
                    });
                    Store.snackBar = {
                        open: true,
                        error: true,
                        message: "Une erreur est suvenue lors de la supression du modèle."
                    }
                    console.error('ERROR \'deleteModel\': ', error)
                });
            });
        }
    }
    openModalTask = (row: IDelegation) => event => {
        this.setState({
            showTasks: true,
            selectedDeleg: row,
            delegedTasks: row.WfTasks
        });
        // Appel de la fonction get task
        // this.getAllTasksByDelegationId(row.id);
    }
    openModalStop = (row: IDelegation) => event => {
        this.setState({
            modalStop: true,
            selectedDeleg: row,
            delegedTasks: row.WfTasks
        });
        // Appel de la fonction get task
        // this.getAllTasksByDelegationId(row.id);
    }
    openModalModelDelete = (row) => event => {
        this.setState({
            modalModelDelete: true,
            selectedModel: row
        })
    }
    openModalDelete = (row) => event => {
        this.setState({
            modalDelete: true,
            selectedDeleg: row
        })
    }
    openModalStart = (row: IDelegation) => event => {
        // Get des délégations enCours
        const allDeleg: IDelegation[] = this.state.allDeleg.filter(deleg => deleg.status == "enCours" && deleg.id != row.id && deleg.delegateFrom == row.delegateFrom && row.idModel == deleg.idModel);
        if (allDeleg.length == 0) {
            this.setState({
                modalStart: true,
                selectedDeleg: row
            });
        }
        else {
            Store.snackBar = {
                open: true,
                error: true,
                message: "Une délégation avec le même paramètrage est déjà en cours."
            }
        }
    }
    onCloseModalDelete = () => {
        this.setState({
            modalDelete: false,
            loadingDelete: false
        });
    }
    onCloseModalTask = () => {
        this.setState({
            loadingTask: false,
            showTasks: false,
            allTaskSelectedIds: [],
        });
    }
    onCloseModalModelDelete = () => {
        this.setState({
            modalModelDelete: false,
            loadingDelete: false
        });
    }
    onCloseModalStop = () => {
        this.setState({
            modalStop: false,
            allTaskSelectedIds: [],
            loadingStopDeleg: false,
            delegedTasks: [],
            selectedDeleg: undefined
        });
    }
    onCloseModalStart = () => {
        this.setState({
            modalStart: false,
            selectedDeleg: undefined,
            loadingStartDeleg: false
        });
    }
    sortDateStart = whatDisplay => (a: IDelegation, b: IDelegation) => {
        if (whatDisplay === "programmée") {
            return new Date(a.dateStart).getTime() - new Date(b.dateStart).getTime()
        } else {
            return new Date(b.dateStart).getTime() - new Date(a.dateStart).getTime()
        }
    }
    sortDateEnd = (a: IDelegation, b: IDelegation) => {
        return new Date(b.dateEnd).getTime() - new Date(a.dateEnd).getTime()
    }

    // Fonction appelée lors de la sélection des tâches à garder ou non dans le stop délégation
    onSelectRowMultiple = (row, isSelected, e) => {
        let allTaskSelected: number[] = [...this.state.allTaskSelectedIds];
        if (isSelected == true) {
            const findInArray = allTaskSelected.find(task => task == row.id);
            if (findInArray == undefined) {
                allTaskSelected.push(row.id);
            }
        }
        else {
            allTaskSelected = allTaskSelected.filter(task => task != row.id);
        }
        this.setState({
            allTaskSelectedIds: allTaskSelected
        });
    }

    // Fonction appelée lors de la sélection de toutes les tâches à garder ou non dans le stop délégation
    onSelectRowMultipleAll = (isSelected, rows) => {
        let allTaskSelected: number[] = [];
        if (isSelected == true) {
            allTaskSelected = rows.map(task => task.id);
            this.setState({
                allTaskSelectedIds: allTaskSelected
            });
        }
        this.setState({
            allTaskSelectedIds: allTaskSelected
        });
    }
    recupDeleg = (taskSelectedIds: number[]) => event => {
        if (taskSelectedIds != undefined && taskSelectedIds != null && taskSelectedIds.length > 0) {
            this.setState({ loadingTask: true }, () => {
                axios.post(Store.wsPath + "/1/delegation/RecupTasks", {
                    tasksIds: taskSelectedIds,
                    idDelegation: this.state.selectedDeleg.id
                }).then(response => {
                    // On close la modal
                    this.onCloseModalTask();
                    Store.snackBar = {
                        open: true,
                        error: false,
                        message: "Les tâches ont bien été récupéré."
                    }
                    this.getDelegation();
                }).catch(error => {
                    this.setState({
                        loadingTask: false,
                    });
                    Store.snackBar = {
                        open: true,
                        error: true,
                        message: "Une erreur est suvenue lors de la récupération des tâches."
                    }
                    console.error('ERROR \'recupDeleg\': ', error)
                });
            });
        } else if (taskSelectedIds != undefined && taskSelectedIds != null && taskSelectedIds.length == 0) {
            Store.snackBar = {
                open: true,
                error: true,
                message: "Veuillez selectionner les tâches à récupérer."
            }
        }
    }
    titleHover = (type: string) => (cell: string, row: IModelDelegation | IDelegation) => {
        return (
            <span title={
                type == "userFrom" ? row.UserFrom.email
                    : type == "userTo" ? row.UserTo.email
                        : ""
            }>{cell}</span>
        )
    }
    public render(): JSX.Element {
        const optionsModelDelegation = {
            sortIndicator: true,
            defaultSortName: 'id',
            noDataText: 'Aucun modèle à afficher',
            sizePerPage: 10
        };
        const optionsDelegation = {
            sortIndicator: true,
            defaultSortName: 'id',
            sortOrder: 'desc',

            noDataText: 'Aucune délégation à afficher',
            sizePerPage: 10,
        };
        const optionsTasks = {
            sortIndicator: true,
            defaultSortName: 'id',
            noDataText: 'Aucune tâche n\'a été affecté par cette délégation',
            sizePerPage: 10
        };
        const selectRowMultiple = {
            mode: 'checkbox',
            clickToSelect: true,
            onSelect: this.onSelectRowMultiple,
            onSelectAll: this.onSelectRowMultipleAll,
            unselectable: this.state.delegedTasks.filter(task => task.state == "Terminée" || task.state == "Refusée" || task.state == "Annulée" || task.isDelegate != true).map(task => task.id)
        };
        return (
            <>
                <div className={styles.block}>
                    <div className={styles.contentCheckbox} style={{ background: Store.themeParam.backgroundPrimary }}>
                        <h5>Mes Modèles</h5>
                    </div>
                    <div className={styles.actionBar}>
                        <Button onClick={this.onNewModel} className={styles.addButton} variant="contained" color="primary"><Icon>add</Icon>Créer un modèle</Button>
                    </div>
                    <div className={styles.tableDeleg}>
                        {this.state.loadingModel ?
                            <Spinner label="Chargement des modèles" labelPlacement="bottom" style={{ margin: "30px" }} />
                            :
                            <BootstrapTable data={this.state.modelsDeleg} options={optionsModelDelegation}
                                pagination
                                trClassName={styles.trBootstrapTableModel}
                                search
                                searchPlaceholder="Rechercher..."
                                bordered={false}
                                striped
                                tableHeaderClass={styles.tableThHeader}
                                headerContainerClass={styles.tableHeader}
                            >
                                <TableHeaderColumn dataField='id' hidden isKey>id</TableHeaderColumn>
                                <TableHeaderColumn dataField='name' tdStyle={{ verticalAlign: 'middle' }}>Nom</TableHeaderColumn>
                                <TableHeaderColumn dataField='circuitDisplay' tdStyle={{ verticalAlign: 'middle' }}>Modèle circuit</TableHeaderColumn>
                                <TableHeaderColumn dataField='userFromDisplay' tdStyle={{ verticalAlign: 'middle' }} dataFormat={this.titleHover("userFrom")}
                                    searchable={(Store.userConnected.typeUserLydoc === "ged" || Store.userConnected.typeUserLydoc === "admin")}
                                >
                                    Délégué Par
                                </TableHeaderColumn>
                                <TableHeaderColumn dataField='userToDisplay' tdStyle={{ verticalAlign: 'middle' }} dataFormat={this.titleHover("userTo")}>Délégué À</TableHeaderColumn>
                                <TableHeaderColumn width="140px" dataField="action" tdStyle={{ padding: '0px' }} dataFormat={this.getActions("modele")}>Actions</TableHeaderColumn>
                            </BootstrapTable>
                        }
                    </div>
                </div>
                <div className={styles.block}>
                    <div className={styles.contentCheckbox} style={{ background: Store.themeParam.backgroundPrimary }}>
                        <h5>Délégations</h5>
                    </div>
                    <AppBar position='static' className={styles.menu}>
                        <Tabs
                            onChange={this.onChangeDisplay}
                            value={this.state.whatDisplay}
                            indicatorColor='primary'
                        >
                            <Tab label="En Cours" value="enCours" style={{ background: Store.themeParam.backgroundSecondary }} className={styles.menuItem}></Tab>
                            <Tab label="Programmées" value="programmée" style={{ background: Store.themeParam.backgroundSecondary }} className={styles.menuItem}></Tab>
                            <Tab label="Terminées" value="terminée" style={{ background: Store.themeParam.backgroundSecondary }} className={styles.menuItem}></Tab>
                        </Tabs>
                    </AppBar>
                    <div className={styles.actionBar}>
                        <Button onClick={this.onNewDeleg} className={styles.addButton} variant="contained" color="primary"><Icon>add</Icon>Ajouter une délégation</Button>
                    </div>
                    <div className={styles.tableDeleg}>
                        {this.state.loadingDeleg ?
                            <Spinner label="Chargement des délégations" labelPlacement="bottom" style={{ margin: "30px" }} />
                            :
                            <BootstrapTable data={this.state.whatDisplay === "terminée" ? this.state.allDeleg.filter(deleg => deleg.status === this.state.whatDisplay).sort(this.sortDateEnd) : this.state.allDeleg.filter(deleg => deleg.status === this.state.whatDisplay).sort(this.sortDateStart(this.state.whatDisplay))}
                                options={optionsDelegation}
                                pagination
                                search
                                searchPlaceholder="Rechercher..."
                                bordered={false}
                                striped
                                tableHeaderClass={styles.tableThHeader}
                                headerContainerClass={styles.tableHeader}
                            >
                                <TableHeaderColumn dataField='id' hidden isKey>id</TableHeaderColumn>
                                <TableHeaderColumn dataField='name' tdStyle={{ verticalAlign: 'middle' }}>Nom</TableHeaderColumn>
                                <TableHeaderColumn dataField='circuitDisplay' tdStyle={{ verticalAlign: 'middle' }}>Modèle circuit</TableHeaderColumn>
                                <TableHeaderColumn dataField='userFromDisplay' tdStyle={{ verticalAlign: 'middle' }} dataFormat={this.titleHover("userFrom")}>
                                    Délégué Par
                                </TableHeaderColumn>
                                <TableHeaderColumn dataField='userToDisplay' tdStyle={{ verticalAlign: 'middle' }} dataFormat={this.titleHover("userTo")}>Délégué À</TableHeaderColumn>
                                <TableHeaderColumn width="110px" dataField='dateStartDisplay' tdStyle={{ verticalAlign: 'middle' }}>
                                    Début
                                </TableHeaderColumn>
                                <TableHeaderColumn width="110px" dataField='dateEndDisplay' tdStyle={{ verticalAlign: 'middle' }}>
                                    Fin
                                </TableHeaderColumn>
                                <TableHeaderColumn width="140px" dataField="action" tdStyle={{ padding: '0px' }} dataFormat={this.getActions("statut")}>Actions</TableHeaderColumn>
                            </BootstrapTable>
                        }
                    </div>

                    {/* MODAL TASKS */}
                    <Dialog
                        maxWidth='xl'
                        open={this.state.showTasks}
                        onClose={this.onCloseModalTask}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        <DialogTitle>
                            Tâches déléguées
                        </DialogTitle>
                        <DialogContent>
                            {/* {
                                this.state.loadingTask == true ?
                                    <div className={styles.table}>
                                        <Spinner size={24} label="Récupération des tâches..." labelPlacement="right" />
                                    </div>
                                    : */}
                            <div className={styles.table}>
                                <p>Veuillez sélectionner les tâches que vous voulez récupérer lors de la cloture de cette délégation dans la liste ci-dessous :</p>
                                <BootstrapTable data={this.state.delegedTasks} options={optionsTasks}
                                    selectRow={(this.state.selectedDeleg != undefined && this.state.selectedDeleg.status != "enCours") ? selectRowMultiple : undefined}
                                    pagination={false}
                                    trClassName={styles.trBootstrapTable}
                                    bordered={false}
                                    striped
                                    tableHeaderClass={styles.tableThHeader}
                                    headerContainerClass={styles.tableHeader}
                                >
                                    <TableHeaderColumn dataField='id' hidden isKey>id</TableHeaderColumn>
                                    <TableHeaderColumn dataField='document' tdStyle={{ verticalAlign: 'middle' }} dataFormat={this.getCircuit}>Circuit</TableHeaderColumn>
                                    <TableHeaderColumn dataField='name' tdStyle={{ verticalAlign: 'middle' }}>Tâche</TableHeaderColumn>
                                    <TableHeaderColumn dataField='state' tdStyle={{ verticalAlign: 'middle' }}>Status</TableHeaderColumn>
                                    <TableHeaderColumn dataField='dateDone' tdStyle={{ verticalAlign: 'middle' }} dataFormat={this.getDateFormat}>Date de fin</TableHeaderColumn>
                                    <TableHeaderColumn width="70px" dataField='redirect' dataFormat={this.getRedirectTask}>Lien</TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                            {/* } */}
                        </DialogContent>
                        <DialogActions>
                            {this.state.selectedDeleg != undefined && this.state.selectedDeleg.status != "enCours" ?
                                <Button
                                    onClick={this.recupDeleg(this.state.allTaskSelectedIds)}
                                    className={modal.button}
                                    variant="contained"
                                    color="primary"
                                    disabled={this.state.loadingTask || this.state.allTaskSelectedIds.length == 0}
                                > {
                                        this.state.loadingTask == true ?
                                            <Spinner size={21} />
                                            :
                                            "Récupérer"
                                    }
                                </Button>
                                : ""}
                            <Button
                                onClick={this.onCloseModalTask}
                                className={modal.button}
                                variant="contained"
                                color="secondary"
                            >
                                Annuler
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/* MODAL DELETE DELEG */}
                    <Dialog
                        maxWidth='xs'
                        open={this.state.modalDelete}
                        onClose={this.onCloseModalDelete}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        <DialogTitle>
                            <div className={styles.modalTitle}>Suppression</div>
                        </DialogTitle>
                        <DialogContent>
                            <div className={styles.modalContent}>
                                <p>Vous allez supprimer cette délégation, êtes vous sûr(e)s de cette action</p>
                                <div className={styles.modalButtons}>
                                    <Button
                                        onClick={this.deleteDeleg(this.state.selectedDeleg)}
                                        className={styles.buttonValid}
                                        variant="contained"
                                        color="primary"
                                        disabled={this.state.loadingDelete}
                                    >
                                        {this.state.loadingDelete ?
                                            <Spinner size={21} />
                                            : "Valider"}
                                    </Button>
                                    <Button
                                        onClick={this.onCloseModalDelete}
                                        className={styles.buttonCancel}
                                        variant="contained"
                                        color="secondary"
                                    >
                                        Annuler
                                    </Button>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>

                    {/* MODAL DELETE MODEL */}
                    <Dialog
                        maxWidth='xs'
                        open={this.state.modalModelDelete}
                        onClose={this.onCloseModalModelDelete}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        <DialogTitle>
                            <div className={styles.modalTitle}>Suppression</div>
                        </DialogTitle>
                        <DialogContent>
                            <div className={styles.modalContent}>
                                <p>Vous allez supprimer ce modèle, êtes vous sûr(e)s de cette action</p>
                                <div className={styles.modalButtons}>
                                    <Button
                                        onClick={this.deleteModel(this.state.selectedModel)}
                                        className={styles.buttonValid}
                                        variant="contained"
                                        color="primary"
                                        disabled={this.state.loadingDelete}
                                    >
                                        {this.state.loadingDelete ?
                                            <Spinner size={21} />
                                            : "Valider"}
                                    </Button>
                                    <Button
                                        onClick={this.onCloseModalModelDelete}
                                        className={styles.buttonCancel}
                                        variant="contained"
                                        color="secondary"
                                    >
                                        Annuler
                                    </Button>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>

                    {/* MODAL Stop deleg with TASKS */}
                    <Dialog
                        maxWidth='xl'
                        open={this.state.modalStop}
                        onClose={this.onCloseModalStop}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        <DialogTitle>
                            Terminer la délégation
                        </DialogTitle>
                        <DialogContent>
                            {/* {
                                this.state.loadingTask == true ?
                                    <div className={styles.table}>
                                        <Spinner size={24} label="Récupération des tâches..." labelPlacement="right" />
                                    </div>
                                    : */}
                            <div className={styles.table}>
                                <p>Veuillez sélectionner les tâches que vous voulez récupérer lors de la cloture de cette délégation dans la liste ci-dessous :</p>
                                <BootstrapTable data={this.state.delegedTasks} options={optionsTasks}
                                    selectRow={selectRowMultiple}
                                    pagination={false}
                                    trClassName={styles.trBootstrapTable}
                                    bordered={false}
                                    striped
                                    tableHeaderClass={styles.tableThHeader}
                                    headerContainerClass={styles.tableHeader}
                                >
                                    <TableHeaderColumn dataField='id' hidden isKey>id</TableHeaderColumn>
                                    <TableHeaderColumn dataField='document' className={"toto"} tdStyle={{ verticalAlign: 'middle' }} dataFormat={this.getCircuit}>Circuit</TableHeaderColumn>
                                    <TableHeaderColumn dataField='name' tdStyle={{ verticalAlign: 'middle' }}>Tâche</TableHeaderColumn>
                                    <TableHeaderColumn dataField='state' tdStyle={{ verticalAlign: 'middle' }}>Status</TableHeaderColumn>
                                    <TableHeaderColumn dataField='dateDone' tdStyle={{ verticalAlign: 'middle' }} dataFormat={this.getDateFormat}>Date de fin</TableHeaderColumn>
                                </BootstrapTable>
                            </div>
                            {/* } */}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={this.stopDeleg(this.state.selectedDeleg, this.state.allTaskSelectedIds)}
                                className={modal.button}
                                variant="contained"
                                color="primary"
                                disabled={this.state.loadingStopDeleg}
                            > {
                                    this.state.loadingStopDeleg == true ?
                                        <Spinner size={21} />
                                        :
                                        "Terminer"
                                }
                            </Button>
                            <Button
                                onClick={this.onCloseModalStop}
                                className={modal.button}
                                variant="contained"
                                color="secondary"
                            >
                                Annuler
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/* MODAL START DELEG */}
                    <Dialog
                        maxWidth='xs'
                        open={this.state.modalStart}
                        onClose={this.onCloseModalStart}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        <DialogTitle>
                            <div className={styles.modalTitle}>Démarrer la délégation</div>
                        </DialogTitle>
                        <DialogContent>
                            <div className={styles.modalContent}>
                                <p>Vous allez démarrer cette délégation, êtes vous sûr(e) de cette action ?</p>
                                <div className={styles.modalButtons}>
                                    <Button
                                        onClick={this.startDeleg(this.state.selectedDeleg)}
                                        className={styles.buttonValid}
                                        variant="contained"
                                        color="primary"
                                        disabled={this.state.loadingStartDeleg == true || this.state.selectedDeleg == undefined ? true : false}
                                    >
                                        {
                                            this.state.loadingStartDeleg == true ?
                                                <Spinner size={21} />
                                                :
                                                "Valider"
                                        }
                                    </Button>
                                    <Button
                                        onClick={this.onCloseModalStart}
                                        className={styles.buttonCancel}
                                        variant="contained"
                                        color="secondary"
                                    >
                                        Annuler
                                    </Button>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
                </div>
            </>
        )
    }
}
export default withRouter(Delegation);