import * as React from 'react';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
const styles = require('./styles/ListRoles_styles.module.scss');
import Store, { getAllRoles } from '../store/store';
import axios from 'axios';
import { observer } from "mobx-react";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Modal from "@material-ui/core/Modal/Modal";
import { IRole } from './interfaces/IRole';
const modal = require("../../styles/styles.module.scss");

interface State {
    openModal: boolean;
    idRole: number;
}
@observer
export default class ListRoles extends React.Component<any, State>{

    public constructor(props: any) { // Dans le constructeur, on initialise toujours les states
        super(props)
        this.state = {
            openModal: false,
            idRole: undefined
        }
        this.getAllRoles();
    }

    public getAllRoles = () => {
        try {
            getAllRoles();
        } catch (error) {
            console.log("Error 'getAllRoles' : ", error);
        }
    }

    public onNewRole = () => {
        Store.addEditRole = Store.DefaultValueRole;
        Store.contextualmenu = {
            open: 'extended',
            content: "addRole"
        }
    }

    modalOpen = (row: IRole) => {
        this.setState({
            idRole: row.id,
            openModal: true
        })
    }

    public toolbox = (cell: any, row: any) => {
        return (
            <div className={styles.listActionContainer}>
                <div className={styles.iconAction} onClick={() => this.editRow(row)}><Icon>edit</Icon></div>
                <div className={styles.iconAction} onClick={() => this.modalOpen(row)}><Icon style={{ fontSize: 20 }}>delete</Icon></div>
            </div>
        );
    }

    modalClose = () => {
        this.setState({ openModal: false, idRole: undefined });
    }

    public editRow = async (row: IRole) => {
        Store.contextualmenu.content = undefined;
        Store.addEditRole.id = row.id;
        Store.addEditRole.name = row.name;
        Store.addEditRole.idxUser = row.idxUser;
        Store.contextualmenu = {
            open: "extended",
            content: "editRole"
        }
    }

    public onDeleteRole = () => {
        axios.post(Store.wsPath + '/1/roles/deleteRole', {
            idRole: this.state.idRole
        }).then((response) => {
            if (response.status == 200) {
                this.modalClose();
                // Snack Bar
                Store.snackBar.message = "Rôle supprimé avec succés";
                Store.snackBar.error = false;
                Store.snackBar.open = true;

                this.getAllRoles();
            }
            else {
                // Snack Bar
                Store.snackBar.message = "Une erreur c'est produite, veuillez réessayer";
                Store.snackBar.error = true;
                Store.snackBar.open = true;
            }
        })
    }

    public onSelect = (row, isSelected) => {
        const monId: any = row.id
        Store.myStepModel = row
        this.editRow(row)
    }

    public userFormatter =(cell,row)=>{
      const email = row.User != undefined && row.User != null ?row.User.email :""
      return `<span title="${email}"> ${cell}</span>`;
    }
    public render() {
        const options = {
            sortIndicator: true,
            defaultSortName: 'name',
            noDataText: 'Aucun rôle à afficher',
            sizePerPage: 25
        };

        // const selectRowProp = {
        //     mode: 'radio',
        //     bgColor: '#10a544',
        //     clickToSelect: true,
        //     onSelect: this.onSelect,
        //     hideSelectColumn: true
        // };

        return (
            <div className={styles.firstContent}>
                <Modal
                    open={this.state.openModal}
                    className={modal.modal}
                >
                    <div className={modal.modalContent}>
                        <Typography variant="h5" id="modal-title">
                            Suppression
                                        </Typography>
                        <br />
                        <Typography variant="subtitle2" id="simple-modal-description">
                            Vous allez supprimer ce rôle, êtes vous sûr(e)s de cette action ?
                        </Typography>
                        <br />
                        <div className={modal.modalButton}>
                            <Button
                                onClick={this.modalClose}
                                className={modal.button}
                                variant="contained"
                                color="secondary"
                            >
                                Annuler
                        </Button>
                            <Button
                                className={modal.button}
                                onClick={this.onDeleteRole}
                                variant="contained"
                                color="primary"
                            >
                                Valider
                        </Button>

                        </div>
                    </div>
                </Modal>
                <div className={styles.roleTitle} style={{ background: Store.themeParam.backgroundPrimary }}><h5>Rôles</h5></div>
                <div className={styles.contentbodyRole}>
                    <Button onClick={this.onNewRole} variant="contained" color="primary"><Icon>add</Icon>Ajouter un rôle</Button>
                    <BootstrapTable 
                        multiColumnSearch
                        data={Store.allRoles}
                        searchPlaceholder='Rechercher dans la liste'
                        search={true}
                        options={options}
                        pagination
                        version='4'
                        className={styles.bootstrapRole}
                        /*selectRow={selectRowProp}*/>
                        <TableHeaderColumn isKey={true} hidden dataField='id'>id</TableHeaderColumn>
                        <TableHeaderColumn dataField='name' dataSort={true}>Rôle</TableHeaderColumn>
                        <TableHeaderColumn hidden dataField='idxUser'>Personne associée</TableHeaderColumn>
                        <TableHeaderColumn dataField='user' dataFormat={this.userFormatter} dataSort={true}>Personne associée</TableHeaderColumn>
                        <TableHeaderColumn headerAlign='center' width='80px' dataField='id' tdStyle={{ padding: '0', verticalAlign: 'inherit' }} dataFormat={this.toolbox.bind(this)}>Actions</TableHeaderColumn>
                    </BootstrapTable>
                </div>
            </div>)
    }
}