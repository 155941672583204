import React from 'react';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import Store from '../store/store'
import { observer } from "mobx-react";
import axios from "axios";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import SelectReact, { createFilter } from 'react-select';
import { MenuList, customMultipleValue } from './../helper/reactSelectCustomRender'
import Spinner from '../Spinner/Spinner';
const styles = require('./styles/stylePopover.scss');
const modal = require('../../styles/modal.scss');


interface Props {
  row: any;
  mode: string;
  updateListAfterEdit?: () => void;

}
interface State {
  anchorElGroup?: any;
  isOpenPopover?: boolean;
  allUserGroup?: any[];
  isLoadingButton: boolean;
  newUsersGroupId: any[];
  usersGroupId: any[];
  selectValues: any[];
  openDeleteModal: boolean;

}

@observer
export default class PopoverGroup extends React.Component<Props, State>{
  constructor(props: any) {
    super(props)
    this.state = {
      anchorElGroup: null,
      isOpenPopover: false,
      allUserGroup: [],
      isLoadingButton: false,
      newUsersGroupId: [],
      usersGroupId: [],
      selectValues: [],
      openDeleteModal: false
    }

    this.onOpenPopover = this.onOpenPopover.bind(this);
    this.closePopover = this.closePopover.bind(this);
  }




  public addUserInGroup = (event) => {
    event.preventDefault()
    if (this.state.newUsersGroupId.length > 0) {
      const fusionnedUsersOfGroup: any[] = [...this.state.usersGroupId, ...this.state.newUsersGroupId];
      axios.post(Store.wsPath + `/1/paramGroupes/editGroupe`, {
        //nameGroupe: this.state.nameGroup,
        nameGroupe: this.props.row.firstname,
        //idUsers: this.state.choiceUser
        idUser: this.props.row.id,
        usersOfGroup: fusionnedUsersOfGroup
      }).then(response => {
        if (response.status == 200) {
          Store.contextualmenu.open = "hidden";
          Store.contextualmenu.content = undefined;
          Store.snackBar.message = fusionnedUsersOfGroup.length > 1 ? "Utilisateurs ajoutés avec succés" : "Utilisateur ajouté avec succés"
          Store.snackBar.open = true;
          Store.snackBar.error = false;
          this.getUserFromGroup(fusionnedUsersOfGroup, "add");
          this.props.updateListAfterEdit();
        }
        else {
          // SnackBar
          Store.snackBar.message = "Une erreur c'est produite, veuillez réessayez"
          Store.snackBar.open = true;
          Store.snackBar.error = true;
        }
      }).catch(error => {
        Store.snackBar.message = "Une erreur c'est produite, veuillez réessayez"
        Store.snackBar.open = true;
        Store.snackBar.error = true;
      })
    }
  }

  public deleteUserInGroup = (row) => {
    event.preventDefault();
    const filteredUsersOfGroup: any[] = this.state.usersGroupId.filter(uog => uog != row.id);
    // Si on supprime le dernier utilisateur du groupe alors on ouvre une modale pour dire que l'on ne peut pas
    if (filteredUsersOfGroup.length > 0) {
      axios.post(Store.wsPath + `/1/paramGroupes/editGroupe`, {
        nameGroupe: this.props.row.firstname,
        idUser: this.props.row.id,
        usersOfGroup: filteredUsersOfGroup
      }).then(response => {
        if (response.status == 200) {
          Store.contextualmenu.open = "hidden";
          Store.contextualmenu.content = undefined;
          Store.snackBar.message = "Utilisateur retiré avec succés"
          Store.snackBar.open = true;
          Store.snackBar.error = false;
          this.getUserFromGroup(filteredUsersOfGroup, "remove");
          this.props.updateListAfterEdit();
        }
        else {
          // SnackBar
          Store.snackBar.message = "Une erreur c'est produite, veuillez réessayez"
          Store.snackBar.open = true;
          Store.snackBar.error = true;
        }
      }).catch(error => {
        Store.snackBar.message = "Une erreur c'est produite, veuillez réessayez"
        Store.snackBar.open = true;
        Store.snackBar.error = true;
      })
    }
    else {
      this.setState({ openDeleteModal: true });
    }
  }

  public onChangeSelect = (option) => {
    const tempArray = option.map(role => role.value)
    this.setState({ newUsersGroupId: tempArray, selectValues: option });
  }

  public getUserFromGroup = (usersOfGroup: number[], mode: string, currentTarget?: any, groupId?: number) => {
    usersOfGroup = usersOfGroup != undefined || usersOfGroup != null ? usersOfGroup : []
    axios.post(Store.wsPath + "/1/users/getUsersByIdsNew", {
      usersIds: usersOfGroup,
      groupId: groupId
    }).then((response) => {
      if (mode == "add") {
        this.setState({ allUserGroup: response.data, usersGroupId: usersOfGroup, newUsersGroupId: [], selectValues: [] })

      } else if (mode == "init") {
        this.setState({ allUserGroup: response.data, usersGroupId: usersOfGroup, isOpenPopover: true, anchorElGroup: currentTarget })
      }
      else {
        this.setState({ allUserGroup: response.data, usersGroupId: usersOfGroup })
      }

    })
  }


  onOpenPopover(event) {
    const currentTarget = event.currentTarget
    this.getUserFromGroup(this.props.row.usersOfGroup, "init", currentTarget, this.props.row.id);
    event.stopPropagation();
  }
  closePopover(event) {
    event.stopPropagation();
    this.setState({ isOpenPopover: false, anchorElGroup: event.currentTarget });
  }

  public toolbox = (cell: any, row: any) => {
    return (
      <div className={styles.listActionContainer}>
        <div className={styles.iconEdit} onClick={() => this.deleteUserInGroup(row)} title="Retirer"><Icon style={{ fontSize: "15px" }}>delete</Icon></div>
      </div>
    );
  }

  public closeDeleteModal = () => {
    this.setState({ openDeleteModal: !this.state.openDeleteModal });
  }

  render() {
    const options = {
      sortIndicator: true,
      noDataText: 'Aucun utilisateur à afficher',
      sizePerPage: 10,
    };
    const id = this.state.isOpenPopover ? 'simple-popover' : undefined;
    return (
      <div>
        <div className={styles.iconEdit} onClick={this.onOpenPopover} title="Consulter les membres du groupe"><Icon style={{ fontSize: 20 }}>people</Icon></div>
        {this.state.anchorElGroup != null ?
          <Popover
            id={id}
            open={this.state.isOpenPopover}
            anchorEl={this.state.anchorElGroup}
            onClose={this.closePopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <div style={{ width: "800px", height: "500px", margin: "8px" }} >
              <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "bold", marginTop: "16px", fontSize: "17px", marginLeft: "2px" }}>
                <div>
                  {"Membres de " + this.props.row.firstname}
                </div>
                <div style={{ cursor: "pointer" }} onClick={this.closePopover} title="Fermer">
                  <Icon>close</Icon>
                </div>
              </div>
              {this.props.mode == "edit" ?
                <form key="myForm" autoComplete="false" onSubmit={this.addUserInGroup}>
                  <div style={{ display: "flex", alignItems: "center", width: "100%", marginTop: "16px" }}>
                    <SelectReact
                      options={Store.suggestionsUser != undefined ? Store.suggestionsUser.filter(user => user.isGroup != true && this.state.usersGroupId.find(uog => uog == user.value) == undefined) : []}
                      onChange={option => this.onChangeSelect(option ? option : null)}
                      placeholder="Ajouter un ou des utilisateurs"
                      filterOption={createFilter({ ignoreAccents: false })}
                      className={styles.field}
                      isMulti
                      value={this.state.selectValues}
                      styles={{ control: styles => ({ ...styles, backgroundColor: 'transparent', padding: '2px 0', width: "250px" }) }}
                      isClearable
                      components={{ MultiValueLabel: customMultipleValue, Option: MenuList }}
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={this.state.isLoadingButton == true ? true : false}
                      style={{ marginLeft: "21px", height: "30px" }}
                    >
                      {this.state.isLoadingButton == true ?
                        <Spinner fullWidth={true} size={24} />
                        :
                        "Ajouter"
                      }
                    </Button>
                  </div>
                </form>
                :
                ""
              }
              <BootstrapTable
                multiColomnSearch
                data={this.state.allUserGroup.length > 0 ? this.state.allUserGroup : []}
                searchPlaceholder='Rechercher'
                search={true}
                options={options}
                version='4'
                bordered={true}
                className={styles.contentMyTasks}
              >
                <TableHeaderColumn isKey={true} dataField='id' hidden dataAlign='center' headerAlign='center'>id</TableHeaderColumn>
                <TableHeaderColumn datasort={true} dataField='firstname' dataAlign='center' headerAlign='center'>Prénom</TableHeaderColumn>
                <TableHeaderColumn datasort={true} dataField='lastname' dataAlign='center' headerAlign='center'>Nom </TableHeaderColumn>
                <TableHeaderColumn datasort={true} dataField='email' dataAlign='center' headerAlign='center'>E-mail</TableHeaderColumn>
                <TableHeaderColumn hidden={this.props.mode == "edit" ? false : true} headerAlign='center' dataField='lastname' tdStyle={{ padding: '0', verticalAlign: 'inherit' }} dataFormat={this.toolbox.bind(this)}></TableHeaderColumn>
              </BootstrapTable>
            </div>
          </Popover>
          :
          ""
        }
        <Modal
          open={this.state.openDeleteModal}
          className={modal.modal}
          onClose={this.closeDeleteModal}
        >
          <div className={modal.modalContent}>
            <Typography variant="h5">
              ⚠️ Attention
            </Typography>
            <br />
            <Typography variant="subtitle2" id="simple-modal-description">
              Vous ne pouvez pas supprimer le dernier utilisateur d'un groupe.<br />
              <span style={{color:"red"}}>Un groupe ne peut pas être vide.</span><br />
              Pour supprimer cet utilisateur veuillez d'abord en ajouter de nouveaux.
            </Typography>
            <br />
            <div className={modal.modalButton}>
              <Button
                onClick={this.closeDeleteModal}
                className={modal.button}
                variant="contained"
                color="secondary"
              >
                Fermer
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}