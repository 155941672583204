import React from 'react';
const styles = require('./styles/FormulaireSharepoint.scss');
import Store from '../store/store'
import { toJS } from "mobx";
import axios from 'axios';
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import ListItemText from "@material-ui/core/ListItemText";
import Switch from "@material-ui/core/Switch";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import Icon from "@material-ui/core/Icon";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    KeyboardDateTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import frLocale from "date-fns/locale/fr";
import { ValidatorForm } from "react-material-ui-form-validator";
import SelectReact, { createFilter } from 'react-select';
import 'simplebar/dist/simplebar.min.css';
import TaxonomyPicker from "./TaxonomyPicker/TaxonomyPicker";
import { withRouter, RouteComponentProps } from "react-router";
import { useQuery } from './../helper/helpers';
import { IDBDocument } from '../Workflow/interfaces/IWorkflow';
import { IDoc } from '../ListeDocuments/interfaces/IDoc';

interface IFormulaireSharepointProps {
    document: IDBDocument | IDoc;
    projet: any;
    viewMode?: boolean;
    hideButton?: boolean;
    hideDesignationDossier?: boolean;
    hideFields?: string[];
    afficherChampsVueListe?: boolean;
    formTitle?: string;
    isGRC?: boolean;
    isForm?:boolean;
    formType?:string;
    authMode?: string;
}

interface IFormulaireSharepointState {
    updateFormData: any;
    updateValue: boolean;
    allFields: any[];
    dataSharepoint: any;
    document: any;
    loadingData: boolean;
    loadingButton: boolean;
    projectName: string;
    projectId: number;
    saveInProgress: any;
    errorLoadingFields: boolean;
    gestionnaire: any;
}

class FormulaireSharepoint extends React.Component<RouteComponentProps & IFormulaireSharepointProps, IFormulaireSharepointState> {
    public constructor(props: RouteComponentProps & IFormulaireSharepointProps) {
        super(props)
        this.state = {
            updateFormData: {},
            updateValue: false,
            allFields: [],
            dataSharepoint: {},
            document: {},
            loadingData: true,
            loadingButton: false,
            projectName: "",
            projectId: null,
            saveInProgress: {},
            errorLoadingFields: false,
            gestionnaire: null
        }
    }

    public componentDidMount() {
        if (this.props.document.id != undefined && this.props.document.id != null) {
            this.getAllFieldsAndFormatData(this.props.document);
            if(this.props.document.userId != undefined && this.props.document.userId != null) {
                const newValue = Store.allUsers.filter(user => user.id == this.props.document.userId).map(user => {
                    return {
                        id: user.id,
                        value: user.login,
                        label: user.firstname + " " + user.lastname,
                        key: user.id
                    }
                })[0];
                this.setState({ gestionnaire : newValue });
            }
        }

        if (this.props.projet.id != undefined && this.props.projet.id != null && this.props.projet.name != null) {
            this.setState({ projectName: this.props.projet.name, projectId: this.props.projet.id });
        }

        // Pour enlever le style disabled au champs quand le form est en view mode
        this.deleteDisabledClassForViewMode();
    }

    public componentDidUpdate(prevProps: IFormulaireSharepointProps, prevState: IFormulaireSharepointState, snapshot) {
        if (this.props.document.id != undefined && prevProps.document.id != this.props.document.id) {
            this.getAllFieldsAndFormatData(this.props.document);
        }

        if ((this.props.projet.id != undefined && this.props.projet.id != null && this.props.projet.id != prevProps.projet.id) || (this.props.projet.name != undefined && this.props.projet.name != null && this.props.projet.name != prevProps.projet.name)) {
            this.setState({ projectName: this.props.projet.name, projectId: this.props.projet.id });
        }

        // Pour enlever le style disabled au champs quand le form est en view mode
        this.deleteDisabledClassForViewMode();
    }

    getAllFieldsAndFormatData = (document: any) => {
        this.setState({ loadingData: true, errorLoadingFields: false }, () => {
            axios.get(Store.wsPath + '/1/documents/getListFieldsByIdList', {
                params: {
                    idList: document.dataSharepoint.idList,
                    web: document.dataSharepoint.site,
                    isGRC: document.isGRC,
                    isForm:document.isForm
                }
            }).then(async responseFields => {
                if (responseFields.status == 200 && responseFields.data != null) {
                    const dataSharepoint: any = toJS(document.dataSharepoint);

                    // Initialisation variable champs
                    const allFields: any[] = responseFields.data.allFields;
                    const sortedViewFields: any[] = responseFields.data.sortedViewFields;

                    // Update des datas dèjà effectuées
                    const updateData = document.dataSharepoint.editMetadata != undefined && document.dataSharepoint.editMetadata != null ? toJS(document.dataSharepoint.editMetadata) : {};

                    // Préparation de la données si champs, user/multiUser
                    await Promise.all(allFields.map(field => {
                        return new Promise<void>(async (resolve, reject) => {
                            let errorMessage = "";
                            try {
                                switch (field.TypeAsString) {
                                    // Si champs User/MultiUser
                                    case "User":
                                    case "UserMulti":
                                        errorMessage = "Error dans la récupèration des utilisateurs pour le champs '" + field.InternalName + "'";
                                        if (updateData[field.InternalName] == undefined && dataSharepoint[field.InternalName + "Id"] != null) {
                                            let idUsers = [];
                                            // Pour initialiser la valeur à vide si jamais il y a des erreurs après
                                            dataSharepoint[field.InternalName] = idUsers;

                                            // Récupèration des ids des users et envoi au back pour aller chercher sur sharePoint à qui correspond cet id
                                            if (typeof dataSharepoint[field.InternalName + "Id"] == "number") {
                                                idUsers[0] = dataSharepoint[field.InternalName + "Id"];
                                            }
                                            else {
                                                idUsers = dataSharepoint[field.InternalName + "Id"];
                                            }

                                            // Envoi de la requete au back pour retourner les utilisateurs correspondant aux ids
                                            const users = await axios.post(Store.wsPath + '/1/documents/geFieldValueForForm', {
                                                fieldType: field.TypeAsString,
                                                siteUrl: dataSharepoint.site,
                                                data: idUsers
                                            });
                                            // Si réponse ok et qu'il y a des utilisateurs alors on les mets dans l'objet
                                            if (users.status == 200 && users.data.length > 0) {
                                                const listUsers = users.data.map(user => {
                                                    if (this.props.authMode.toLowerCase() == "ad") {
                                                        const splitLoginName = user.LoginName.split("\\");
                                                        if (splitLoginName.length > 1) {
                                                            return splitLoginName[splitLoginName.length - 1];
                                                        }
                                                        else {
                                                            return user.LoginName;
                                                        }
                                                    }
                                                    else {
                                                        return user.UserPrincipalName;
                                                    }
                                                });
                                                dataSharepoint[field.InternalName] = listUsers;
                                            }
                                            resolve();
                                        }
                                        else {
                                            dataSharepoint[field.InternalName] = [];
                                            resolve();
                                        }
                                        break;
                                    default:
                                        resolve();
                                        break;
                                }
                            } catch (error) {
                                console.error(errorMessage + " : ", error);
                                resolve();
                            }
                        })
                    }));

                    let allFieldsView: any[] = [];
                    if (this.props.isGRC == true || this.props.isForm == true) {
                        const lyflowFields:string[]= ["sherpa_WorkflowEtat","sherpa_WorkflowIdChrono"]
                        const grcFields: string[] = ["grc_EmailDemandeur", "grc_Statut"];
                        const recruitFields: string[] = ["recruit_EmailDemandeur", "recruit_Statut"];
                        allFields.forEach(field => {
                            if (grcFields.filter(fi => fi == field.InternalName).length > 0 || lyflowFields.filter(fi => fi == field.InternalName).length > 0 || recruitFields.filter(fi => fi == field.InternalName).length > 0) {
                                field.ReadOnlyField = true;
                                allFieldsView.push(field);
                            }
                        });
                    }
                    else {
                        if (this.props.afficherChampsVueListe == true) {
                            sortedViewFields.forEach(field => {
                                const find = allFields.filter(fi => fi.InternalName == field.InternalName);
                                if (find.length > 0) {
                                    allFieldsView.push(find[0]);
                                }
                            });
                        }
                        else {
                            allFieldsView = allFields;
                        }
                    }

                    // this.setState({ updateValue: true, updateFormData: updateData });
                    this.setState({ loadingData: false, allFields: allFieldsView, dataSharepoint: dataSharepoint, document: toJS(document), updateValue: true, updateFormData: updateData });
                }
                else {
                    this.setState({ errorLoadingFields: true });
                }
            }).catch(error => {
                console.error("Error 'getListFieldsByIdList' : ", error);
                this.setState({ errorLoadingFields: true });
            });
        });
    }

    // Fonction qui envoi les données mise à jour au back
    public updateFormSharePointWS = () => {
        return new Promise<void>((resolve, reject) => {
            axios.post(Store.wsPath + "/1/documents/updateDocData", {
                idDoc: this.state.document.id,
                updatedData: this.state.updateFormData
            }).then((responseUpdate) => {
                if (responseUpdate.status == 200) {
                    resolve();
                }
                else {
                    throw new Error("Une erreur inconnue, s'est produite");
                }
            }).catch(error => {
                console.error("Error 'updateFormSharePointWS' : ", error);
                reject();
            })
        });
    }

    // Fonction appelée lors du click sur le bouton valider du formulaire, qui appelle la fonction d'envoi des données au back
    public updateFormSharePointButton = () => {
        this.setState({ loadingButton: true }, () => {
            this.updateFormSharePointWS().then((responseUpdate) => {
                Store.snackBar.error = false;
                Store.snackBar.message = "Mise à jour du dossier réussie";
                Store.snackBar.open = true;
                this.setState({ loadingButton: false });
            }).catch(error => {
                this.setState({ loadingButton: false });
                ////////// SnackBar ///////////
                Store.snackBar.error = true;
                Store.snackBar.message = "Une erreur est survenue, veuillez réessayer";
                Store.snackBar.open = true;
            });
        });
    }

    // Fonction appelée lorsque l'on quite un champs pour mettre à jour les données à la volé, appelle la fonction d'envoi des données au back
    public updateFormSharePointOnBlur = (key: string) => event => {
        const saveInProgress = this.state.saveInProgress;
        saveInProgress[key] = { etat: "load", message: "" };
        this.setState({ saveInProgress: saveInProgress }, () => {
            this.updateFormSharePointWS().then((responseUpdate) => {
                this.updateSaveEtat(key);
            }).catch((error) => {
                console.error("SaveProjectName : ", error);
                this.updateSaveEtat(key, true);
            })
        });
    }

    public onChangeValueSharePoint = (field, sousType?: string) => event => {
        const value = this.state.updateFormData;
        switch (field.TypeAsString) {
            case "File":
                value[field.InternalName] = event.target.value + "." + sousType;
                break;
            case "Text":
            case "Note":
            case "Number":
            case "MultiChoice":
            case "Choice":
                value[field.InternalName] = event.target.value;
                break;
            case "Boolean":
                value[field.InternalName] = event.target.checked;
                break;
            case "DateTime":
                value[field.InternalName] = event;
                break;
            case "User":
                if (event != null) {
                    value[field.InternalName] = [event.value];
                }
                else {
                    value[field.InternalName] = [];
                }
                break;
            case "UserMulti":
                value[field.InternalName] = event.map(user => user.value);
                break;
            case "URL":
                if (value[field.InternalName] != undefined) {
                    value[field.InternalName][sousType] = event.target.value;
                }
                else {
                    value[field.InternalName] = {};
                    value[field.InternalName][sousType] = event.target.value;
                }
                break;
            case "TaxonomyFieldType":
            case "TaxonomyFieldTypeMulti":
                value[field.InternalName] = event;
                break;
            default:
                break;
        }
        this.setState({ updateFormData: value });
    }

    // OnChange pour modifier la désignation du dossier
    private onChangeProjectName = () => event => {
        this.setState({ projectName: event.target.value });
    }

    private onChangeGestionnaire = () => event => {
        const newValue = Store.allUsers.filter(user => user.login == event.value).map(user => {
            return {
                id: user.id,
                value: user.login,
                label: user.firstname + " " + user.lastname,
                key: user.id
            }
        })[0];
        if(newValue != undefined){
            this.setState({gestionnaire: newValue});
        }
    }

    private saveChangeGestionnaire = () => event => {
        event.preventDefault();
        const saveInProgress = this.state.saveInProgress;
        
        if(this.state.gestionnaire.id != undefined && this.state.gestionnaire.id != null &&
        this.state.gestionnaire.id != this.props.document.userId){
            saveInProgress["GestionnaireDuDossier"] = { etat: "load", message: "" };
            this.setState({saveInProgress: saveInProgress});
            axios.post(Store.wsPath + "/1/workflows/updateGestionnaireByDoc",{
                docId: this.props.document.id,
                userId: this.state.gestionnaire.id
            })
            .then(response => {
                if (response.status == 200) {
                    Store.selectedDoc.userId = this.state.gestionnaire.id;
                    location.reload();
                }
                else {
                    saveInProgress["GestionnaireDuDossier"] = { etat: "error", message: "" };
                    this.setState({saveInProgress: saveInProgress});
                    throw new Error("Error inconnue");
                }
            }).catch((error) => {
                console.error("saveChangeGestionnaire : ", error);
            })
        } else {
            saveInProgress["GestionnaireDuDossier"] = { etat: "error", message: "" };
            this.setState({saveInProgress: saveInProgress});
        }
    }

    // Fonction pour sauvegarder la nouvelle désignation du dossier en base
    private saveProjectName = (key: string, required?: boolean) => event => {
        const valueField = event.target.value;
        if (required == true && (valueField == null || valueField.length == 0)) {
            // error !!!
            this.updateSaveEtat(key, true, "Champ requis, veuillez le completer.");
        }
        else {
            const regex = RegExp('["*:<\\\>?\/|]', 'g');
            if (regex.test(valueField) == true) {
                // error !!!!
                this.updateSaveEtat(key, true, "Entrez un nom qui ne contient aucun caractères suivants : \"*:<\\>?/|");
            }
            else {
                const saveInProgress = this.state.saveInProgress;
                saveInProgress[key] = { etat: "load", message: "" };
                this.setState({ saveInProgress: saveInProgress }, () => {
                    axios.post(Store.wsPath + "/1/workflows/updateProjectName", {
                        idProject: this.state.projectId,
                        docId: this.state.document.id,
                        nameProject: this.state.projectName,
                        modeSharePoint: Store.modeSharepoint,
                        isGRC: this.state.document.isGRC,
                        isForm: this.state.document.isForm
                    }).then((responseUpdate) => {
                        if (responseUpdate.status == 200) {
                            this.updateSaveEtat(key);
                            const urlParams: string = useQuery().toString();
                            if(urlParams != undefined && urlParams != null && urlParams.length > 0) {
                                this.props.history.push("/refresh?redir=document?" + urlParams);
                            }
                            else {
                                this.props.history.push("/refresh?redir=document");
                            }
                        }
                        else {
                            throw new Error("Error inconnue");
                        }
                    }).catch((error) => {
                        console.error("SaveProjectName : ", error);
                        this.updateSaveEtat(key, true);
                    })
                })
            }
        }
    }

    // Fonction qui passe l'état du champs à l'état done ou error, pour l'affichage des helpersText sur les champs
    private updateSaveEtat = (keyField: string, error?: boolean, message?: string): Promise<void> => {
        return new Promise((resolve, reject) => {
            const messageToShow = message != undefined && message != null && message.length > 0 ? message : "";
            let fieldEtat = "done";
            if (error == true) {
                fieldEtat = "error";
            }

            const saveInProgress = this.state.saveInProgress;
            saveInProgress[keyField] = { etat: fieldEtat, message: messageToShow };

            this.setState({ saveInProgress: saveInProgress }, () => {
                if (fieldEtat != "error") {
                    const timeout = setTimeout(() => {
                        const saveInProgressEnd = this.state.saveInProgress;
                        delete saveInProgressEnd[keyField];

                        this.setState({ saveInProgress: saveInProgressEnd }, () => {
                            clearTimeout(timeout);
                            resolve();
                        });
                    }, 3000);
                }
            });
        });
    }

    // Fonction pour afficher un helperText sur les champs en fonction de l'état ou il est
    private helperTextFormat = (fieldKey: string) => {
        const field = this.state.saveInProgress[fieldKey];
        let message: any = "";

        if (field != undefined && field != null && field.etat != undefined) {
            if (field.etat == "load") {
                const messageToShow = field.message != undefined && field.message != null && field.message.length > 0 ? field.message : "Enregistrement en cours";
                message = <span style={{ display: "flex", alignItems: "center" }}><CircularProgress size="11px" color="primary" style={{ marginRight: "5px" }} />{messageToShow}</span>;
            }
            else if (field.etat == "done") {
                const messageToShow = field.message != undefined && field.message != null && field.message.length > 0 ? field.message : "Enregistrement réussi";
                message = <span style={{ color: "green", display: "flex", alignItems: "center" }}><Icon style={{ fontSize: "15px", marginRight: "5px" }}>check</Icon>{messageToShow}</span>;
            }
            else if (field.etat == "error") {
                const messageToShow = field.message != undefined && field.message != null && field.message.length > 0 ? field.message : "Une erreur est survenue, réessayez";
                message = <span style={{ color: "red", display: "flex", alignItems: "center" }}><Icon style={{ fontSize: "15px", marginRight: "5px" }}>warning</Icon>{messageToShow}</span>;
            }
        }

        return message;
    }

    // Pour enlever le style disabled au champs quand le form est en view mode
    private deleteDisabledClassForViewMode = () => {
        const allFields = document.querySelectorAll(".sherpa_formViewMode .sherpa_viewModeField");
        for (let i = 0; i < allFields.length; i++) {
            if (allFields[i].classList.contains("sherpa_takeFather") == true) {
                const field: any = allFields[i];
                if (field.offsetParent != undefined && field.offsetParent != null) {
                    field.offsetParent.classList.remove("Mui-disabled");
                }
            }
            else {
                allFields[i].classList.remove("Mui-disabled");
            }
        }
    }

    public render() {
        // Variable pour savoir si projet terminé ou non
        let projectEnd = false;
        if (this.props.viewMode == true) {
            projectEnd = true;
        }

        // Tableau des champs à exclure de l'affichage qu'on récupére des props
        const excludeFields: string[] = this.props.hideFields != undefined && this.props.hideFields != null ? this.props.hideFields : [];
        excludeFields.push("sherpa_WorkflowBouton");
        excludeFields.push("sherpa_WorkflowGestionnaireDuDossier");

        // Variable pour annulé la couleur disable quand la props view mode est true
        const inputPropsClass = {};
        this.props.viewMode == true ? inputPropsClass["disabled"] = "sherpa_viewModeField" : "";

        const asRigthOnDoc = Store.userConnected.typeUserLydoc === "ged" || Store.userConnected.typeUserLydoc === "admin" || Store.userConnected.id == this.props.document.userId;
        let valueAuthMode = "AAD";
        if (this.props.authMode.toLowerCase() == "aad") {
            valueAuthMode = "AAD";
        }
        else if (this.props.authMode.toLowerCase() == "ad") {
            valueAuthMode = "AD";
        }
        const optionUser = Store.allUsers.filter(user => user.matricule.indexOf(valueAuthMode) != -1).map(user => {
            return {
                id: user.id,
                value: user.login,
                label: user.firstname + " " + user.lastname,
                key: user.id
            }
        });

        const classNameFormViewMode: string = this.props.viewMode == true ? "sherpa_formViewMode" : "";
        return (
            // this.props.item.id != undefined && this.props.item.id != null && this.props.allFields != undefined && this.props.allFields.length > 0 ?
            this.state.errorLoadingFields == true ?
                <p style={{ textAlign: "center" }}>Une erreur est survenue, veuillez réessayer</p>
                :
                this.state.loadingData == true ?
                    <div key="loadingData" style={{ minHeight: "100px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <CircularProgress color="primary" />
                        <div style={{ marginTop: "10px" }}>Chargement en cours...</div>
                    </div>
                    :
                    <ValidatorForm
                        ref="form"
                        onError={errors => console.error("errors", errors)}
                        onSubmit={this.updateFormSharePointButton}
                        className={styles.formStyle + " " + classNameFormViewMode}
                    >
                        <div /*style={{ maxHeight: "100vh" }} */ >
                            {/* <SimpleBar style={{ overflowX: "hidden" }}> */}
                            {this.props.formTitle != undefined && this.props.formTitle != null && this.props.formTitle.length > 0 ?
                                <h3>{this.props.formTitle}</h3>
                                :
                                ""
                            }
                            {this.props.hideDesignationDossier == true ?
                                <React.Fragment></React.Fragment>
                                :
                                <TextField
                                    key="DésignationDossier"
                                    name="Désignation du dossier"
                                    margin="normal"
                                    className={styles.groupInput}
                                    variant="outlined"
                                    label="Désignation du dossier"
                                    // defaultValue={this.state.projectName}
                                    value={this.state.projectName}
                                    onChange={this.onChangeProjectName()}
                                    disabled={projectEnd}
                                    helperText={this.helperTextFormat("DésignationDossier")}
                                    onBlur={this.saveProjectName("DésignationDossier", true)}
                                    required={true}
                                    InputLabelProps={{
                                        shrink: true,
                                        classes: { root: styles.labelFix }
                                    }}
                                    type="text"
                                    InputProps={{
                                        classes: inputPropsClass
                                    }}
                                    inputProps={{
                                        maxLength: 60
                                    }}
                                />
                            }
                            {
                            this.props.document != undefined && this.props.document != null &&
                            this.props.document.userId != undefined && this.props.document.userId != null?
                                <div style={{ marginBottom: "20px" }}>
                                    <FormLabel component="legend">Gestionnaire du dossier</FormLabel>
                                    <SelectReact
                                        options={optionUser}
                                        onChange={this.onChangeGestionnaire()}
                                        placeholder="Gestionnaire du dossier"
                                        filterOption={createFilter({ ignoreAccents: false })}
                                        value={this.state.gestionnaire}
                                        isMulti={false}
                                        onBlur={this.saveChangeGestionnaire()}
                                        styles={{ control: styles => ({ ...styles, backgroundColor: 'transparent', padding: '2px 0', color: 'primary' }), menu: base => ({ ...base, zIndex: 99999 }) }}
                                        isClearable={false}
                                        isDisabled={projectEnd == false && asRigthOnDoc == true ? false : true}
                                    />
                                    <span>{this.helperTextFormat('GestionnaireDuDossier')}</span>
                                </div> 
                                :
                                <React.Fragment></React.Fragment>
                            }
                            {
                                this.state.allFields.filter(field => excludeFields.filter(fi => field.InternalName.indexOf(fi) == 0).length == 0).map((field, index) => {
                                    // defaultValue
                                    const defaultValue = this.state.updateFormData != undefined && this.state.updateFormData != null && this.state.updateFormData[field.InternalName] != undefined ? this.state.updateFormData[field.InternalName] : this.state.dataSharepoint[field.InternalName];

                                    let updateValue = false;
                                    if (this.state.updateValue == true) {
                                        updateValue = true;
                                        if (index + 1 == this.state.allFields.length) {
                                            this.setState({ updateValue: false });
                                        }
                                    }

                                    let tempValue = {};

                                    switch (field.TypeAsString) {
                                        // case "File":
                                        //     // Pour le nom du document
                                        //     // const fileValue = this.props.item.dataSharepoint[field.InternalName];
                                        //     const extension = defaultValue.split(".")[1];
                                        //     const name = defaultValue.split(".")[0];
                                        //     if (updateValue) {
                                        //         tempValue = { value: name };
                                        //     }
                                        //     return (
                                        //         <TextField
                                        //             key={field.Id + field.InternalName}
                                        //             name={field.Title}
                                        //             margin="normal"
                                        //             className={styles.groupInput}
                                        //             variant="outlined"
                                        //             label={field.Title}
                                        //             {...tempValue}
                                        //             helperText={this.helperTextFormat(field.Id + field.InternalName)}
                                        //             onBlur={this.updateFormSharePointOnBlur(field.Id + field.InternalName)}
                                        //             onChange={this.onChangeValueSharePoint(field, extension)}
                                        //             InputProps={{
                                        //                 endAdornment: <InputAdornment position="end">{"." + extension}</InputAdornment>,
                                        //             }}
                                        //             InputLabelProps={{
                                        //                 shrink: true,
                                        //                 classes: { root: styles.labelFix }
                                        //             }}
                                        //             type="text"
                                        //             required={field.Required}
                                        //         />
                                        //     )
                                        //     break;
                                        case "Text":
                                            if (updateValue) {
                                                tempValue = { value: defaultValue != null ? defaultValue : "" };
                                            }
                                            let disabled: boolean = field.ReadOnlyField;
                                            if (field.InternalName == "sherpa_WorkflowEtat" || field.InternalName == "sherpa_WorkflowIdChrono" || field.InternalName == "sherpa_WorkflowDesignation") {
                                                disabled = true;
                                            }

                                            // Cas des champs de mise en forme pour la grc
                                            if (field.InternalName.toLowerCase().indexOf("titre_") != -1) {
                                                return (
                                                    <div key={field.Id + field.InternalName} >
                                                        <h6 className={styles.titleField}>{field.Description}</h6>
                                                    </div>
                                                );
                                            }
                                            else if (field.InternalName.toLowerCase().indexOf("texte_") != -1) {
                                                return (
                                                    <div key={field.Id + field.InternalName} className={styles.groupInput}>
                                                        <p>{field.Description}</p>
                                                    </div>
                                                );
                                            }
                                            else if (field.InternalName.toLowerCase().indexOf("liste_") != -1) {
                                                return (
                                                    <div key={field.Id + field.InternalName} className={styles.groupInput}>
                                                        {
                                                            field.Description.split(";").map((desc: string, index: number) => {
                                                                return (
                                                                    <li key={desc + index} style={{ marginLeft: "12px" }}>{desc}</li>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                );
                                            }
                                            else {
                                                return (
                                                    <div key={field.Id + field.InternalName}>
                                                        <TextField
                                                            label={field.Title}
                                                            variant="outlined"
                                                            className={styles.groupInput}
                                                            onChange={this.onChangeValueSharePoint(field)}
                                                            {...tempValue}
                                                            helperText={this.helperTextFormat(field.Id + field.InternalName)}
                                                            onBlur={this.updateFormSharePointOnBlur(field.Id + field.InternalName)}
                                                            disabled={disabled == true || projectEnd == true ? true : false}
                                                            required={field.Required}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                classes: { root: styles.labelFix }
                                                            }}
                                                            InputProps={{
                                                                classes: inputPropsClass
                                                            }}
                                                        />
                                                    </div>
                                                )
                                            }
                                            break;
                                        case "Number":
                                            if (updateValue) {
                                                tempValue = { value: defaultValue != null ? defaultValue : "" };
                                            }
                                            return (
                                                <div key={field.Id + field.InternalName}>
                                                    <TextField
                                                        type="number"
                                                        label={field.Title}
                                                        variant="outlined"
                                                        inputProps={{ min: field.MinimumValue, max: field.MaximumValue, step: "1" }}
                                                        className={styles.groupInput}
                                                        onChange={this.onChangeValueSharePoint(field)}
                                                        helperText={this.helperTextFormat(field.Id + field.InternalName)}
                                                        onBlur={this.updateFormSharePointOnBlur(field.Id + field.InternalName)}
                                                        {...tempValue}
                                                        disabled={field.ReadOnlyField == true || projectEnd == true ? true : false}
                                                        required={field.Required}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                            classes: { root: styles.labelFix }
                                                        }}
                                                        InputProps={{
                                                            classes: inputPropsClass
                                                        }}
                                                    />
                                                </div>
                                            )
                                            break;
                                        case "Note":
                                            if (field.InternalName != "MediaServiceKeyPoints") {
                                                if (updateValue) {
                                                    tempValue = { value: defaultValue != null ? defaultValue : "" };
                                                }
                                                return (
                                                    <div key={field.Id + field.InternalName}>
                                                        <TextField
                                                            type="text"
                                                            label={field.Title}
                                                            variant="outlined"
                                                            multiline
                                                            rowsMax={field.NumberOfLines}
                                                            className={styles.groupInput}
                                                            onChange={this.onChangeValueSharePoint(field)}
                                                            helperText={this.helperTextFormat(field.Id + field.InternalName)}
                                                            onBlur={this.updateFormSharePointOnBlur(field.Id + field.InternalName)}
                                                            {...tempValue}
                                                            disabled={field.ReadOnlyField == true || projectEnd == true ? true : false}
                                                            required={field.Required}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                classes: { root: styles.labelFix }
                                                            }}
                                                            InputProps={{
                                                                classes: inputPropsClass
                                                            }}
                                                        />
                                                    </div>
                                                )
                                            }
                                            else {
                                                return (<React.Fragment></React.Fragment>)
                                            }
                                            break;
                                        case "Boolean":
                                            return (
                                                <div key={field.Id + field.InternalName}>
                                                    <div>{field.Title}</div>
                                                    <Switch
                                                        {...tempValue}
                                                        onChange={this.onChangeValueSharePoint(field)}
                                                        color="primary"
                                                        checked={defaultValue}
                                                        onBlur={this.updateFormSharePointOnBlur(field.Id + field.InternalName)}
                                                        // value="checkedA"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        classes={inputPropsClass}
                                                        disabled={field.ReadOnlyField == true || projectEnd == true ? true : false}
                                                        required={field.Required}
                                                    />
                                                    <div>{this.helperTextFormat(field.Id + field.InternalName)}</div>
                                                </div>
                                            )
                                            break;
                                        case "User":
                                        case "UserMulti":
                                            if (field.InternalName.indexOf("sherpa_WorkflowGestionnaireDuDos") != -1 ) {
                                                field.ReadOnlyField = true;
                                            }
                                            
                                            return (
                                                <div key={field.Id + field.InternalName} style={{ marginBottom: "20px" }}>
                                                    <FormLabel component="legend">{field.Title}</FormLabel>
                                                    <SelectReact
                                                        options={optionUser}
                                                        onChange={this.onChangeValueSharePoint(field)}
                                                        placeholder={field.Title}
                                                        filterOption={createFilter({ ignoreAccents: false })}
                                                        value={defaultValue != null ? optionUser.filter(user => defaultValue.filter(login => login.toLowerCase() == user.value.toLowerCase()).length > 0) : null}
                                                        isMulti={field.AllowMultipleValues}
                                                        onBlur={this.updateFormSharePointOnBlur(field.Id + field.InternalName)}
                                                        // className={styles.field}
                                                        styles={{ control: styles => ({ ...styles, backgroundColor: 'transparent', padding: '2px 0', color: 'primary' }) }}
                                                        isClearable={!field.Required}
                                                        isDisabled={field.ReadOnlyField == true || projectEnd == true ? true : false}
                                                    // components={{ SingleValue: customSingleValue, Option: MenuList }}
                                                    />
                                                    <div>{this.helperTextFormat(field.Id + field.InternalName)}</div>
                                                </div>
                                            );
                                            break;
                                        case "DateTime":
                                            if (field.DisplayFormat == 1) { // Affichage en mode Date et heure
                                                return (
                                                    <div key={field.Id + field.InternalName}>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                                                            <KeyboardDateTimePicker
                                                                // disableToolbar
                                                                autoOk={true}
                                                                ampm={false}
                                                                variant="inline"
                                                                inputVariant="outlined"
                                                                format="dd/MM/yyyy HH:mm"
                                                                margin="normal"
                                                                id={"date-time-" + field.InternalName}
                                                                label={field.Title}
                                                                className={styles.groupInput}
                                                                helperText={this.helperTextFormat(field.Id + field.InternalName)}
                                                                value={defaultValue != null ? defaultValue : null}
                                                                onChange={this.onChangeValueSharePoint(field)}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change date time',
                                                                    onBlur: this.updateFormSharePointOnBlur(field.Id + field.InternalName)
                                                                }}
                                                                InputLabelProps={{
                                                                    classes: { root: styles.labelFix }
                                                                }}
                                                                inputProps={{
                                                                    onBlur: this.updateFormSharePointOnBlur(field.Id + field.InternalName)
                                                                }}
                                                                InputProps={{
                                                                    classes: inputPropsClass
                                                                }}
                                                                disabled={field.ReadOnlyField == true || projectEnd == true ? true : false}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </div>
                                                );
                                            }
                                            if (field.DisplayFormat == 0) { // Affichage en mode Date
                                                return (
                                                    <div key={field.Id + field.InternalName}>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                                                            <KeyboardDatePicker
                                                                disableToolbar
                                                                autoOk={true}
                                                                variant="inline"
                                                                inputVariant="outlined"
                                                                format="dd/MM/yyyy"
                                                                margin="normal"
                                                                id={"date-picker-inline-" + field.InternalName}
                                                                label={field.Title}
                                                                className={styles.groupInput}
                                                                value={defaultValue != null ? defaultValue : null}
                                                                onChange={this.onChangeValueSharePoint(field)}
                                                                helperText={this.helperTextFormat(field.Id + field.InternalName)}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change date',
                                                                    onBlur: this.updateFormSharePointOnBlur(field.Id + field.InternalName)
                                                                }}
                                                                InputLabelProps={{
                                                                    classes: { root: styles.labelFix }
                                                                }}
                                                                inputProps={{
                                                                    onBlur: this.updateFormSharePointOnBlur(field.Id + field.InternalName)
                                                                }}
                                                                InputProps={{
                                                                    classes: inputPropsClass
                                                                }}
                                                                disabled={field.ReadOnlyField == true || projectEnd == true ? true : false}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </div>
                                                );
                                            }
                                            break;
                                        case "Choice":
                                            // Pour les colonnes de types choix
                                            const choiceList = field.Choices.results != undefined ? field.Choices.results : field.Choices;
                                            const affichageMode = field.EditFormat;
                                            if (affichageMode == 0) {
                                                return (
                                                    <div key={field.Id + field.InternalName}>
                                                        <FormControl variant="outlined" className={styles.groupInput}>
                                                            <InputLabel className={styles.labelFix} htmlFor={"outlined-" + field.Title + "-simple"}>
                                                                {field.Title}
                                                            </InputLabel>
                                                            <Select
                                                                required={field.Required}
                                                                value={defaultValue != null ? defaultValue : ""}
                                                                onChange={this.onChangeValueSharePoint(field)}
                                                                // labelWidth={labelWidth}
                                                                onBlur={this.updateFormSharePointOnBlur(field.Id + field.InternalName)}
                                                                inputProps={{
                                                                    name: field.Title,
                                                                    id: "outlined-" + field.Title + "-simple"
                                                                }}
                                                                // @ts-ignore
                                                                // class="MuiInputBase-root MuiOutlinedInput-root Mui-disabled Mui-disabled MuiInputBase-formControl"
                                                                classes={{ root: "sherpa_takeFather", ...inputPropsClass }}
                                                                disabled={field.ReadOnlyField == true || projectEnd == true ? true : false}
                                                            >
                                                                {field.Required == false ? <MenuItem key={"nullllll"} value=""><span style={{ opacity: 0 }}>null</span></MenuItem> : ""}
                                                                {choiceList.map((choice, indexChoice) => {
                                                                    return (
                                                                        <MenuItem key={choice + "/" + indexChoice} value={choice}>{choice}</MenuItem>
                                                                    )
                                                                })
                                                                }
                                                            </Select>
                                                            <div>{this.helperTextFormat(field.Id + field.InternalName)}</div>
                                                        </FormControl>
                                                    </div>
                                                )
                                            }
                                            else {
                                                return (
                                                    <div key={field.Id + field.InternalName}>
                                                        <FormControl component="fieldset" className={styles.groupInput}>
                                                            <FormLabel component="legend">{field.Title}</FormLabel>
                                                            <RadioGroup aria-label={field.Title} name={field.Title} value={defaultValue} onChange={this.onChangeValueSharePoint(field)} row onBlur={this.updateFormSharePointOnBlur(field.Id + field.InternalName)}>
                                                                {choiceList.map((choice, indexChoice) => {
                                                                    return (
                                                                        <FormControlLabel
                                                                            key={choice + indexChoice}
                                                                            value={choice}
                                                                            control={<Radio color="primary" />}
                                                                            label={choice}
                                                                            labelPlacement="end"
                                                                            classes={inputPropsClass}
                                                                            disabled={field.ReadOnlyField == true || projectEnd == true ? true : false}
                                                                        />
                                                                    )
                                                                })
                                                                }
                                                            </RadioGroup>
                                                            <div>{this.helperTextFormat(field.Id + field.InternalName)}</div>
                                                        </FormControl>
                                                    </div>
                                                )
                                            }
                                            break;
                                        // case "MultiChoice":
                                        //     // Pour les colonnes de types multi choix
                                        //     const multiChoiceValue = Store.sharepointDocEditData[field.InternalName] != null ? Store.sharepointDocEditData[field.InternalName] : [];
                                        //     const multiChoiceList = field.Choices;
                                        //     return (
                                        //         <div key={index}>
                                        //             <FormControl component="fieldset" className={styles.groupInput}>
                                        //                 <FormLabel component="legend">{field.Title}</FormLabel>
                                        //                 <FormGroup row>
                                        //                     {multiChoiceList.map((choice, indexChoice) => {
                                        //                         return (
                                        //                             <FormControlLabel
                                        //                                 key={indexChoice}
                                        //                                 control={
                                        //                                     <Checkbox
                                        //                                         checked={multiChoiceValue.find(value => choice == value) == undefined ? false : true}
                                        //                                         color="primary"
                                        //                                         onChange={this.onChangeValueSharePoint(field)}
                                        //                                         value={choice}
                                        //                                     />
                                        //                                 }
                                        //                                 label={choice}
                                        //                                 labelPlacement="end"
                                        //                             />
                                        //                         )
                                        //                     })
                                        //                     }
                                        //                 </FormGroup >
                                        //             </FormControl>
                                        //         </div>
                                        //     )
                                        //     break;
                                        case "MultiChoice":
                                            // Pour les colonnes de types multi choix
                                            // const multiChoiceValue = Store.sharepointDocEditData[field.InternalName] != null ? Store.sharepointDocEditData[field.InternalName] : [];
                                            const multiChoiceList = field.Choices.results != undefined ? field.Choices.results : field.Choices;
                                            const multiValue = defaultValue != undefined && defaultValue != null ? defaultValue.results != undefined && defaultValue.results != null ? defaultValue.results.slice() : defaultValue.slice() : [];
                                            return (
                                                <FormControl variant="outlined" key={field.Id + field.InternalName} component="fieldset" className={styles.groupInput}>
                                                    <InputLabel className={styles.labelFix} htmlFor={"outlined-" + field.Title + "-simple"}>{field.Title}</InputLabel>
                                                    <Select
                                                        required={field.Required}
                                                        multiple
                                                        inputProps={{
                                                            name: field.Title,
                                                            id: "outlined-" + field.Title + "-simple",
                                                        }}
                                                        value={multiValue}
                                                        onBlur={this.updateFormSharePointOnBlur(field.Id + field.InternalName)}
                                                        onChange={this.onChangeValueSharePoint(field)}
                                                        // input={<Input />}
                                                        classes={inputPropsClass}
                                                        renderValue={(selected) => (selected as string[]).join(', ')}
                                                        disabled={field.ReadOnlyField == true || projectEnd == true ? true : false}
                                                    // MenuProps={MenuProps}
                                                    >
                                                        {multiChoiceList.map((value, index) => (
                                                            <MenuItem key={value + index + "jj"} value={value}>
                                                                <Checkbox checked={multiValue.indexOf(value) > -1 ? true : false} />
                                                                <ListItemText primary={value} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    <div>{this.helperTextFormat(field.Id + field.InternalName)}</div>
                                                </FormControl>
                                            )
                                            break;
                                        case "URL":
                                            // Pour les colonnes de types url
                                            // Check si c'est la colonne Pièce jointe
                                            if (field.InternalName != "sherpa_WorkflowPJ") {
                                                // const urlValue = Store.selectedDoc.dataSharepoint[field.InternalName];
                                                let tempValueUrl = {};
                                                let tempValueDescription = {};

                                                if (updateValue) {
                                                    tempValueUrl = { value: defaultValue != null ? defaultValue.Url : "" };
                                                    tempValueDescription = { value: defaultValue != null ? defaultValue.Description : "" }
                                                }
                                                return (
                                                    <div key={field.Id + field.InternalName}>
                                                        <FormLabel component="legend">{field.Title}</FormLabel>
                                                        <div style={{ paddingLeft: "20px" }}>
                                                            <TextField
                                                                label="Url"
                                                                variant="outlined"
                                                                className={styles.groupInput}
                                                                onChange={this.onChangeValueSharePoint(field, "Url")}
                                                                onBlur={this.updateFormSharePointOnBlur(field.Id + field.InternalName)}
                                                                {...tempValueUrl}
                                                                disabled={field.ReadOnlyField == true || projectEnd == true ? true : false}
                                                                required={field.Required}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                    classes: { root: styles.labelFix }
                                                                }}
                                                                InputProps={{
                                                                    classes: inputPropsClass
                                                                }}
                                                            />
                                                            <TextField
                                                                label="Description"
                                                                variant="outlined"
                                                                className={styles.groupInput}
                                                                onChange={this.onChangeValueSharePoint(field, "Description")}
                                                                onBlur={this.updateFormSharePointOnBlur(field.Id + field.InternalName)}
                                                                {...tempValueDescription}
                                                                disabled={field.ReadOnlyField == true || projectEnd == true ? true : false}
                                                                required={field.Required}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                    classes: { root: styles.labelFix }
                                                                }}
                                                                InputProps={{
                                                                    classes: inputPropsClass
                                                                }}
                                                            />
                                                        </div>
                                                        <div>{this.helperTextFormat(field.Id + field.InternalName)}</div>
                                                    </div>
                                                )
                                            }
                                            else {
                                                return (<React.Fragment></React.Fragment>)
                                            }
                                            break;
                                        // case "TaxonomyFieldType":
                                        //     // Pour les colonnes de types taxonomy (ceci est provisoire)
                                        //     if (updateValue) {
                                        //         tempValue = { value: defaultValue != null && defaultValue.TermInfo != undefined && defaultValue.TermInfo != null ? defaultValue.TermInfo.Name : "" };
                                        //     }
                                        //     return (
                                        //         <div key={field.Id + field.InternalName}>
                                        //             <TextField
                                        //                 label={field.Title}
                                        //                 variant="outlined"
                                        //                 className={styles.groupInput}
                                        //                 onChange={this.onChangeValueSharePoint(field)}
                                        //                 {...tempValue}
                                        //                 disabled={true}
                                        //                 // required={field.Required}
                                        //                 InputLabelProps={{
                                        //                     shrink: true,
                                        //                     classes: { root: styles.labelFix }
                                        //                 }}
                                        //             />
                                        //         </div>
                                        //     )
                                        //     break;
                                        case "TaxonomyFieldType":
                                        case "TaxonomyFieldTypeMulti":
                                            // Pour les colonnes de types taxonomy
                                            const taxoValue: any | any[] = defaultValue != undefined && defaultValue != null ? defaultValue.results != undefined && defaultValue.results != null ? defaultValue.results : defaultValue : ""
                                            return (
                                                <div style={{ margin: "20px 0" }} key={field.Id + field.InternalName}>
                                                    <TaxonomyPicker
                                                        multiSelect={field.AllowMultipleValues}
                                                        label={field.Title}
                                                        termSetId={field.TermSetId}
                                                        anchorId={field.AnchorId}
                                                        onChange={this.onChangeValueSharePoint(field)}
                                                        value={taxoValue}
                                                        onBlur={this.updateFormSharePointOnBlur(field.Id + field.InternalName)}
                                                        helperText={this.helperTextFormat(field.Id + field.InternalName)}
                                                        required={field.Required}
                                                        disabled={field.ReadOnlyField == true || projectEnd == true ? true : false}
                                                    />
                                                </div>
                                            )
                                            break;
                                        default:
                                            //  console.log("pas de correspondance dans le Switch pour : ", field.TypeAsString);
                                            break;
                                    }
                                })

                            }

                            {/* TODO Gestion des erreurs à mettre en place */}

                            {/* </SimpleBar> */}
                        </div>
                        {this.props.hideButton != true ?
                            <div className={styles.contentBouttonSharepoint}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={projectEnd == true || this.state.loadingButton == true ? true : false}
                                >
                                    {this.state.loadingButton == true ?
                                        <CircularProgress size={24} color="primary" />
                                        :
                                        "VALIDER"
                                    }
                                </Button>
                                <Button
                                    onClick={() => { }}
                                    variant="contained"
                                    color="default"
                                    disabled={projectEnd == true ? true : false}
                                >
                                    Annuler
                            </Button>
                            </div>
                            :
                            <React.Fragment></React.Fragment>
                        }
                    </ValidatorForm>
        )
    }
}

export default withRouter(FormulaireSharepoint);