import * as React from 'react'
import { getAllUsers } from "../store/store";
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import axios from 'axios'
import Store from '../store/store'
import { observer } from "mobx-react";
import Modal from "@material-ui/core/Modal/Modal";
import PopoverGroup from '../popover/PopoverGroup';
import { IRole } from '../parametersRoles/interfaces/IRole';
import Spinner from '../Spinner/Spinner';
const modal = require("../../styles/styles.module.scss");
const styles = require('./styles/ParamGroup.scss');

interface Props {

}
interface State {
  allUsersList: any[];
  allGroup?: any[];
  openModal?: boolean;
  idUserGroupe?: number;
  isOpenPopover?: boolean;
  userOfGroupPopover?: number[];
  idGroup?: number;
  loading: {
    modal: boolean;
    deleteButton: boolean;
  };
  error: {
    role: boolean;
  };
  roleAssociatedToGroup: IRole[];
}

@observer

export default class ListGroupes extends React.Component<Props, State>{

  constructor(props: any) {
    super(props)
    this.state = {
      allUsersList: undefined,
      allGroup: [],
      openModal: false,
      idUserGroupe: undefined,
      userOfGroupPopover: [],
      isOpenPopover: false,
      idGroup: undefined,
      loading: {
        modal: false,
        deleteButton: false
      },
      error: {
        role: false
      },
      roleAssociatedToGroup: []
    }
    getAllUsers();


  }
  componentDidMount() {
    this.getAllGroup()
  }

  onClickButtonValidation() {

  }

  onNewGroup = () => {
    Store.contextualmenu.content = "addNewGroup";
    Store.contextualmenu.open = "extended";

    // Initialisation nécessaire pour le menu
  }
  onEditGroup = (row: any) => {
    Store.myUser = {
      id: row.id,
      civility: row.civility,
      lastname: row.lastname,
      firstname: row.firstname,
      matricule: row.matricule,
      email: row.email,
      login: row.login,
      password: row.password,
      id_service: row.id_terme_service, // john
      id_fonction: row.id_terme_fonction, // john
      id_profession: row.id_terme_profession, // john
      typeUserLydoc: row.typeUserLydoc,
      typeUser: row.typeUser,
      accessConsult: row.accessConsult, // john
      accessRef: row.accessRef, // john
      accessParam: row.accessParam,
      accessSignalement: row.accessSignalement,
      id_fonctionResponsable: { ActionReferent: [], InfoResponsable: [] },
      id_professionResponsable: { ActionReferent: [], InfoResponsable: [] },
      id_serviceResponsable: { ActionReferent: [], InfoResponsable: [] },
      id_riskResponsable: { ActionReferent: [], InfoResponsable: [] },
      isGroup: row.isGroup,
      usersOfGroup: row.usersOfGroup
    };
    Store.contextualmenu.content = "EditGroup";
    Store.contextualmenu.open = "extended";
  }


  onDeleteGroup = () => {
    this.setState({ loading: { ...this.state.loading, deleteButton: true } }, () => {
      axios.post(Store.wsPath + '/1/paramGroupes/deleteGroupe', {
        idUser: this.state.idUserGroupe,
        roleAssociated: this.state.roleAssociatedToGroup.map(role => role.id)
      }).then((response) => {
        if (response.status == 200) {
          this.modalClose();
          // Snack Bar
          Store.snackBar = {
            message: "Groupe supprimé avec succés",
            error: false,
            open: true
          };
          this.getAllGroup();
        }
        else {
          // Snack Bar
          Store.snackBar = {
            message: "Une erreur c'est produite lors de la suppression, veuillez réessayer",
            error: true,
            open: true
          };
          this.setState({ error: { ...this.state.error, role: true } });
        }
      }).catch(error => {
        console.error("Error 'onDeleteGroup' : ", error);
        // Snack Bar
        Store.snackBar = {
          message: "Une erreur c'est produite lors de la suppression, veuillez réessayer",
          error: true,
          open: true
        };
        this.setState({ error: { ...this.state.error, role: true } });
      });
    });
  }

  public toolbox = (cell: any, row: any) => {
    return (
      <div className={styles.listActionContainer}>
        <PopoverGroup row={row} mode={"edit"} updateListAfterEdit={this.getAllGroup} />
        <div className={styles.iconEdit} onClick={() => this.onEditGroup(row)} title="Editer le groupe"><Icon style={{ fontSize: 20 }}>mode_edit</Icon></div>
        <div className={styles.iconEdit} onClick={() => this.modalOpen(row.id)} title="Supprimer le groupe"><Icon style={{ fontSize: 20 }}>delete</Icon></div>
      </div>
    );
  }

  modalOpen = (id?: number) => {
    this.setState({ openModal: true, loading: { modal: true, deleteButton: false }, error: { role: false }, idUserGroupe: id }, () => {
      // On get les roles associés aux groupes
      axios.get(Store.wsPath + '/1/roles/getAllRoleByIdUser', {
        params: {
          idUser: id
        }
      }).then(responseRole => {
        this.setState({ roleAssociatedToGroup: responseRole.data, loading: { ...this.state.loading, modal: false } });
      }).catch(error => {
        Store.snackBar = {
          message: "Une erreur c'est produite, veuillez réessayer",
          error: true,
          open: true
        };
        this.setState({ loading: { ...this.state.loading, modal: false }, error: { role: true } })
      })
    });
  };

  modalClose = () => {
    this.setState({ openModal: false, idUserGroupe: undefined, loading: { modal: false, deleteButton: false }, error: { role: false }, roleAssociatedToGroup: [] });
  }

  public getAllGroup = () => {
    axios.get(Store.wsPath + '/1/paramGroupes/getAllGroupe', {}).then(response => {
      getAllUsers();
      this.setState({ allGroup: response.data })
    }).catch(error => {
      Store.snackBar.message = "Une erreur c'est produite, veuillez réessayez"
      Store.snackBar.open = true;
      Store.snackBar.error = true;
    })
  }

  public numberUserInGroup = (cell: any, row: any) => {
    if (cell != undefined && cell != null && cell.length > 0) {
      return cell.length;
    }
    else {
      return "0";
    }
  }

  public render() {
    //console.log('userOfGroupPopover', this.state.userOfGroupPopover)
    const options = {
      sortIndicator: true,
      defaultSortName: 'id',
      noDataText: 'Aucun groupe à afficher',
      sizePerPage: 25,
    };
    return (
      <div className={styles.mainContent}>
        <div className={styles.titleParam} style={{ background: Store.themeParam.backgroundPrimary }}><h5>Groupes</h5></div>
        {/* <div className={styles.groupContent}>
                </div> */}
        <div className={styles.groupContent}>
          <Button onClick={this.onNewGroup} variant="contained" color="primary"><Icon>add</Icon>Ajouter un nouveau groupe</Button>
          <BootstrapTable
            multiColomnSearch
            data={this.state.allGroup}
            searchPlaceholder='Rechercher dans la liste'
            search={true}
            options={options}
            pagination
            version='4'
            bordered={true}
            className={styles.contentMyTasks}
          >
            <TableHeaderColumn isKey={true} dataField='id' hidden dataAlign='center' headerAlign='center'>id</TableHeaderColumn>
            <TableHeaderColumn dataSort={true} dataField='firstname' dataAlign='center' headerAlign='center' width='18%' >Nom du groupe</TableHeaderColumn>
            <TableHeaderColumn dataSort={true} dataField='usersOfGroup' dataAlign='center' headerAlign='center' width='4%' dataFormat={this.numberUserInGroup}>Nombre d'utilisateurs</TableHeaderColumn>
            <TableHeaderColumn headerAlign='center' width='3%' dataField='id' tdStyle={{ padding: '0', verticalAlign: 'inherit' }} dataFormat={this.toolbox.bind(this)}>Actions</TableHeaderColumn>
          </BootstrapTable>
        </div>
        <Modal
          open={this.state.openModal}
          className={modal.modal}
          onClose={this.modalClose}
        >
          <div className={modal.modalContent}>
            {
              this.state.error.role == true ?
                <React.Fragment>
                  <Typography variant="h5" id="modal-title">
                    ⚠️Erreur
                  </Typography>
                  <br />
                  <Typography variant="subtitle2">
                    Une erreur est survenue, veuillez réesayer...
                  </Typography>
                  <br />
                  <div className={modal.modalButton}>
                    <Button
                      onClick={this.modalClose}
                      className={modal.button}
                      variant="contained"
                      color="secondary"
                    >
                      Fermer
                    </Button>
                  </div>
                </React.Fragment>
                :
                this.state.loading.modal == true ?
                  <Spinner fullWidth={true} />
                  :
                  <React.Fragment>
                    <Typography variant="h5">
                      Suppression
                    </Typography>
                    <br />
                    {
                      this.state.roleAssociatedToGroup.length > 0 ?
                        <React.Fragment>
                          <Typography variant="subtitle2">
                            Ce groupe est associé aux rôles suivants :
                        </Typography>
                          <ul>
                            {
                              this.state.roleAssociatedToGroup.map(role => {
                                return (<li>{role.name}</li>);
                              })
                            }
                          </ul>
                          <Typography style={{ color: "red" }} variant="subtitle2">
                            Si vous supprimez ce groupe il sera également supprimé des rôles ci-dessus.
                        </Typography>
                          <Typography variant="subtitle2">
                            Vous devrez peut-être mettre à jour ces rôles avec un autre groupe/utilisateur.<br />
                            Tips: Vous pouvez aller mettre à jour les rôles ci-dessus avant de supprimer le groupe.
                        </Typography>
                          <br />
                          <Typography variant="subtitle2">
                            Voulez vous vraiment supprimer ce groupe ?
                        </Typography>
                        </React.Fragment>
                        :
                        <Typography variant="subtitle2">
                          Vous allez supprimer ce groupe, êtes vous sûr de cette action ?
                      </Typography>
                    }
                    <br />
                    <div className={modal.modalButton}>
                      <Button
                        onClick={this.modalClose}
                        className={modal.button}
                        variant="contained"
                        color="secondary"
                        disabled={this.state.loading.deleteButton}
                      >
                        Annuler
                      </Button>
                      <Button
                        className={modal.button}
                        onClick={this.onDeleteGroup}
                        variant="contained"
                        color="primary"
                        disabled={this.state.loading.deleteButton}
                      >
                        {
                          this.state.loading.deleteButton == true ?
                            <Spinner size={24} />
                            :
                            "Valider"
                        }
                      </Button>
                    </div>
                  </React.Fragment>
            }

          </div>
        </Modal>

      </div>
    )
  }
}