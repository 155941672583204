import * as React from "react";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Store from "../store/store";
import axios from "axios";
import { observer } from "mobx-react";
import Draggable from "react-draggable"; // Both at the same time
import Modal from "@material-ui/core/Modal/Modal";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { IPostItProps } from "./interfaces/IPostItProps";
import { IPostItState } from "./interfaces/IPostItState";
import Spinner from "../Spinner/Spinner";
import { IWfPostIt, IPostItMessage, ITaskPostIt } from "./interfaces/IPostIt";
import { withRouter, RouteComponentProps } from "react-router";
const modal = require("../../styles/styles.module.scss");
const styles = require("./styles/PostIt_style.scss");


@observer
class PostIt extends React.Component<RouteComponentProps & IPostItProps, IPostItState> {
  public constructor(props: RouteComponentProps & IPostItProps) {
    // Dans le constructeur, on initialise toujours les states
    super(props);
    this.state = {
      openModal: false,
      addMessage: "",
      postItSelected: undefined,
      loading: false,
      loadingButton: false,
      addPostItTask: false,
      resetPositionPostItId: null,
      positionPostIts: [],
      postItSelectedType: null, // "WF" ou "Task"
      postItIdEdit: null,
      editMessage: false,
      messageSelectedId: null,
      openModalDelete: false
    };
  }

  componentWillReceiveProps(nextProps: IPostItProps) {
    // Condition pour l'ajout d'un message dans le post-it jaune en dehors du composant
    if (nextProps.addMessagePostIt.add != undefined && nextProps.addMessagePostIt.add == true) {
      const message = nextProps.addMessagePostIt.message;
      this.addMessagePostIt("WF", this.props.postIt.id, message, false);
      Store.addMessagePostIt = { message: "", add: false };
    }
  }

  componentDidUpdate() {
    const postItJaune = document.querySelector('#PostItJaune .simplebar-content');
    if (postItJaune != null) {
      postItJaune.scrollTo({ top: 99999999999999, behavior: "smooth" });
    }

    const postItBleus = document.querySelectorAll('div[id^="PostItBleu"] .simplebar-content');
    if (postItBleus != undefined && postItBleus != null) {
      const bleusArray = Array.from(postItBleus);
      bleusArray.forEach(postItBleu => {
        postItBleu.scrollTo({ top: 99999999999999, behavior: "smooth" });
      });
    }
  }

  // Fonction pour formater la date pour l'affichage dans les post its
  public formatDateForDisplaying = (date: Date) => {
    const dateFormated = new Date(date);
    const jour = dateFormated.getDate();
    let jourFormated: any = jour;
    const mois = dateFormated.getMonth() + 1;
    let moisFormated: any = mois;
    const annee = dateFormated.getFullYear();
    const heures = dateFormated.getHours();
    const minutes = dateFormated.getMinutes();
    let minutesFormated: any = minutes;

    if (mois < 10) {
      moisFormated = "0" + mois;
    }
    if (jour < 10) {
      jourFormated = "0" + jour;
    }
    if (minutes < 10) {
      minutesFormated = "0" + minutes;
    }

    return ("le " + jourFormated + "/" + moisFormated + "/" + annee + " à " + heures + "h" + minutesFormated);
  };

  // Fonction pour ouvrir la modal
  public openModal = (typePostIt: string, idPostIt: number, addPostItTask: boolean) => {
    this.setState({
      openModal: true,
      postItSelected: idPostIt,
      postItSelectedType: typePostIt,
      addPostItTask: addPostItTask
    });
  };


  // Fonction pour ouvrir la modal de suppression d'un message
  public openModalDelete = (messageSelectedId: number) => event => {
    this.setState({
      openModalDelete: true,
      messageSelectedId: messageSelectedId
    });
  };

  // Fonction appelée lors de la saisie du message pour un post it
  public onChange = (event) => {
    this.setState({ addMessage: event.target.value });
  };

  // Fonction qui ajoute un message sur le post it reçu en paramétre
  public addMessagePostIt = (typePostIt: string, postItId: number, message: string, editable?: boolean) => {
    this.setState({ loadingButton: true }, () => {
      const type: string = typePostIt == "WF" ? "WF" : "task";
      axios.post(Store.wsPath + "/1/postIt/addMessage", {
        type: type,
        message: message,
        idxUser: Store.userConnected.id,
        idxPostIt: postItId,
        idxTaskPostIt: postItId,
        idWF: Store.workflow.id,
        editable: editable
      }).then((responseAddMessage) => {
        if (responseAddMessage.status == 200) {
          this.closeEditModeAndModal();

          Store.reload = {
            form: true,
            banette: false
          };

          this.props.history.push("/document?w=" + Store.workflow.id);

          // Snackbar
          Store.snackBar = {
            error: false,
            message: "Message ajouté",
            open: true
          }
          /////////////
        }
      }).catch((error) => {
        console.error("error ajout message : ", error);
        // Snackbar
        Store.snackBar = {
          error: true,
          message: "Erreur lors de l'ajout du message",
          open: true
        }
        /////////////
        this.setState({ loadingButton: false });
      });
    });
  };

  // Fonction pour afficher la modale permetant l'ajout d'un post it et de son message sur une tâche
  public addPostItOnTask = () => {
    const idTaskSelected: number = Store.myStepTaskSelectionned.id;
    if (idTaskSelected != undefined && idTaskSelected != null) {
      this.openModal("Task", null, true);
    }
  };

  // Fonction appelée pour créer un post it bleu sur une tâche et ajouter un message en même temps
  public createPostItTaskAndMessage = () => {
    axios.post(Store.wsPath + "/1/postIt/addPostItTask", {
      taskId: Store.myStepTaskSelectionned.id,
      userId: Store.userConnected.id, // C'est possible que ça provoque des bugs => thomas
      // userId : Store.myStepTaskSelectionned.User.id C'est possible que ça provoque des bugs => john
    }).then((responseCreatedPostIt) => {
      if (responseCreatedPostIt.status == 200) {
        this.addMessagePostIt("Task", responseCreatedPostIt.data.data.id, this.state.addMessage, true);
      }
    })
      .catch((error) => {
        console.error("error création post-it : ", error);
        // Snackbar
        Store.snackBar.error = true;
        Store.snackBar.message = "Erreur lors de la création du post-it";
        Store.snackBar.open = true;
        /////////////
      });
  };

  // Fonction pour enregistrer la position en base du post it jaune
  public savePosition = (event, postItType: string, value: any, idPostIt: number) => {
    const valueX: number = value.x;
    const valueY: number = value.y;
    const position = { x: valueX, y: valueY };

    axios.post(Store.wsPath + "/1/postIt/savePositionPostit", {
      idPostIt: idPostIt,
      position: position,
      postItType: postItType
    }).then((responsePosition) => {
    }).catch((error) => {
      console.error("Error save position postit : ", error);
    });
  };

  // Update message post it
  public updateMessage = (idMessageSelected: number, message: string) => event => {
    this.setState({ loadingButton: true }, () => {
      axios.post(Store.wsPath + "/1/postIt/updateMessage", {
        idMessage: idMessageSelected,
        message: message
      }).then((responseUpdate) => {
        if (responseUpdate.status == 200) {
          this.closeEditModeAndModal();

          Store.reload = {
            form: true,
            banette: false
          };

          this.props.history.push("/document?w=" + Store.workflow.id);

          // Snackbar
          Store.snackBar = {
            error: false,
            message: "Message modifié",
            open: true
          }
          /////////////
        }
      }).catch((error) => {
        console.error("Error 'updateMessage' postit : ", error);
        this.setState({ loadingButton: false });
        // Snackbar
        Store.snackBar = {
          error: true,
          message: "Erreur lors de l'ajout du message",
          open: true
        }
        /////////////
      });
    });
  }

  // Delete message post it
  public deleteMessage = (idMessageSelected: number) => event => {
    this.setState({ loadingButton: true }, () => {
      axios.post(Store.wsPath + "/1/postIt/deleteMessage", {
        idMessage: idMessageSelected
      }).then((responseUpdate) => {
        if (responseUpdate.status == 200) {
          this.closeEditModeAndModal();

          Store.reload = {
            form: true,
            banette: false
          };

          this.props.history.push("/document?w=" + Store.workflow.id);

          // Snackbar
          Store.snackBar = {
            error: false,
            message: "Message supprimé",
            open: true
          }
          /////////////
        }
      }).catch((error) => {
        console.error("Error 'deleteMessage' postit : ", error);
        this.setState({ loadingButton: false });
        // Snackbar
        Store.snackBar = {
          error: true,
          message: "Erreur lors de la suppression du message",
          open: true
        }
        /////////////
      });
    });
  }

  // Fonction render qui affiche un contenue pour l'ajout d'un message
  public renderEditView = () => {
    const onClickParam: any = {};
    if (this.state.addMessage.length > 0) {
      if (this.state.editMessage == true) {
        onClickParam["onClick"] = this.updateMessage(this.state.messageSelectedId, this.state.addMessage);
      }
      else {
        onClickParam["onClick"] = () => this.addMessagePostIt(this.state.postItSelectedType, this.state.postItSelected, this.state.addMessage, true);
      }
    }
    return (
      <div className={styles.editContainer} onDoubleClick={this.closeEditModeAndModal}>
        <div>
          <TextField
            onChange={this.onChange}
            style={{ width: "100%" }}
            label="Message"
            variant="outlined"
            minRows={2}
            maxRows={Infinity}
            type="text"
            multiline={true}
            required={true}
            defaultValue={this.state.addMessage}
          />
        </div>
        <div className={styles.buttonContainer}>
          {
            this.state.loadingButton == true ?
              <Spinner size={24} />
              :
              <Icon
                className={this.state.addMessage.length > 0 ? styles.iconSend + " " + styles.iconActive : styles.iconSend}
                title={this.state.editMessage == true ? "Mettre à jour le message" : "Poster le message"}
                {...onClickParam}
              >
                send
          </Icon>
          }
        </div>
      </div>
    )
  }

  // Fonction appelée pour fermer le mode edition dans les post it
  public closeEditModeAndModal = () => {
    this.setState({
      postItIdEdit: null,
      postItSelected: null,
      postItSelectedType: null,
      addPostItTask: false,
      addMessage: "",
      loadingButton: false,
      editMessage: false,
      messageSelectedId: null,
      openModal: false,
      openModalDelete: false
    });
  }

  // Fonction pour ouvrir le mode edition dans un post it
  public onDoubleClickPostIt = (typePostIt: string, idPostIt: number, editMessage: boolean, messageEdit?: string, messageSelectedId?: number) => event => {
    this.setState({
      postItIdEdit: idPostIt,
      postItSelected: idPostIt,
      postItSelectedType: typePostIt,
      editMessage: editMessage,
      addMessage: messageEdit != undefined && messageEdit != null ? messageEdit : "",
      messageSelectedId: messageSelectedId != undefined && messageSelectedId != null ? messageSelectedId : null
    });
  }

  // Fonction qui affiche le post it jaune
  public renderPostItJaune = (postIt: IWfPostIt) => {
    const positionPostIt: any = this.state.positionPostIts.filter(pos => pos.idPostIt == postIt.id && pos.type == "WF").length > 0 ? this.state.positionPostIts.filter(pos => pos.idPostIt == postIt.id && pos.type == "WF")[0].position : postIt.positionPostIt != undefined && postIt.positionPostIt != null ? postIt.positionPostIt : null;
    return (
      <Draggable
        position={
          positionPostIt
        }
        onStop={(event, value) => {
          this.savePosition(event, "WF", value, postIt.id);
        }}
        handle="strong"
        onDrag={this.onDragPostIt(postIt.id, "WF")}
      >
        <div id="PostItJaune" className={styles.postIt}>
          <strong title="Cliquer pour déplacer le post-it" className={styles.iconContainer}>
            <Icon
              className={styles.iconAction}
              title={this.state.postItIdEdit == postIt.id ? "Fermer édition" : "Ajouter un message"}
              onClick={this.state.postItIdEdit == postIt.id ? this.closeEditModeAndModal : this.onDoubleClickPostIt("WF", postIt.id, false)}
            >
              {
                this.state.postItIdEdit == postIt.id ? "close" : "add_comment"
              }
            </Icon>
            {/* Activation de la posibilité de mettre un message meme le wf terminé ou refusé {
              this.props.workflowEnd == false ?
                <Icon
                  className={styles.iconAction}
                  title={this.state.postItIdEdit == postIt.id ? "Fermer édition" : "Ajouter un message"}
                  onClick={this.state.postItIdEdit == postIt.id ? this.closeEditModeAndModal : this.onDoubleClickPostIt("WF", postIt.id, false)}
                >
                  {
                    this.state.postItIdEdit == postIt.id ? "close" : "add_comment"
                  }
                </Icon>
                :
                ""
            } */}
            <Icon
              className={styles.iconAction}
              title="Réinitialiser la position du post it"
              onClick={this.resetPosition("jaune", postIt.id)}
            >
              flip_to_front
            </Icon>
          </strong>
          {
            this.state.postItIdEdit == postIt.id ?
              this.renderEditView()
              :
              <SimpleBar onDoubleClick={this.props.workflowEnd == false ? this.onDoubleClickPostIt("WF", postIt.id, false) : () => { }} className={styles.messagesContainer}>
                {postIt.PostItMessages.length > 0 || Store.messageMotifRefus != "" || Store.messageMotifReturn ?
                  <React.Fragment>
                    {postIt.PostItMessages.map((message: IPostItMessage, index) => {
                      return (
                        <div key={message.id} className={styles.messageContainer} style={
                          message.idxUser == Store.userConnected.id
                            ? { justifyContent: "flex-end" }
                            : {}
                        }>

                          <div className={styles.messageContent}>
                            {
                              message.message.endsWith(".gif") && (message.message.startsWith("https://") || message.message.startsWith("http://")) ?
                                <img className={styles.imgStyle} src={message.message} />
                                :
                                <p className={styles.messageStyle} dangerouslySetInnerHTML={{ __html: message.message }}></p>
                            }
                            <p className={styles.titreMessage}>
                              {message.User.firstname + " " + message.User.lastname + " - "}
                              {this.formatDateForDisplaying(message.createdAt)}
                            </p>
                          </div>
                          {
                            message.idxUser == Store.userConnected.id && message.editable == true /* && this.props.workflowEnd == false */ ?
                              <div className={styles.iconsActionMessageContainer}>
                                <Icon
                                  className={styles.iconActionMessage}
                                  title="Modifier le message"
                                  onClick={this.onDoubleClickPostIt("Task", postIt.id, true, message.message, message.id)}
                                >
                                  edit
                                </Icon>
                                <Icon
                                  className={styles.iconActionMessage}
                                  title="Supprimer le message"
                                  onClick={this.openModalDelete(message.id)}
                                >
                                  delete
                                </Icon>
                              </div>
                              :
                              <React.Fragment></React.Fragment>
                          }
                        </div>
                      );
                    })}
                  </React.Fragment>
                  :
                  <div className={styles.noMessageContainer} >
                    {this.state.loading == true ? (
                      <Spinner />
                    ) : (
                        "Pas de message pour l'instant"
                      )}
                  </div>
                }
              </SimpleBar>
          }
        </div>
      </Draggable>
    );
  };

  // Fonction pour afficher les post it bleus
  public renderPostItBleu = (postItList: ITaskPostIt[]) => {
    if (postItList != undefined && postItList != null) {
      let deporter = 0;
      return postItList.map((postIt: ITaskPostIt, index: number) => {
        const positionPostIt: any = this.state.positionPostIts.filter(pos => pos.idPostIt == postIt.id && pos.type == "Task").length > 0 ? this.state.positionPostIts.filter(pos => pos.idPostIt == postIt.id && pos.type == "Task")[0].position : postIt.positionPostIt != undefined && postIt.positionPostIt != null ? postIt.positionPostIt : null;
        deporter = deporter + 20;
        return (
          <Draggable
            key={"postItBleu" + postIt.id}
            handle="strong"
            position={
              positionPostIt
            }
            onStop={(event, value) => {
              this.savePosition(event, "Task", value, postIt.id);
            }}
            onDrag={this.onDragPostIt(postIt.id, "Task")}
          >
            <div
              id={"PostItBleu" + postIt.id}
              className={styles.postIt + " postItBleu"}
              style={{
                position: "absolute",
                top: deporter + "px",
                left: deporter + "px",
              }}
            >
              <strong className={styles.iconContainer + " " + styles.iconContainerBleu}>
                {
                  postIt.User != undefined && postIt.User != null && this.props.taskAndPostIts.User != undefined && this.props.taskAndPostIts.User != null ?
                    <span style={{ paddingLeft: "5px" }}>{Store.userConnected.id == postIt.idxUser? "Discussion avec " + this.props.taskAndPostIts.User.firstname + " "+ this.props.taskAndPostIts.User.lastname : "Discussion avec " + postIt.User.firstname +" "+postIt.User.lastname}</span>
                    :
                    <span></span>
                }
                <div>
                  {
                    this.props.workflowEnd == false ?
                      <Icon
                        className={styles.iconAction}
                        title={this.state.postItIdEdit == postIt.id ? "Fermer édition" : "Ajouter un message"}
                        onClick={this.state.postItIdEdit == postIt.id ? this.closeEditModeAndModal : this.onDoubleClickPostIt("Task", postIt.id, false)}
                      >
                        {
                          this.state.postItIdEdit == postIt.id ? "close" : "add_comment"
                        }
                      </Icon>
                      :
                      ""
                  }
                  <Icon
                    className={styles.iconAction}
                    title="Réinitialiser position post-it"
                    onClick={this.resetPosition("bleu", postIt.id)}
                  >
                    flip_to_front
                </Icon>
                  <Icon
                    className={styles.iconAction}
                    title="Fermer le post-it"
                    onClick={() => { document.getElementById("PostItBleu" + postIt.id).style.display = "none"; }}
                  >
                    close
                </Icon>
                </div>
              </strong>
              {
                this.state.postItIdEdit == postIt.id ?
                  this.renderEditView()
                  :
                  <SimpleBar onDoubleClick={this.props.workflowEnd == false ? this.onDoubleClickPostIt("Task", postIt.id, false) : () => { }} className={styles.messagesContainer}>
                    {postIt.PostItMessages.length > 0 ? (
                      <React.Fragment>
                        {postIt.PostItMessages.map((message: IPostItMessage, index: number) => {
                          return (
                            <div key={message.id} className={styles.messageContainer} style={
                              message.idxUser == Store.userConnected.id
                                ? { justifyContent: "flex-end" }
                                : {}
                            }>
                              <div className={styles.messageContent}>
                                {
                                  message.message.endsWith(".gif") && (message.message.startsWith("https://") || message.message.startsWith("http://")) ?
                                    <img className={styles.imgStyle} src={message.message} />
                                    :
                                    <p className={styles.messageStyle} dangerouslySetInnerHTML={{ __html: message.message }}></p>
                                }
                                <h3 className={styles.titreMessage}>
                                  {message.User != undefined && message.User != null ? message.User.firstname + " " + message.User.lastname + " - " : " - "}
                                  {this.formatDateForDisplaying(message.createdAt)}
                                </h3>
                              </div>
                              {
                                message.idxUser == Store.userConnected.id && message.editable == true && this.props.workflowEnd == false ?
                                  <div className={styles.iconsActionMessageContainer}>
                                    <Icon
                                      className={styles.iconActionMessage}
                                      title="Modifier le message"
                                      onClick={this.onDoubleClickPostIt("Task", postIt.id, true, message.message, message.id)}
                                    >
                                      edit
                                    </Icon>
                                    <Icon
                                      className={styles.iconActionMessage}
                                      title="Supprimer le message"
                                      onClick={this.openModalDelete(message.id)}
                                    >
                                      delete
                                    </Icon>
                                  </div>
                                  :
                                  <React.Fragment></React.Fragment>
                              }
                            </div>
                          );
                        })}
                      </React.Fragment>
                    ) : (
                        <div className={styles.noMessageContainer}>
                          Pas de message pour l'instant
                    </div>
                      )}
                  </SimpleBar>
              }
            </div>
          </Draggable>
        );
      })
    }
    else {
      return <React.Fragment></React.Fragment>
    }
  };

  // Fonction pour remettre à zéro la position des post its, reçoit le type en param, "jaune", "bleu" ou "all"
  public resetPosition = (type: string, idPostIt?: number) => event => {
    const defaultPosition = { x: 0, y: 0 };
    // Si le reset est jaune ou bleu
    if (type == "jaune" || type == "bleu") {
      const typepostIt: string = type == "jaune" ? "WF" : "Task";
      if (this.state.positionPostIts.filter(po => po.idPostIt == idPostIt && po.type == typepostIt).length > 0) {
        const tempState = this.state.positionPostIts;
        tempState.forEach(postPos => {
          if (postPos.idPostIt == idPostIt && postPos.type == typepostIt) {
            postPos.position = defaultPosition;
          }
        });
        this.setState({ positionPostIts: tempState });
      }
      else {
        const tempState = this.state.positionPostIts;
        tempState.push({ idPostIt: idPostIt, type: typepostIt, position: defaultPosition });
        this.setState({ positionPostIts: tempState });
      }

      // Réinitialiser position post it jaune
      this.savePosition("", typepostIt, defaultPosition, idPostIt);
    }

    // Si le reset est tous les post its
    if (type == "all") {
      const jaune: IWfPostIt = this.props.postIt;
      const bleus: ITaskPostIt[] = this.props.taskAndPostIts.TaskPostIts;

      let tempState = this.state.positionPostIts;
      // On check le jaune
      if (this.state.positionPostIts.filter(po => po.idPostIt == jaune.id && po.type == "WF").length > 0) {
        tempState.forEach(postPos => {
          if (postPos.idPostIt == jaune.id && postPos.type == "WF") {
            postPos.position = defaultPosition;
          }
        });
      }
      else {
        tempState.push({ idPostIt: jaune.id, type: "WF", position: defaultPosition });
      }

      // sauvegarde en base de la position
      this.savePosition("", "WF", defaultPosition, jaune.id);

      // La on fait les bleus
      if (bleus != undefined && bleus != null) {
        bleus.forEach(postBleu => {
          if (this.state.positionPostIts.filter(po => po.idPostIt == postBleu.id && po.type == "Task").length > 0) {
            tempState.forEach(postPos => {
              if (postPos.idPostIt == postBleu.id && postPos.type == "Task") {
                postPos.position = defaultPosition;
              }
            });
          }
          else {
            tempState.push({ idPostIt: postBleu.id, type: "Task", position: defaultPosition });
          }

          // Modification de la position de chaque post It en base
          this.savePosition("", "Task", defaultPosition, postBleu.id);
        });
      }
      this.setState({ positionPostIts: tempState });
    }
  };

  // Fonction appelée lors du glissé des post it
  public onDragPostIt = (idPostIt: number, typePostIt: string) => (e, value) => {
    const valueX: number = value.x;
    const valueY: number = value.y;
    const position = { x: valueX, y: valueY };
    if (this.state.positionPostIts.filter(po => po.idPostIt == idPostIt && po.type == typePostIt).length > 0) {
      const tempState = this.state.positionPostIts;
      tempState.forEach(postPos => {
        if (postPos.idPostIt == idPostIt && postPos.type == typePostIt) {
          postPos.position = position;
        }
      });
      this.setState({ positionPostIts: tempState });
    }
    else {
      const tempState = this.state.positionPostIts;
      tempState.push({ idPostIt: idPostIt, type: typePostIt, position: position });
      this.setState({ positionPostIts: tempState });
    }
  }

  public render() {
    return (
      <div className={styles.postItComponent}>
        <h6>Echanges :</h6>
        {
          this.props.postIt != undefined && this.props.postIt != null ?
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                position: "relative",
              }}
            >
              {
                this.props.workflowEnd == false && this.props.taskAndPostIts != undefined && this.props.taskAndPostIts != null && this.props.taskAndPostIts.TaskPostIts != undefined && this.props.taskAndPostIts.TaskPostIts != null && this.props.taskAndPostIts.TaskPostIts.find((postIt) => postIt.idxUser == Store.userConnected.id) == undefined ?
                  <Icon
                    onClick={this.addPostItOnTask}
                    style={{ cursor: "pointer", color: "#3157b7" }}
                    title="Ouvrir un échange privé pour cette tâche"
                    color="primary"
                  >
                    add_comment
                  </Icon>
                  :
                  ""
              }
              <Icon
                onClick={this.resetPosition("all")}
                title="Réinitialiser la position des post-it"
                color="primary"
                className={styles.btnPostit}
              >flip_to_front</Icon>
            </div>
            :
            ""
        }



        <div className={styles.postItContainer}>
          {
            this.props.postIt != undefined && this.props.postIt != null ?
              this.renderPostItJaune(this.props.postIt)
              :
              <div className={styles.noWorkflowMessage} >
                Pas de WorkFlow pour l'instant !
              </div>
          }


          {
            this.props.taskAndPostIts != undefined && this.props.taskAndPostIts != null  && this.props.taskAndPostIts.TaskPostIts != undefined && this.props.taskAndPostIts.TaskPostIts != null && this.props.taskAndPostIts.TaskPostIts.length > 0 ?
              this.renderPostItBleu((Store.myStepTaskSelectionned.User.isGroup != true && Store.myStepTaskSelectionned.userIdx == Store.userConnected.id) || (Store.myStepTaskSelectionned.User.isGroup == true && Store.myStepTaskSelectionned.User.usersOfGroup.filter(user => user == Store.userConnected.id).length > 0) ? this.props.taskAndPostIts.TaskPostIts : this.props.taskAndPostIts.TaskPostIts.filter(pos => pos.idxUser == Store.userConnected.id))
              :
              ""
          }


          <Modal
            open={this.state.openModal}
            className={modal.modal}
            onClose={() => this.closeEditModeAndModal()}
          >
            <div className={modal.modalContent}>
              <Typography variant="h5" id="modal-title">
                Ajouter un message
              </Typography>
              <br />
              <div>
                <TextField
                  onChange={this.onChange}
                  style={{ width: "100%" }}
                  label="Message"
                  variant="outlined"
                  minRows={2}
                  maxRows={Infinity}
                  type="text"
                  multiline={true}
                />
              </div>
              <br />
              <div className={modal.modalButton}>
                <Button
                  onClick={() => this.closeEditModeAndModal()}
                  className={modal.button}
                  variant="contained"
                  color="secondary"
                >
                  Annuler
                </Button>
                <Button
                  className={modal.button}
                  onClick={this.state.addPostItTask == true ?
                    this.createPostItTaskAndMessage
                    :
                    () => {
                      this.addMessagePostIt(this.state.postItSelectedType, this.state.postItSelected, this.state.addMessage, true);
                    }
                  }
                  variant="contained"
                  color="primary"
                  disabled={this.state.addMessage.length == 0 || this.state.loadingButton == true ? true : false}
                >
                  {
                    this.state.loadingButton == true ?
                      <Spinner size={24} />
                      :
                      "Ajouter"
                  }
                </Button>
              </div>
            </div>
          </Modal>

          <Modal
            open={this.state.openModalDelete}
            className={modal.modal}
            onClose={() => this.closeEditModeAndModal()}
          >
            <div className={modal.modalContent}>
              <Typography variant="h5" id="modal-title">
                Supprimer un message
              </Typography>
              <br />
              <p>Voulez vous vraiment supprimer ce message ?</p>
              <br />
              <div className={modal.modalButton}>
                <Button
                  onClick={() => this.closeEditModeAndModal()}
                  className={modal.button}
                  variant="contained"
                  color="secondary"
                >
                  Annuler
                </Button>
                <Button
                  className={modal.button}
                  onClick={this.deleteMessage(this.state.messageSelectedId)}
                  variant="contained"
                  color="primary"
                  disabled={this.state.loadingButton == true ? true : false}
                >
                  {
                    this.state.loadingButton == true ?
                      <Spinner size={24} />
                      :
                      "Supprimer"
                  }
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withRouter(PostIt);