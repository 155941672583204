import * as React from 'react';
const styles = require('../../styles/styles.module.scss');
import { observer } from "mobx-react";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import Store, { getAllRoles } from '../store/store';
import axios from 'axios'
import SelectReact, { createFilter } from 'react-select';
import{MenuList , customSingleValue} from './../helper/reactSelectCustomRender'

interface Props {
}

interface State {
}

@observer
export default class AddEditRole extends React.Component<Props, State>{
    public constructor(props: Props) { // Dans le constructeur, on initialise toujours les states
        super(props)
        this.state = {
        }
    }

    public onChange = (name: string) => event => {
        if (name == "nameRole") {
            Store.addEditRole.name = event.target.value
        } 
    }

    public onChangeUser = (option) => {
        if (option != null) {
            Store.addEditRole.idxUser = option.value;
        }
        else {
            Store.addEditRole.idxUser = null;
        }
    }

    public addRole = (event) => {
        event.preventDefault()

        axios.post(Store.wsPath + `/1/roles/createRole`, {
            nameRole: Store.addEditRole.name,
            idUser: Store.addEditRole.idxUser
        }).then(response => {
            if (response.status == 200) {
                Store.contextualmenu.open = "hidden";
                Store.contextualmenu.content = undefined;
                Store.addEditRole = Store.DefaultValueRole;
                // SnackBar
                Store.snackBar.message = "Rôle ajouté avec succés"
                Store.snackBar.open = true;
                Store.snackBar.error = false;
                getAllRoles();
            }
            else {
                // SnackBar
                Store.snackBar.message = "Une erreur c'est produite, réessayez"
                Store.snackBar.open = true;
                Store.snackBar.error = true;
            }
        })
    }

    public editRole = (event) => {
        event.preventDefault()
        axios.post(Store.wsPath + `/1/roles/updateRole`, {
            id: Store.addEditRole.id,
            nameRole: Store.addEditRole.name,
            idUser: Store.addEditRole.idxUser
        }).then(response => {
            if (response.status == 200) {
                Store.contextualmenu = {
                    open: "hidden",
                    content: undefined
                };
                Store.addEditRole = Store.DefaultValueRole;
                // SnackBar
                Store.snackBar = {
                    error: false,
                    message: "Rôle modifié avec succés",
                    open: true
                };
                getAllRoles();
            }
            else {
                // SnackBar
                Store.snackBar.message = "Une erreur c'est produite, réessayez"
                Store.snackBar.open = true;
                Store.snackBar.error = true;
            }
        })
    }

    public updateReactSelect = (user: any) => {
        const myUser = Store.suggestionsUser.find(suggestion => suggestion.value == user);
        return myUser
    }

    
     public renderAddEditRole = () => {
        /* const MenuList = ({ cx, children, getStyles, innerRef, ...props })  => {
            console.log('fnzefjkzef', props)
            //
                return (
                    <div ref={innerRef}​​​​​  title={props.data.mail} {...props.innerProps} className={cx(css(getStyles('option', props)),{'option': true,'option--is-disabled': props.innerProps.isDisabled,'option--is-focused': props.innerProps.isFocused,'option--is-selected': props.innerProps.isSelected,})}>
                        <span>{props.data.label}​​​​​</span>
                    </div>
                );
        }

        const customSingleValue = ({ data }) => {
            console.log("Console log data",data)
            return (
            <div className="input-select" >
                <div className={" input-select__single-value" } title={data.mail}>
                    <span>{data.label}</span>
                </div>
            </div>
        )}; */
        return (
            <form key="myForm" autoComplete="false" onSubmit={Store.contextualmenu.content == "addRole" ? this.addRole : this.editRole}>
                <h5 className={styles.titleContextual}>{Store.contextualmenu.content == "addRole" ? "Ajouter un rôle" : "Modifier le rôle"}</h5>
                <TextField
                    // InputLabelProps={{ shrink: true }}
                    variant='outlined'
                    className={styles.field}
                    required
                    type="text"
                    value={Store.addEditRole.name != undefined ? Store.addEditRole.name : ""}
                    onChange={this.onChange("nameRole")}
                    label="Nom du rôle"
                />

                <InputLabel>Personne assignée au rôle :</InputLabel>
                <div style={{ position: "relative" }}>
                    <SelectReact
                        options={Store.suggestionsUser} 
                        onChange={option => this.onChangeUser(option ? option : null)}
                        placeholder="Personne assignée au rôle"
                        filterOption={createFilter({ ignoreAccents: false })}
                        value={Store.addEditRole.idxUser != undefined ? this.updateReactSelect(Store.addEditRole.idxUser) : null}
                        className={styles.field}
                        styles={{ control: styles => ({ ...styles, backgroundColor: 'transparent', padding: '2px 0' }) }}
                        components={{ SingleValue: customSingleValue , Option: MenuList}}
                        isClearable
                    />
                </div>

                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                >
                    {Store.contextualmenu.content == "addRole" ?
                        "Valider"
                        :
                        "Modifier"
                    }
                </Button>
                <Button
                    type="button"
                    variant="contained"
                    className={styles.btnCancel}
                    color="default"
                    onClick={() => { Store.contextualmenu.open = "hidden"; Store.contextualmenu.content = undefined; Store.addEditRole = Store.DefaultValueRole; }}
                >
                    Annuler
                        </Button>
            </form>
        )
    }
    public render() {
        return (
            <div>
                {Store.contextualmenu.content == "addRole" || Store.contextualmenu.content == "editRole" ? this.renderAddEditRole() : ""}
            </div>
        )
    }
}