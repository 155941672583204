import * as React from "react";
import { IFormsListProps } from "./interfaces/IFormsListProps";
import { IFormsListState } from "./interfaces/IFormsListState";
import styles from "./styles/FormsList.scss";
import Store from "../../store/store";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import { RouteComponentProps, withRouter } from "react-router-dom";
import axios from "axios";
import { IFormInfo } from "../renderForm/interfaces/IRenderForm";
import { ITypeForm, IDBDocument } from "../../Workflow/interfaces/IWorkflow";
import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";
import { Pivot, PivotItem } from 'office-ui-fabric-react/lib/Pivot';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Publish from '@material-ui/icons/Publish';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import GetApp from '@material-ui/icons/GetApp';
import Visibility from '@material-ui/icons/Visibility';
import Dialog, { DialogFooter, DialogType } from "office-ui-fabric-react/lib/Dialog";
import { PrimaryButton, DefaultButton } from "office-ui-fabric-react/lib/Button";
import { DetailsList, DetailsListLayoutMode, SelectionMode } from '@fluentui/react/lib/DetailsList';
import Messages from "../../messages";
import ConvocationMasse from "./components/convocationMasse/ConvocationMasse";
import Accordion from "@material-ui/core/Accordion/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary/AccordionSummary";
import DescriptionIcon from "@material-ui/icons/Description";
import NoteAdd from "@material-ui/icons/NoteAdd";
import { createFicheDePoste } from "../ficheDePoste/FicheDePoste";
import TextEditor from "./components/textEditor/TextEditor";
import { DatePicker, DayOfWeek } from 'office-ui-fabric-react/lib/DatePicker';
import { DayPickerStrings } from "../renderForm/formFields/SPFieldDateTime";

class FormsList extends React.Component<IFormsListProps & RouteComponentProps, IFormsListState> {
  constructor(props: IFormsListProps & RouteComponentProps) {
    super(props);
    this.state = {
      allForms: [],
      typeFormList: [],
      loading: {
        allForms: true,
        showIntool: null,
        hideIntool: null,
        addFDP: null,
        editForm: false,
      },
      selectedForm: null,
      deleteDialogParams: {
        hideDialog: true,
        type: "form",
        cantDelete: false,
        wfLists: [],
        loading: false,
        error: false,
      },
      deleteDialogConvocParams: {
        hideDialog: true,
        loading: false,
        error: false,
      },
      deleteDialogDocumentParams: {
        hideDialog: true,
        loading: false,
        error: false,
      },
      deleteDialogWFParams: {
        hideDialog: true,
        loading: false,
        error: false,
      },
      selectedWFToDelete: null,
      selectedConvoc: null,
      selectedDocument:null,
      selectedFormInfo: null,
      fileConvocation: null,
      fileConvocationName: "",
      fileDocument: null,
      fileDocumentName: "",
      loadingButton: false,
      loadingButtonDoc:false,
      selectedDate: null
    };
  }

  public componentDidMount() {
    this.getAllInfo();
  }
  public componentDidUpdate(prevProps, prevState) {

  }

  public getAllInfo = () => {
    this.setState({ loading: { ...this.state.loading, allForms: true } }, async () => {
      try {
        const allFormTypes: ITypeForm[] = await this.getFormType();
        const userRights: any = Store.userConnected.typeUserLydoc;
        const allForms: IFormInfo[] = await this.getAllForms(userRights);
        this.setState({ allForms: allForms, typeFormList: allFormTypes, loading: { ...this.state.loading, allForms: false } });
      } catch (error) {
        console.error("Error 'getAllInfo' : ", error);
        Store.snackBar = {
          message: "Une erreur est survenue lors de la récupération des formulaires",
          error: true,
          open: true
        };
        this.setState({ loading: { ...this.state.loading, allForms: false } });
      }
    });
  }
  public getAllForms: (userRights: "all" | "ged" | "admin") => Promise<IFormInfo[]> = (userRights: "all" | "ged" | "admin") => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await axios.get(Store.wsPath + "/1/formulaire/GetAllFormsAndLinkedQueries", {
          params: {
            userRights: userRights,
            idUserConnected: Store.userConnected.id,
            userFormRights: Store.userConnected.formRights
          }
        });
        const allForms: IFormInfo[] = result.data;
        allForms.sort((a, b) => a.id - b.id)
        resolve(allForms);
      } catch (error) {
        console.error("Error 'getAllForms' : ", error);
        reject(error);
      }
    });
  }

  // Fonction pour récupérer tous les types de formulaires en base de données
  public getFormType: () => Promise<ITypeForm[]> = async () => {
    return new Promise<ITypeForm[]>(async (resolve, reject) => {
      try {
        const result = await axios.get(Store.wsPath + "/1/formulaire/GetAllFormType");
        const allTypeForm: ITypeForm[] = result.data;
        resolve(allTypeForm);
      } catch (error) {
        console.error("Error 'getFormType' : ", error);
        reject(error);
      }
    });
  }

  public newForm = () => {
    this.props.history.push("/createForm");
  }

  public getCaret(direction) {
    if (direction === 'asc') {
      return (<Icon style={{ fontSize: '26px' }} >arrow_drop_up</Icon>);
    }
    if (direction === 'desc') {
      return (<Icon style={{ fontSize: '26px' }} >arrow_drop_down</Icon>);
    }
    return (<span></span>);
  }

  public formatType = (cell: string, row) => {
    const findType = this.state.typeFormList.filter(type => type.name == cell);
    if (findType.length > 0) {
      const type = findType[0];
      return type.label;
    }
    else {
      return cell;
    }
  }

  public formatFormName = (cell: string, row) => {
    return (
      <span><i style={{ fontSize: "16px", marginRight: "5px" }} className={"fa fa-" + row.icon}></i>{cell}</span>
    )
  }

  public displayed = (cell: boolean, row) => {
    if (cell == true) {
      return "Oui";
    }
    else {
      return "Non";
    }
  }

  public onPinChange = (row) => (event) => {
    let newPined = false;
    const tempForms = this.state.allForms.map(form => {
      if (row.id == form.id) {
        newPined = !form.pined;
        form.pined = !form.pined;
      }
      return form;
    });
    this.setState({
      allForms: tempForms,
      loading: { ...this.state.loading, editForm: true }
    });
    axios.post(Store.wsPath + "/1/formulaire/EditPined", {
      idForm: row.id,
      pined: newPined,
      formType: row.formType
    })
      .then(response => {
        Store.snackBar = {
          error: false,
          message: "La modification du formulaire a bien été effectuée.",
          open: true
        };
        this.setState({ loading: { ...this.state.loading, editForm: false } });
      })
      .catch(error => {
        console.error("Error 'onPinChange' request : ", error)
        Store.snackBar = {
          error: true,
          message: "Une erreur est survenue lors de la mise à jour des données",
          open: true
        };
        this.setState({ loading: { ...this.state.loading, editForm: false } });
      })
  }
  public pined = (cell: boolean, row: IFormInfo) => {
    var checked = false;
    if ((row.pined != undefined && row.pined != null) && row.pined == true) {
      checked = true;
    }
    return <input type="checkbox" checked={checked} onChange={this.onPinChange(row)} disabled={this.state.loading.editForm} />
  }

  public showHideFormInTool = (idForm: number, show: boolean) => event => {
    if (show == true) {
      this.setState({ loading: { ...this.state.loading, showIntool: idForm } }, async () => {
        try {
          const formUpdated = await axios.post(Store.wsPath + "/1/formulaire/ShowHideFormInTool", { idForm: idForm, actif: true });
          const formInListIndex: number = this.state.allForms.findIndex(frm => frm.id == idForm);
          const allForms = [...this.state.allForms];
          allForms[formInListIndex] = {...allForms[formInListIndex], ...formUpdated.data};
          Store.snackBar = {
            message: "Formulaire disponible dans l'outil",
            error: false,
            open: true
          };
          this.setState({ allForms: allForms, loading: { ...this.state.loading, showIntool: null } })
        } catch (error) {
          console.error("Error 'showFormInTool' : ", error);
          Store.snackBar = {
            message: "Une erreur est survenue lors de l'activation du formulaire dans l'outil",
            error: true,
            open: true
          };
          this.setState({ loading: { ...this.state.loading, showIntool: null } });
        }
      });
    }
    else {
      this.setState({ loading: { ...this.state.loading, hideIntool: idForm } }, async () => {
        try {
          const formUpdated = await axios.post(Store.wsPath + "/1/formulaire/ShowHideFormInTool", { idForm: idForm, actif: false });
          const formInListIndex: number = this.state.allForms.findIndex(frm => frm.id == idForm);
          const allForms = [...this.state.allForms];
          allForms[formInListIndex] = {...allForms[formInListIndex], ...formUpdated.data};
          Store.snackBar = {
            message: "Formulaire masqué dans l'outil",
            error: false,
            open: true
          };
          this.setState({ allForms: allForms, loading: { ...this.state.loading, hideIntool: null } })
        } catch (error) {
          console.error("Error 'showFormInTool' : ", error);
          Store.snackBar = {
            message: "Une erreur est survenue lors de la désactivation du formulaire dans l'outil",
            error: true,
            open: true
          };
          this.setState({ loading: { ...this.state.loading, hideIntool: null } });
        }
      });
    }
  }

  public toolbox = (cell: string, row) => {
    // On prend les droits de l'utilisateur actuel pour les forms et on regarde si il est gestionnaire du form
    let userRightOnThisForm = Store.userConnected.formRights;
    if(Store.userConnected.id == row.gestionnaire) {
      userRightOnThisForm = "Admin";
    }
    let component = <React.Fragment></React.Fragment>
    if (row.status == "brouillon") {
      component = (
        <React.Fragment>
          {
            row.FicheDePoste != undefined && row.FicheDePoste != null ?
              <span title="Modifier la fiche de poste"><DescriptionIcon className={styles.toolboxIcon} onClick={() => this.props.history.push("/fichedeposte?idForm=" + row.id)} /></span>
              :
              row.formType == "recruit" && row.name != "Candidature spontanée" ?
                this.state.loading.addFDP == row.id ?
                  <span style={{ display: "flex", alignItems: "center" }} title="Ajout en cours..."><Spinner size={SpinnerSize.small} /></span>
                  :
                  <span title="Ajouter une fiche de poste"><NoteAdd className={styles.toolboxIcon} onClick={this.addFicheDePoste(row.id)} /></span>
                :
                <React.Fragment></React.Fragment>
          }
          {
            userRightOnThisForm != "Lecture" ?
              <span title="Publier le formulaire"><Publish className={styles.toolboxIcon} onClick={() => this.props.history.push("/createForm?id=" + row.id + "&publish=true")} /></span>
              :
              <React.Fragment />
          }
          <span title="Editer le formulaire"><Edit className={styles.toolboxIcon} onClick={() => this.props.history.push("/createForm?id=" + row.id)} /></span>
          {
            userRightOnThisForm == "Admin" ?
              <span title="Supprimer le formulaire"><Delete className={styles.toolboxIcon} onClick={this.openCloseDeleteModal(true, row.id)} /></span>
              :
              <React.Fragment />
          }
        </React.Fragment>
      )
    }
    else if (row.status == "publié") {
      component = row.displayed == false ?
        <React.Fragment>
          {
            row.FicheDePoste != undefined && row.FicheDePoste != null ?
              <span title={userRightOnThisForm != "Lecture" ? "Modifier la fiche de poste" : "Afficher la fiche de poste"}><DescriptionIcon className={styles.toolboxIcon} onClick={() => this.props.history.push("/fichedeposte?idForm=" + row.id)} /></span>
              :
              row.formType == "recruit" && row.name != "Candidature spontanée" ?
              userRightOnThisForm != "Lecture" ?
                  this.state.loading.addFDP == row.id ?
                    <span style={{ display: "flex", alignItems: "center" }} title="Ajout en cours..."><Spinner size={SpinnerSize.small} /></span>
                    :
                    <span title="Ajouter une fiche de poste"><NoteAdd className={styles.toolboxIcon} onClick={this.addFicheDePoste(row.id)} /></span>
                  :
                  <React.Fragment></React.Fragment>
                :
                <React.Fragment></React.Fragment>
          }
          {
            userRightOnThisForm != "Lecture" ?
              this.state.loading.showIntool == row.id ?
                <span style={{ display: "flex", alignItems: "center" }} title="Chargement en cours..."><Spinner size={SpinnerSize.small} /></span>
                :
                <span title="Rendre disponible dans l'outil"><Visibility className={styles.toolboxIcon} onClick={this.showHideFormInTool(row.id, true)} /></span>
              :
              <React.Fragment />
          }
          <span title={userRightOnThisForm != "Lecture" ? "Editer le formulaire" : "Afficher le formulaire"}><Edit className={styles.toolboxIcon} onClick={() => this.props.history.push("/createForm?id=" + row.id)} /></span>
          {
            userRightOnThisForm == "Admin" ?
              <span title="Supprimer le formulaire"><Delete className={styles.toolboxIcon} onClick={this.openCloseDeleteModal(true, row.id)} /></span>
              :
              <React.Fragment />
          }
        </React.Fragment>
        :
        <React.Fragment>
          {
            row.FicheDePoste != undefined && row.FicheDePoste != null ?
              <span title={userRightOnThisForm != "Lecture" ? "Modifier la fiche de poste" : "Afficher la fiche de poste"}><DescriptionIcon className={styles.toolboxIcon} onClick={() => this.props.history.push("/fichedeposte?idForm=" + row.id)} /></span>
              :
              row.formType == "recruit" && row.name != "Candidature spontanée" ?
                this.state.loading.addFDP == row.id ?
                  <span style={{ display: "flex", alignItems: "center" }} title="Ajout en cours..."><Spinner size={SpinnerSize.small} /></span>
                  :
                  <span title="Ajouter une fiche de poste"><NoteAdd className={styles.toolboxIcon} onClick={this.addFicheDePoste(row.id)} /></span>
                :
                <React.Fragment></React.Fragment>
          }
          {
            userRightOnThisForm != "Lecture" ?
              this.state.loading.hideIntool == row.id ?
                <span style={{ display: "flex", alignItems: "center" }} title="Chargement en cours..."><Spinner size={SpinnerSize.small} /></span>
                :
                <span title="Masquer le formulaire de l'outil"><VisibilityOff className={styles.toolboxIcon} onClick={this.showHideFormInTool(row.id, false)} /></span>
              :
              <React.Fragment />
          }
          <span title={userRightOnThisForm != "Lecture" ? "Editer le formulaire" : "Afficher le formulaire"}><Edit className={styles.toolboxIcon} onClick={() => this.props.history.push("/createForm?id=" + row.id)} /></span>
          {
            userRightOnThisForm == "Admin" ?
              <span title="Supprimer le formulaire"><Delete className={styles.toolboxIcon} onClick={this.openCloseDeleteModal(true, row.id)} /></span>
              :
              <React.Fragment />
          }
        </React.Fragment>
    }
    return (
      <div className={styles.toolboxContainer}>
        {component}
      </div>
    )
  }


  public toolboxConvoc = (cell: string, row) => {
    let component = <React.Fragment></React.Fragment>
    const urlSharepoint = Store.userConnected.siteUrl.endsWith("/") ? Store.userConnected.siteUrl.substring(0, Store.userConnected.siteUrl.length - 1) : Store.userConnected.siteUrl
    const linkToFile = urlSharepoint +row.lienSP +"?download=1"
    if (Store.userConnected.typeUserLydoc == "ged" || Store.userConnected.typeUserLydoc == "admin") {
      component = (
        <React.Fragment>
          <span title="Télécharger le document"><a href={linkToFile} className={styles.linkIcon}><GetApp className={styles.toolboxIcon}/></a></span>
          <span title="Supprimer le document"><Delete className={styles.toolboxIcon} onClick={this.openCloseDeleteConvocModal(true, row.id)} /></span>
        </React.Fragment>
      )
    } else {
      component = (
        <React.Fragment>
          <span title="Télécharger le document"><a href={linkToFile} className={styles.linkIcon}><GetApp className={styles.toolboxIcon}/></a></span>
        </React.Fragment>
      )
    }
    return (
      <div className={styles.toolboxContainer}>
        {component}
      </div>
    )
  }
  public toolboxDocument = (cell: string, row) => {
    let component = <React.Fragment></React.Fragment>
    const urlSharepoint = Store.userConnected.siteUrl.endsWith("/") ? Store.userConnected.siteUrl.substring(0, Store.userConnected.siteUrl.length - 1) : Store.userConnected.siteUrl
    const linkToFile = urlSharepoint +row.lienSP +"?download=1"
    if (Store.userConnected.typeUserLydoc == "ged" || Store.userConnected.typeUserLydoc == "admin") {
      component = (
        <React.Fragment>
          <span title="Télécharger le document"><a href={linkToFile} className={styles.linkIcon}><GetApp className={styles.toolboxIcon}/></a></span>
          <span title="Supprimer le document"><Delete className={styles.toolboxIcon} onClick={this.openCloseDeleteDocumentModal(true, row.id)} /></span>
        </React.Fragment>
      )
    } else {
      component = (
        <React.Fragment>
          <span title="Télécharger le document"><a href={linkToFile} className={styles.linkIcon}><GetApp className={styles.toolboxIcon}/></a></span>
        </React.Fragment>
      )
    }
    return (
      <div className={styles.toolboxContainer}>
        {component}
      </div>
    )
  }

  public toolboxLinked = (cell: string, row) => {
    let component = <React.Fragment></React.Fragment>
    component = (
      <React.Fragment>
        <span title="Supprimer la demande"><Delete className={styles.toolboxIcon} onClick={this.openCloseDeleteWFModal(true, row.id)} /></span>
      </React.Fragment>
    )
    return (
      <div className={styles.toolboxContainer}>
        {component}
      </div>
    )
  }
  // Fonction appelée lors de la suppression du formulaire
  public deleteForm = (form: IFormInfo) => (event: any) => {
    this.setState({ deleteDialogParams: { ...this.state.deleteDialogParams, loading: true } }, async () => {
      try {
        const resultDelete = await axios.post(Store.wsPath + "/1/formulaire/DeleteFormById", { idForm: form.id });
        if (resultDelete.data.cantDelete != undefined && resultDelete.data.cantDelete != null && resultDelete.data.cantDelete == true) {
          this.setState({ deleteDialogParams: { ...this.state.deleteDialogParams, cantDelete: true, wfLists: resultDelete.data.wf, loading: false } });
        }
        else {
          const allFormList: IFormInfo[] = [...this.state.allForms].filter(frm => frm.id != form.id);
          Store.snackBar = {
            message: "Formulaire supprimé avec succès",
            error: false,
            open: true
          };

          this.setState({ deleteDialogParams: { ...this.state.deleteDialogParams, hideDialog: true }, allForms: allFormList, selectedFormInfo: null });
        }
      } catch (error) {
        console.error("Error 'deleteForm' : ", error);
        this.setState({ deleteDialogParams: { ...this.state.deleteDialogParams, loading: false, error: true } });
        Store.snackBar = {
          message: "Une erreur est survenue lors de la suppression du formulaire",
          error: true,
          open: true
        };
      }
    });
  }
  public deleteWF = (idWF: number) => (event: any) => {
    this.setState({ deleteDialogWFParams: { ...this.state.deleteDialogWFParams, loading: true } }, async () => {
      try {
        const resultDelete = await axios.post(Store.wsPath + "/1/workflows/deleteWf", { idWF: idWF });
        // const allFormList: IFormInfo[] = [...this.state.allForms].filter(frm => frm.id != form.id);
        const allLinkedAnswer: any[] = [...this.state.selectedFormInfo.linkedAnswer.filter(la => la.id != idWF)]
        const tempState = this.state.selectedFormInfo;
        tempState.linkedAnswer = allLinkedAnswer;
        Store.snackBar = {
          message: "Demande supprimé avec succès",
          error: false,
          open: true
        };

        this.setState({ deleteDialogWFParams: { ...this.state.deleteDialogWFParams, hideDialog: true }, selectedWFToDelete: null, selectedFormInfo: tempState });

      } catch (error) {
        console.error("Error 'deleteWF' : ", error);
        this.setState({ deleteDialogWFParams: { ...this.state.deleteDialogWFParams, loading: false, error: true } });
        Store.snackBar = {
          message: "Une erreur est survenue lors de la suppression de la demande",
          error: true,
          open: true
        };
      }
    });
  }

  public openCloseDeleteModal = (open: boolean, idForm?: number) => event => {
    if (open == true) {
      this.setState({ deleteDialogParams: { hideDialog: false, type: "form", wfLists: [], cantDelete: false, loading: false, error: false }, selectedForm: this.state.allForms.find(form => form.id == idForm) });
    }
    else {
      this.setState({ deleteDialogParams: { hideDialog: true, type: "form", wfLists: [], cantDelete: false, loading: false, error: false }, selectedForm: null });
    }
  }
  public openCloseDeleteConvocModal = (open: boolean, idConvoc?: number) => event => {
    if (open == true) {
      this.setState({ deleteDialogConvocParams: { hideDialog: false, loading: false, error: false }, selectedConvoc: idConvoc });
    }
    else {
      this.setState({ deleteDialogConvocParams: { hideDialog: true, loading: false, error: false }, selectedConvoc: null });
    }
  }
  public openCloseDeleteDocumentModal = (open: boolean, idDocument?: number) => event => {
    if (open == true) {
      this.setState({ deleteDialogDocumentParams: { hideDialog: false, loading: false, error: false }, selectedDocument: idDocument });
    }
    else {
      this.setState({ deleteDialogDocumentParams: { hideDialog: true, loading: false, error: false }, selectedDocument: null });
    }
  }
  public openCloseDeleteWFModal = (open: boolean, idWf?: number) => event => {
    if (open == true) {
      this.setState({ deleteDialogWFParams: { hideDialog: false, loading: false, error: false }, selectedWFToDelete: idWf });
    }
    else {
      this.setState({ deleteDialogWFParams: { hideDialog: true, loading: false, error: false } });
    }
  }
  // Fonction appelée lors de la suppression du document
  public deleteConvoc = () => (event: any) => {
    this.setState({ deleteDialogConvocParams: { ...this.state.deleteDialogConvocParams, loading: true } }, async () => {
      try {
        await axios.post(Store.wsPath + "/1/formulaire/DeleteConvocationById", { idConvoc: this.state.selectedConvoc });
        const tempselected = this.state.selectedFormInfo;
        tempselected.convocations = tempselected.convocations.filter(c => c.id != this.state.selectedConvoc)
        Store.snackBar = {
          message: "Document supprimé avec succès",
          error: false,
          open: true
        };
        this.setState({ deleteDialogConvocParams: { ...this.state.deleteDialogConvocParams, hideDialog: true }, selectedFormInfo: tempselected, selectedConvoc: null });
      } catch (error) {
        console.error("Error 'deleteConvoc' : ", error);
        this.setState({ deleteDialogConvocParams: { ...this.state.deleteDialogConvocParams, loading: false, error: true }, selectedConvoc: null });
        Store.snackBar = {
          message: "Une erreur est survenue lors de la suppression du document",
          error: true,
          open: true
        };
      }
    });
  }
  public deleteDoc = () => (event: any) => {
    this.setState({ deleteDialogDocumentParams: { ...this.state.deleteDialogDocumentParams, loading: true } }, async () => {
      try {
        await axios.post(Store.wsPath + "/1/formulaire/DeleteDocumentById", { idConvoc: this.state.selectedDocument });
        const tempselected = this.state.selectedFormInfo;
        tempselected.documents = tempselected.documents.filter(c => c.id != this.state.selectedDocument)
        Store.snackBar = {
          message: "Document supprimé avec succès",
          error: false,
          open: true
        };
        this.setState({ deleteDialogDocumentParams: { ...this.state.deleteDialogDocumentParams, hideDialog: true }, selectedFormInfo: tempselected, selectedDocument: null });
      } catch (error) {
        console.error("Error 'deleteDocument' : ", error);
        this.setState({ deleteDialogDocumentParams: { ...this.state.deleteDialogDocumentParams, loading: false, error: true }, selectedDocument: null });
        Store.snackBar = {
          message: "Une erreur est survenue lors de la suppression du document",
          error: true,
          open: true
        };
      }
    });
  }
  public onRowSelect = (row, isSelected, e) => {
    this.setState({
      selectedFormInfo: row
    });
  }
  public nameFormatter = (cell, row) => {
    const stepEnCours = row.WfSteps.filter(ws => ws.state == "En cours")
    if (stepEnCours.length > 0) {
      const url = "/document?w=" + row.id + "&s=" + stepEnCours[0].id;
      return `<a href="${url}"><span title="${cell}"> ${cell}</span></a>`;
    } else {
      const url = "/document?w=" + row.id;
      return `<a href="${url}"><span title="${cell}"> ${cell}</span></a>`;
    }

  }
  public nameStepFormatter = (cell, row) => {
    const stepEnCours = row.WfSteps.filter(ws => ws.state == "En cours")
    if (stepEnCours.length > 0) {
      return stepEnCours[0].name;
    } else {
      return "Aucune en cours";
    }

  }
  public formAlreadyHaveConvocationFileWhithSameName = (name) => {
    let alreadyExist = false;
    if (this.state.selectedFormInfo.convocations != undefined && this.state.selectedFormInfo.convocations.length != 0) {
      this.state.selectedFormInfo.convocations.forEach(c => {
        if (c.name == name) {
          alreadyExist = true;
        }
      })
    }

    return alreadyExist
  }
  public formAlreadyHaveDocumentFileWhithSameName = (name) => {
    let alreadyExist = false;
    if (this.state.selectedFormInfo.documents != undefined && this.state.selectedFormInfo.documents.length != 0) {
      this.state.selectedFormInfo.documents.forEach(c => {
        if (c.name == name) {
          alreadyExist = true;
        }
      })
    }

    return alreadyExist
  }
  public selectFile = (event) => {
    let error = document.getElementById('error-file');
    error.setAttribute('style', 'display: none');
    if (this.formAlreadyHaveConvocationFileWhithSameName(event.target.files[0].name) == false) {
      this.setState({
        fileConvocation: event.target.files,
        fileConvocationName: event.target.files[0].name,
      });
    } else {
      error.innerHTML = "Un document de convocation porte déjà le même nom.";
      const input: any = document.getElementById("btn-upload");
      if (input != undefined && input != null) {
        input.value = "";
      }
      error.setAttribute('style', 'display: block');
    }

  };
  public selectFileDocument = (event) => {
    let error = document.getElementById('error-fileDocument');
    error.setAttribute('style', 'display: none');
    if (this.formAlreadyHaveDocumentFileWhithSameName(event.target.files[0].name) == false) {
      this.setState({
        fileDocument: event.target.files,
        fileDocumentName: event.target.files[0].name,
      });
    } else {
      error.innerHTML = "Un document porte déjà le même nom.";
      const input: any = document.getElementById("btn-uploadDocument");
      if (input != undefined && input != null) {
        input.value = "";
      }
      error.setAttribute('style', 'display: block');
    }

  };
  public validFormConvocation = () => {
    this.setState({ loadingButton: true }, () => {

      const formData = new FormData();
      formData.append("file", this.state.fileConvocation[0]);
      formData.append("idForm", this.state.selectedFormInfo.id.toString());

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      axios.post(
        Store.wsPath + "/1/formulaire/AddConvocationFile",
        formData,
        config
      ).then((response) => {
        if (response.status == 200) {
          Store.snackBar.open = true;
          Store.snackBar.error = false;
          Store.snackBar.message = "Le document a bien été ajouté";
          const tempselected = this.state.selectedFormInfo;
          tempselected.convocations.push(response.data);
          this.setState({
            fileConvocation: undefined,
            fileConvocationName: "",
            loadingButton: false,
            selectedFormInfo: tempselected
          })
          const input: any = document.getElementById("btn-upload");
          if (input != undefined && input != null) {
            input.value = "";
          }
          //this.getAllInfo();
        }
      }).catch(error => {
        Store.snackBar.open = true;
        Store.snackBar.error = true;
        Store.snackBar.message = "Erreur lors de l'ajout du document";
        this.setState({ loadingButton: false });
      })
    })
  }
  public validFormDocument= () => {
    this.setState({ loadingButtonDoc: true }, () => {

      const formData = new FormData();
      formData.append("file", this.state.fileDocument[0]);
      formData.append("idForm", this.state.selectedFormInfo.id.toString());

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      axios.post(
        Store.wsPath + "/1/formulaire/AddDocumentFile",
        formData,
        config
      ).then((response) => {
        if (response.status == 200) {
          Store.snackBar.open = true;
          Store.snackBar.error = false;
          Store.snackBar.message = "Le document a bien été ajouté";
          const tempselected = this.state.selectedFormInfo;
          tempselected.documents.push(response.data);
          this.setState({
            fileDocument: undefined,
            fileDocumentName: "",
            loadingButtonDoc: false,
            selectedFormInfo: tempselected
          })
          const input: any = document.getElementById("btn-uploadDocument");
          if (input != undefined && input != null) {
            input.value = "";
          }
          //this.getAllInfo();
        }
      }).catch(error => {
        Store.snackBar.open = true;
        Store.snackBar.error = true;
        Store.snackBar.message = "Erreur lors de l'ajout du document";
        this.setState({ loadingButton: false });
      })
    })
  }
  deleteSelectedFile = () => {
    this.setState({
      fileConvocation: undefined,
      fileConvocationName: ""
    });
    const input: any = document.getElementById("btn-upload");
    if (input != undefined && input != null) {
      input.value = "";
    }
  };
  deleteSelectedFileDocument = () => {
    this.setState({
      fileConvocation: undefined,
      fileConvocationName: ""
    });
    const input: any = document.getElementById("btn-uploadDocument");
    if (input != undefined && input != null) {
      input.value = "";
    }
  };
  public openConfirmDeleteDialog = () => {
    this.setState({ deleteDialogParams: { hideDialog: false, type: "confirmForm", wfLists: [], cantDelete: false, loading: false, error: false } });
  }

  public dateFermetureandOther = (cell: any, row: IFormInfo) => {
    if (cell == undefined || cell == null || cell == "") return null;
    let dateFormat = null;
    if (typeof cell == "string") {
      dateFormat = typeof cell == "string" ? new Date(cell) : cell;
    } else if (cell instanceof Date) {

    } else {
      dateFormat = new Date(cell.target.value);
    }
    const dates = dateFormat.getDate() < 10 ? "0" + dateFormat.getDate() : dateFormat.getDate();
    const month = dateFormat.getMonth() + 1 < 10 ? "0" + (dateFormat.getMonth() + 1) : dateFormat.getMonth() + 1;
    const year = dateFormat.getFullYear();
    return !dateFormat ? '' : dates + '/' + month + "/" + year;
  }

  // Fonction appelée lors du clic sur le bouton d'ajout d'une fiche de poste sur un formulaire
  public addFicheDePoste = (idForm: number) => (event) => {
    this.setState({ loading: { ...this.state.loading, addFDP: idForm } }, async () => {
      try {
        await createFicheDePoste(idForm);
        this.setState({ loading: { ...this.state.loading, addFDP: null } }, () => {
          this.props.history.push("/fichedeposte?idForm=" + idForm);
        })
      } catch (error) {
        console.error("Error 'addFicheDePoste' : ", error);
        Store.snackBar = {
          message: "Une erreur est survenue lors de la création de la fiche de poste",
          error: true,
          open: true
        };
        this.setState({ loading: { ...this.state.loading, addFDP: null } });
      }
    });
  }
  public beforeSaveEditCellValue = (row: IFormInfo, cellName, cellValue) => {
    if (cellName == "orderList" && row.status != "publié") {
      Store.snackBar = {
        error: true,
        message: "Vous ne pouvez pas modifier l'ordre d'un formulaire non publié.",
        open: true
      };
      return false;
    } else if (cellName == "date_fermeture") {

    }
  }
  public saveEditCellValue = (row: IFormInfo, cellName, cellValue) => {
    if (cellName == "orderList") {
      const editedForms = [...this.state.allForms].map(form => {
        if (form.id == row.id) {
          form.orderList = cellValue;
        }
        return form;
      })
      this.setState({
        allForms: editedForms,
        loading: { ...this.state.loading, editForm: true }
      }, () => {
        axios.post(Store.wsPath + "/1/formulaire/EditOrderList", {
          idForm: row.id,
          newOrder: cellValue,
          formType: row.formType
        })
          .then(response => {
            Store.snackBar = {
              error: false,
              message: "La modification du formulaire a bien été effectuée.",
              open: true
            };
            this.setState({ loading: { ...this.state.loading, editForm: false } });
          })
          .catch(error => {
            console.error("Error 'onOrderListChange' request : ", error)
            Store.snackBar = {
              error: true,
              message: "Une erreur est survenue lors de la mise à jour des données",
              open: true
            };
            this.setState({ loading: { ...this.state.loading, editForm: false } });
          })
      })
    } else if (cellName == "date_fermeture") {
      this.setState({ selectedDate: null })
    }
  }

  formatDate = (date: string | Date): string => {
    if (date == undefined || date == null || date == "") return null;
    let dateFormat = null;
    if (typeof date == "string") {
      dateFormat = typeof date == "string" ? new Date(date) : date;
    }
    const dates = dateFormat.getDate() < 10 ? "0" + dateFormat.getDate() : dateFormat.getDate();
    const month = (dateFormat.getMonth() + 1) < 10 ? "0" + (dateFormat.getMonth() + 1) : (dateFormat.getMonth() + 1);
    const year = dateFormat.getFullYear();

    return !dateFormat ? '' : dates + '/' + month + "/" + year;
  };


  onSelectDate = (onUpdate, props) => (event) => {
    const newDate = event.target.value;
    this.setState({
      selectedDate: newDate,
      loading: { ...this.state.loading, editForm: true }
    }, async () => {
      await axios.post(Store.wsPath + "/1/formulaire/EditDateList", {
        idForm: props.row.id,
        newOrder: newDate,
        formType: props.row.formType
      })
        .then(response => {
          Store.snackBar = {
            error: false,
            message: "La modification du formulaire a bien été effectuée.",
            open: true
          };
          this.setState({ loading: { ...this.state.loading, editForm: false } });
          onUpdate(newDate)
        })
        .catch(error => {
          console.error("Error 'EditDateList' request : ", error)
          Store.snackBar = {
            error: true,
            message: "Une erreur est survenue lors de la mise à jour des données",
            open: true
          };
          this.setState({ loading: { ...this.state.loading, editForm: false } });
          onUpdate(null)
        })
    });
  }
  render() {
    // Variable pour droit de l'utilisateur connecté
    let userRightFormSelected:"Lecture" | "Modification" | "Admin" = Store.userConnected.formRights;
    if(this.state.selectedFormInfo != undefined && this.state.selectedFormInfo != null && this.state.selectedFormInfo.gestionnaire != undefined && this.state.selectedFormInfo.gestionnaire == Store.userConnected.id) {
      userRightFormSelected = "Admin";
    }
    const selectRowProp = {
      mode: 'radio',
      onSelect: this.onRowSelect,
      clickToSelect: true,
      selected: this.state.selectedFormInfo != null ? [this.state.selectedFormInfo.id] : []
    };
    return (
      <div className={"mainContent"}>
        <div className={"titleComponent"} style={{ background: Store.themeParam.backgroundPrimary }}><h5>Formulaires</h5></div>
        <div className={"contentComponent " + styles.contentFormList}>
          {
            Store.userConnected.formRights == "Admin" ?
              <div>
                <Button onClick={this.newForm} variant="contained" color="primary"><Icon>add</Icon>Ajouter un formulaire</Button>
              </div>
              :
              <React.Fragment />
          }

          {
            this.state.loading.allForms == true ?
              <div className={styles.noForms}>
                <Spinner size={SpinnerSize.large} label="Chargement des formulaires en cours..." labelPosition={"bottom"} />
              </div>
              :
              this.state.allForms.length > 0 ?
                <Pivot className={styles.blockArray}>
                  <PivotItem key="allForms" headerText="Tous">
                    <BootstrapTable
                      multiColumnSearch
                      data={this.state.allForms}
                      searchPlaceholder='Rechercher dans la liste'
                      search={true}
                      options={{
                        sortIndicator: true,
                        defaultSortName: 'id',
                        noDataText: "Pas de formulaire pour le moment",
                        sizePerPage: 25,
                      }}
                      className={styles.formListArray}
                      pagination
                      version='4'
                      size="sm"
                      bordered={false}
                      striped
                      selectRow={selectRowProp}
                      cellEdit={{
                        mode: 'click',
                        blurToSave: true,
                        afterSaveCell: this.saveEditCellValue,
                        beforeSaveCell: this.beforeSaveEditCellValue
                      }}
                      style={{ "marginTop": "-15px" }}
                    >
                      <TableHeaderColumn isKey={true} dataField='id' hidden >id</TableHeaderColumn>
                      <TableHeaderColumn dataField='name' dataFormat={this.formatFormName} dataSort={true} caretRender={this.getCaret} editable={false}>Nom du formulaire</TableHeaderColumn>
                      <TableHeaderColumn dataField='status' dataSort caretRender={this.getCaret} editable={false}>Status</TableHeaderColumn>
                      <TableHeaderColumn dataField='formType' dataSort caretRender={this.getCaret} dataFormat={this.formatType} editable={false}>Type</TableHeaderColumn>
                      <TableHeaderColumn dataField='displayed' dataSort caretRender={this.getCaret} dataFormat={this.displayed} editable={false}>Disponible dans l'outil</TableHeaderColumn>
                        
                      {
                        Store.userConnected.formRights == "Admin" || Store.userConnected.formRights == "Modification" ?
                          <TableHeaderColumn dateField="pined" dataSort caretRender={this.getCaret} dataFormat={this.pined} editable={false} width="100px">Épinglé</TableHeaderColumn>
                          :
                          <TableHeaderColumn />
                      }
                      {
                        Store.userConnected.formRights == "Admin" || Store.userConnected.formRights == "Modification" ?
                          <TableHeaderColumn
                            dataField='orderList'
                            dataSort
                            editable={!this.state.loading.editForm}
                            customEditor={{
                              getElement: (onUpdate, props) => (<TextEditor className={styles.textEditor} onUpdate={onUpdate} {...props} />)
                            }}
                            width="100px"
                          >
                            Ordre
                      </TableHeaderColumn>
                          :
                          <TableHeaderColumn />
                      }
                      {
                        Store.userConnected.formRights == "Admin" || Store.userConnected.formRights == "Modification" ?
                          <TableHeaderColumn dataField='date_fermeture'
                            customEditor={{
                              getElement: (onUpdate, props) => (
                                (this.state.selectedDate == null) ?
                                  <input type="date" name="" id="" onChange={this.onSelectDate(onUpdate, props)} />
                                  :
                                  this.formatDate(this.state.selectedDate)
                              )
                            }}
                            dataFormat={this.dateFermetureandOther} dataSort caretRender={this.getCaret}
                            editable={!this.state.loading.editForm}
                          >
                            Date de retrait
                      </TableHeaderColumn>
                          :
                          <TableHeaderColumn />
                      }
                      <TableHeaderColumn dataField='premierePublication' dataSort caretRender={this.getCaret} dataFormat={this.dateFermetureandOther} editable={false}>Première publication</TableHeaderColumn>
                      <TableHeaderColumn dataField='dernierRetrait' dataSort caretRender={this.getCaret} dataFormat={this.dateFermetureandOther} editable={false}>Dernier retrait</TableHeaderColumn>
                      <TableHeaderColumn width='100px' tdStyle={{ padding: 0 }} dataField='name' dataFormat={this.toolbox} editable={false}>Actions</TableHeaderColumn>
                    </BootstrapTable>
                  </PivotItem>
                  {this.state.typeFormList.map(typeForm => {
                    return (
                      <PivotItem key={typeForm.label} headerText={typeForm.label}>
                        <BootstrapTable
                          multiColumnSearch
                          data={this.state.allForms.filter(form => form.formType == typeForm.name)}
                          searchPlaceholder='Rechercher dans la liste'
                          search={true}
                          options={{
                            sortIndicator: true,
                            defaultSortName: 'id',
                            noDataText: "Pas de formulaire pour le moment",
                            sizePerPage: 25,
                          }}
                          className={styles.formListArray}
                          pagination
                          version='4'
                          size="sm"
                          bordered={false}
                          striped
                          selectRow={selectRowProp}
                          cellEdit={{
                            mode: 'click',
                            blurToSave: true,
                            afterSaveCell: this.saveEditCellValue
                          }}
                        >
                          <TableHeaderColumn isKey={true} dataField='id' hidden >id</TableHeaderColumn>
                          <TableHeaderColumn dataField='name' dataFormat={this.formatFormName} dataSort={true} caretRender={this.getCaret} editable={false}>Nom du formulaire</TableHeaderColumn>
                          <TableHeaderColumn dataField='status' dataSort caretRender={this.getCaret} editable={false}>Status</TableHeaderColumn>
                          <TableHeaderColumn dataField='displayed' dataSort caretRender={this.getCaret} dataFormat={this.displayed} editable={false}>Disponible dans l'outil</TableHeaderColumn>
                             
                          {
                            Store.userConnected.formRights == "Admin" || Store.userConnected.formRights == "Modification" ?
                              <TableHeaderColumn dateField="pined" dataSort caretRender={this.getCaret} dataFormat={this.pined} editable={false} width="100px">Épinglé</TableHeaderColumn>
                              :
                              <TableHeaderColumn />
                          }
                          {
                            Store.userConnected.formRights == "Admin" || Store.userConnected.formRights == "Modification" ?
                              <TableHeaderColumn
                                dataField='orderList'
                                dataSort
                                editable={!this.state.loading.editForm}
                                customEditor={{
                                  getElement: (onUpdate, props) => (<TextEditor className={styles.textEditor} {...props} />)
                                }}
                                width="100px"
                              >
                                Ordre
                              </TableHeaderColumn>
                              :
                              <TableHeaderColumn />
                          }
                          {
                            Store.userConnected.formRights == "Admin" || Store.userConnected.formRights == "Modification" ?
                              <TableHeaderColumn dataField='date_fermeture'
                                editable={!this.state.loading.editForm}
                                customEditor={{
                                  getElement: (onUpdate, props) => (
                                    (this.state.selectedDate == null) ?
                                      <input type="date" name="" id="" onChange={this.onSelectDate(onUpdate, props)} />
                                      :
                                      this.formatDate(this.state.selectedDate)
                                  )
                                }}
                                dataFormat={this.dateFermetureandOther} dataSort caretRender={this.getCaret}
                              >
                                Date de retrait
                              </TableHeaderColumn>
                              :
                              <TableHeaderColumn />
                          }
                          <TableHeaderColumn dataField='premierePublication' dataSort caretRender={this.getCaret} dataFormat={this.dateFermetureandOther} editable={false}>Première publication</TableHeaderColumn>
                          <TableHeaderColumn dataField='dernierRetrait' dataSort caretRender={this.getCaret} dataFormat={this.dateFermetureandOther} editable={false}>Dernier retrait</TableHeaderColumn>
                          <TableHeaderColumn width='100px' tdStyle={{ padding: 0 }} dataField='name' dataFormat={this.toolbox} editable={false}>Actions</TableHeaderColumn>
                        </BootstrapTable>
                      </PivotItem>
                    )
                  })}
                </Pivot>
                :
                <div className={styles.noForms}>Aucun formulaire de disponible</div>
          }
          {
            this.state.loading.editForm ?
              <Spinner className={styles.loadingEditForm} labelPosition="right" label="Enregistrement des changements..." />
              : <></>
          }
          {this.state.selectedFormInfo != null ?
            <div>
              <Pivot>
                <PivotItem headerText="Demandes liées" headerButtonProps={{ 'data-order': 1, 'data-title': 'Chat', }}>
                  <div>
                    <BootstrapTable data={this.state.selectedFormInfo.linkedAnswer} keyField='uniqueIdString' searchPlaceholder='Rechercher dans la liste' search={true}
                      pagination
                      options={{
                        noDataText: "Pas de demande pour ce formulaire",
                      }}
                      trClassName={styles.trBootstrapTable}
                      bordered={false}
                      striped
                    >
                      <TableHeaderColumn dataField='id' hidden >id</TableHeaderColumn>
                      <TableHeaderColumn dataField='name' dataSort={true} dataFormat={this.nameFormatter}>Nom de la demande</TableHeaderColumn>
                      <TableHeaderColumn dataField='state'>Etat du circuit</TableHeaderColumn>
                      <TableHeaderColumn dataField='id' dataFormat={this.nameStepFormatter}>Etape</TableHeaderColumn>
                      {
                        Store.userConnected.typeUserLydoc == "admin" || Store.userConnected.typeUserLydoc == "ged" || this.state.selectedFormInfo.gestionnaire == Store.userConnected.id ?
                          <TableHeaderColumn width='100px' tdStyle={{ padding: 0 }} dataField='name' dataFormat={this.toolboxLinked} editable={false}>Actions</TableHeaderColumn>
                          :
                          <TableHeaderColumn />
                      }
                    </BootstrapTable>
                  </div>
                </PivotItem>
                {this.state.selectedFormInfo.formType == "recruit" && this.state.selectedFormInfo.name != "Candidature spontanée" && (userRightFormSelected == "Admin" || userRightFormSelected == "Modification") &&
                    <PivotItem headerText="Convocation">
                      <div className={styles.convocationContainer} style={{ marginTop: "10px" }}>
                        <Accordion className={styles.accordionContainer}>
                          <AccordionSummary expandIcon={<Icon className={styles.accordionIcon}>expand_more</Icon>} className={styles.accordionHeader}>
                            <span className={styles.accordionTitle}><Icon className={styles.iconTitle}>attach_file</Icon>Gestion des pièces jointes</span>
                          </AccordionSummary>
                          <div className={styles.accordionContent}>
                            <p className={styles.errorFile} style={{ display: "none" }} id="error-file"></p>
                            <div>
                              <label style={{ marginBottom: "0px !important" }} htmlFor="btn-upload">
                                <input
                                  id="btn-upload"
                                  name="btn-upload"
                                  type="file"
                                  style={{ display: 'none' }}
                                  onChange={this.selectFile}
                                />
                                {this.state.fileConvocationName != undefined && this.state.fileConvocationName != "" ?
                                  <React.Fragment></React.Fragment>
                                  : <Button component="span" variant="contained" color="primary"><Icon>add</Icon>Ajouter un document</Button>
                                }
                              </label>
                              {this.state.fileConvocationName != undefined && this.state.fileConvocationName != "" ?
                                <div style={{ display: "flex" }}>
                                  <p style={{ marginTop: "2px" }}>{this.state.fileConvocationName}</p>
                                  <Button
                                    disabled={this.state.loadingButton}
                                    className={styles.btnConvoc}
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    onClick={this.validFormConvocation}
                                    style={{ marginLeft: "5px", marginRight: "5px" }}
                                  >

                                    {
                                      this.state.loadingButton === true ?
                                        <Spinner size={20} />
                                        : 'Ajouter'
                                    }
                                  </Button>

                                  <Button
                                    color="secondary"
                                    variant="contained"
                                    className={styles.btnConvoc}
                                    size="small"
                                    style={this.state.loadingButton === true ? { display: 'none' } : {}}
                                    onClick={this.deleteSelectedFile}
                                  >
                                    Annuler
                              </Button>
                                </div>
                                : <React.Fragment></React.Fragment>
                              }
                            </div>
                            <div>
                              <BootstrapTable data={this.state.selectedFormInfo.convocations} keyField='uniqueIdString' searchPlaceholder='Rechercher dans la liste' search={true}
                                pagination
                                options={{
                                  noDataText: "Pas de document de convocation pour ce formulaire",
                                }}
                                trClassName={styles.convocationArray}
                                bordered={false}
                                striped
                              >
                                <TableHeaderColumn dataField='id' hidden >id</TableHeaderColumn>
                                <TableHeaderColumn dataField='name' dataSort={true} >Nom du document</TableHeaderColumn>
                                <TableHeaderColumn width='50px' tdStyle={{ padding: 0, textAlign: "center" }} dataField='id' dataFormat={this.toolboxConvoc}></TableHeaderColumn>
                              </BootstrapTable>
                            </div>
                          </div>
                        </Accordion>

                        {/* Composant pour la convocation de masse */}
                        <Accordion className={styles.accordionContainer}>
                          <AccordionSummary className={styles.accordionHeader} expandIcon={<Icon className={styles.accordionIcon}>expand_more</Icon>}>
                            <span className={styles.accordionTitle}><Icon className={styles.iconTitle}>email</Icon>Envoi des convocations</span>
                          </AccordionSummary>
                          <div className={styles.accordionContent}>
                            <ConvocationMasse form={this.state.selectedFormInfo} />
                          </div>
                        </Accordion>
                      </div>
                    </PivotItem>
                }
                <PivotItem headerText="Échanges" headerButtonProps={{ 'data-order': 1, 'data-title': 'Chat', }}>
                  {
                    (this.state.selectedFormInfo != null && this.state.selectedFormInfo != undefined) ?
                      <Messages idForm={this.state.selectedFormInfo.id} />
                      :
                      <span>Aucun formulaire sélectionné</span>
                  }
                </PivotItem>
                {userRightFormSelected == "Modification" || userRightFormSelected == "Admin" &&
                  <PivotItem headerText="Échanges Admins" headerButtonProps={{ 'data-order': 1, 'data-title': 'Chat_Admin', }}>
                    {
                      (this.state.selectedFormInfo != null && this.state.selectedFormInfo != undefined) ?
                        <Messages idForm={this.state.selectedFormInfo.id} prefix="admin_" />
                        :
                        <span>Aucun formulaire sélectionné</span>
                    }
                  </PivotItem>
                }
                {userRightFormSelected == "Modification" || userRightFormSelected == "Admin" &&
                  <PivotItem headerText="Documents" headerButtonProps={{ 'data-order': 1, 'data-title': 'Documents', }}>
                    <p className={styles.errorFile} style={{ display: "none" }} id="error-fileDocument"></p>
                    <div>
                      <label style={{ marginBottom: "0px !important" }} htmlFor="btn-uploadDocument">
                        <input
                          id="btn-uploadDocument"
                          name="btn-uploadDocument"
                          type="file"
                          style={{ display: 'none' }}
                          onChange={this.selectFileDocument}
                        />
                        {this.state.fileDocumentName != undefined && this.state.fileDocumentName != "" ?
                          <React.Fragment></React.Fragment>
                          : <Button component="span" variant="contained" color="primary"><Icon>add</Icon>Ajouter un document</Button>
                        }
                      </label>
                      {this.state.fileDocumentName != undefined && this.state.fileDocumentName != "" ?
                        <div style={{ display: "flex" }}>
                          <p style={{ marginTop: "2px" }}>{this.state.fileDocumentName}</p>
                          <Button
                            disabled={this.state.loadingButtonDoc}
                            className={styles.btnConvoc}
                            size="small"
                            color="primary"
                            variant="contained"
                            onClick={this.validFormDocument}
                            style={{ marginLeft: "5px", marginRight: "5px" }}
                          >
                            {
                              this.state.loadingButtonDoc === true ?
                                <Spinner size={20} />
                                : 'Ajouter'
                            }
                          </Button>
                          <Button
                            color="secondary"
                            variant="contained"
                            className={styles.btnConvoc}
                            size="small"
                            style={this.state.loadingButtonDoc === true ? { display: 'none' } : {}}
                            onClick={this.deleteSelectedFileDocument}
                          >
                            Annuler
                      </Button>
                        </div>
                        : <React.Fragment></React.Fragment>
                      }
                    </div>
                    <div>
                      <BootstrapTable data={this.state.selectedFormInfo.documents} keyField='uniqueIdString' searchPlaceholder='Rechercher dans la liste' search={true}
                        pagination
                        options={{
                          noDataText: "Pas de document pour ce formulaire",
                        }}
                        trClassName={styles.convocationArray}
                        bordered={false}
                        striped
                      >
                        <TableHeaderColumn dataField='id' hidden >id</TableHeaderColumn>
                        <TableHeaderColumn dataField='name' dataSort={true} >Nom du document</TableHeaderColumn>
                        <TableHeaderColumn width='50px' tdStyle={{ padding: 0, textAlign: "center" }} dataField='id' dataFormat={this.toolboxDocument}></TableHeaderColumn>
                      </BootstrapTable>
                    </div>
                  </PivotItem>
                }
              </Pivot>
            </div>
            : <React.Fragment></React.Fragment>

          }
          <Dialog
            hidden={this.state.deleteDialogWFParams.hideDialog}
            onDismiss={this.openCloseDeleteWFModal(false)}
            dialogContentProps={{
              type: DialogType.normal,
              title: "Supprimer la demande",
              closeButtonAriaLabel: 'Fermer',
            }}
            modalProps={{
              isBlocking: true
            }}
          >
            {
              this.state.deleteDialogWFParams.error == true ?
                <p>Une erreur est survenue lors de la suppression de la demande</p>
                :
                this.state.deleteDialogWFParams.loading == true ?
                  <Spinner size={SpinnerSize.large} label="Suppression de la demande en cours" labelPosition="bottom" />
                  :

                  <React.Fragment>
                    <p>Vous allez supprimer une demande, toutes les données citées ci dessous seront supprimées :</p>
                    <ul>
                      <li>Demande</li>
                      <li>Tâches</li>
                      <li>Documents joints</li>
                      <li>Post-it</li>
                    </ul>
                    <DialogFooter>
                      <PrimaryButton onClick={this.deleteWF(this.state.selectedWFToDelete)} text="Confirmer" />
                      <DefaultButton onClick={this.openCloseDeleteWFModal(false)} text="Annuler" />
                    </DialogFooter>
                  </React.Fragment>
            }
          </Dialog>
          <Dialog
            hidden={this.state.deleteDialogParams.hideDialog}
            onDismiss={this.openCloseDeleteModal(false)}
            dialogContentProps={{
              type: DialogType.normal,
              title: "Supprimer le formulaire",
              closeButtonAriaLabel: 'Fermer',
            }}
            modalProps={{
              isBlocking: true
            }}
          >
            {
              this.state.deleteDialogParams.type == "confirmForm" ?
                this.state.deleteDialogParams.error == true ?
                  <p>Une erreur est survenue lors de la suppression du formulaire</p>
                  :
                  this.state.deleteDialogParams.loading == true ?
                    <Spinner size={SpinnerSize.large} label="Suppression du formulaire en cours" labelPosition="bottom" />
                    :
                    this.state.deleteDialogParams.cantDelete == true ?
                      <div>
                        <p>Impossible de supprimer le formulaire car des workflows rattachés à ce formulaire ne sont pas terminés : </p>
                        <DetailsList
                          items={this.state.deleteDialogParams.wfLists}
                          compact={true}
                          columns={[{
                            key: 'Workflow',
                            name: 'Workflow',
                            fieldName: 'name',
                            minWidth: 210,
                            isRowHeader: false,
                            isResizable: false,
                            isSorted: false,
                            isSortedDescending: false,
                            sortAscendingAriaLabel: 'Trier de A à Z',
                            sortDescendingAriaLabel: 'Trier de Z à A',
                            data: 'string',
                            isPadded: false,
                            onRender: (doc: IDBDocument) => (
                              <a href={"/document?w=" + doc.WfProject.id} style={{ cursor: "pointer" }}>{doc.WfProject.name}</a>
                            )
                          }]}
                          selectionMode={SelectionMode.none}
                          getKey={(item: any, index?: number) => {
                            return item.key;
                          }}
                          setKey="none"
                          layoutMode={DetailsListLayoutMode.justified}
                          isHeaderVisible={false}
                        />
                        <DialogFooter>
                          <PrimaryButton onClick={this.openCloseDeleteModal(false)} text="Fermer" />
                        </DialogFooter>
                      </div>
                      :
                      <React.Fragment>
                        <p>Vous allez supprimer un formulaire, toutes les données citées ci dessous seront supprimées :</p>
                        <ul>
                          <li>Circuits</li>
                          <li>Tâches</li>
                          <li>Documents</li>
                          <li>Post-it</li>
                          <li>Réponses au formulaire</li>
                        </ul>
                        <DialogFooter>
                          <PrimaryButton onClick={this.deleteForm(this.state.selectedForm)} text="Confirmer" />
                          <DefaultButton onClick={this.openCloseDeleteModal(false)} text="Annuler" />
                        </DialogFooter>
                      </React.Fragment>
                :
                <React.Fragment>
                  <div>Êtes vous sur de vouloir supprimer le formulaire ?</div>
                  <DialogFooter>
                    <PrimaryButton onClick={this.openConfirmDeleteDialog} text="Supprimer" />
                    <DefaultButton onClick={this.openCloseDeleteModal(false)} text="Annuler" />
                  </DialogFooter>
                </React.Fragment>
            }
          </Dialog>
          <Dialog
            hidden={this.state.deleteDialogConvocParams.hideDialog}
            onDismiss={this.openCloseDeleteConvocModal(false)}
            dialogContentProps={{
              type: DialogType.normal,
              title: "Supprimer le document",
              closeButtonAriaLabel: 'Fermer',
            }}
            modalProps={{
              isBlocking: true
            }}
          >
            {this.state.deleteDialogConvocParams.error == true ?
              <p>Une erreur est survenue lors de la suppression du document</p>
              :
              this.state.deleteDialogConvocParams.loading == true ?
                <Spinner size={SpinnerSize.large} label="Suppression du document en cours" labelPosition="bottom" />
                :
                <React.Fragment>
                  <div>Êtes vous sur de vouloir supprimer le document ?</div>
                  <DialogFooter>
                    <PrimaryButton onClick={this.deleteConvoc()} text="Supprimer" />
                    <DefaultButton onClick={this.openCloseDeleteConvocModal(false)} text="Annuler" />
                  </DialogFooter>
                </React.Fragment>
            }
          </Dialog>
          <Dialog
            hidden={this.state.deleteDialogDocumentParams.hideDialog}
            onDismiss={this.openCloseDeleteDocumentModal(false)}
            dialogContentProps={{
              type: DialogType.normal,
              title: "Supprimer le document",
              closeButtonAriaLabel: 'Fermer',
            }}
            modalProps={{
              isBlocking: true
            }}
          >
            {this.state.deleteDialogDocumentParams.error == true ?
              <p>Une erreur est survenue lors de la suppression du document</p>
              :
              this.state.deleteDialogDocumentParams.loading == true ?
                <Spinner size={SpinnerSize.large} label="Suppression du document en cours" labelPosition="bottom" />
                :
                <React.Fragment>
                  <div>Êtes vous sur de vouloir supprimer le document ?</div>
                  <DialogFooter>
                    <PrimaryButton onClick={this.deleteDoc()} text="Supprimer" />
                    <DefaultButton onClick={this.openCloseDeleteDocumentModal(false)} text="Annuler" />
                  </DialogFooter>
                </React.Fragment>
            }
          </Dialog>
        </div>
      </div >
    );
  }
}

export default withRouter(FormsList);