import * as React from "react";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import TextField from "@material-ui/core/TextField";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {
  Document,
  StateVersionYes
} from "../types";

import axios from "axios";
import { observer } from "mobx-react";
import PostIt from "../PostIt/PostIt";
import Store from "../store/store";
import { ValidatorForm } from "react-material-ui-form-validator";
import UploadZone from "../Dropzone/Dropzone"
import { withRouter, RouteComponentProps } from "react-router";
import PageVisibility from 'react-page-visibility';
import TaskSlider from "../TaskSlider/TaskSlider";
import DocAssocie from "../DocumentAssocié/docassocie"
const styles = require("./styles/styles.scss");
import Grid from '@material-ui/core/Grid';
import FormulaireSharepoint from "./FormulaireSharepoint";
import Alert from "@material-ui/lab/Alert";
import { IDBWorkflow, IModelWf } from "../Workflow/interfaces/IWorkflow";
import WorkflowNew from "../Workflow/WorkflowNew";
import Spinner from "../Spinner/Spinner";
import { useQuery } from "./../helper/helpers";
import RenderForm from "./../forms/renderForm/RenderForm";
import { IFormInfo, IColumnInfo } from "../forms/renderForm/interfaces/IRenderForm";
interface IFormState {
  numPages: number;
  pageNumber: number;
  file: any;
  containerSize: number;
  matriculeDocSharepoint?: string;
  ongletSelect?: number;
  currentDoc?: number;
  colorImportance?: string;
  colorUrgence?: string;
  dataVisionneuse?: any;
  idModel?: number
  allBannetteAndContent: any;
  idSelectedStep?: number;
  idSelectedTask?: number;
  formInfo?: IFormInfo;
  formFields?: IColumnInfo[];
}

export function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div style={value !== index ? { display: "none" } : {}} id={`tabpanel-${index}`}>{children}</div>
  );
}

// let modeleWfSharePoint = null;
let count = 0
@observer
class Form extends React.Component<RouteComponentProps<{}> & any, IFormState>{
  private container = React.createRef<HTMLDivElement>();

  public constructor(props: any) {
    super(props);
    this.state = {
      numPages: null,
      pageNumber: 1,
      file: undefined,
      containerSize: null,
      matriculeDocSharepoint: undefined,
      ongletSelect: 0,
      colorImportance: "green",
      colorUrgence: "green",
      dataVisionneuse: {
        masquerModifDocButton: false,
        link: null,
        format: "autre",
        idDocLyflow: 0,
        idProject: 0
      },
      idModel: Store.workflow.idModel,
      allBannetteAndContent: [],
      idSelectedStep: undefined,
      idSelectedTask: undefined
    };
    Store.isList = false;
    Store.modeSharepoint ? Store.defaultValueExpansionWf = true : false
    Store.modeSharepoint ? Store.defaultValueExpansionDocAsso = true : false

    // if (Store.selectedDoc && Store.selectedDoc.id) {
    //   this.getProjectById(Store.selectedDoc.id)
    // }

    // if (Store.modeSharepoint) {
    //   Store.loadingSpinner = true
    // }

    // Récupération de l'id du doc dans l'url, si il ni en a pas on check les taches de l'utilisateur et on récupére le docment de sa premiére taches
    const query = useQuery()
    const urlIdDoc: number = parseInt(query.get("idDoc"));
    const idWorkflow: number = parseInt(query.get("idWorkflow"));
    const idW: number = parseInt(query.get('w'));
    const idSelectedStep: number = parseInt(query.get("s"));
    const idSelectedTask: number = parseInt(query.get("t"));
    // Store les id de la bannette et de l'étape dans l'url
    // if(idSelectedBannette != undefined && isNaN(idSelectedBannette) == false){
    //   Store.idSelectedBannette = idSelectedBannette;
    // } else {
    //   Store.idSelectedBannette = undefined
    // }
    let stepId = undefined;
    if (idSelectedStep != undefined && isNaN(idSelectedStep) == false) {
      stepId = idSelectedStep
    }
    let taskId = undefined;
    if (idSelectedTask != undefined && isNaN(idSelectedTask) == false) {
      taskId = idSelectedTask
    }
    // Si il y a un id on récupére le projet avec cet id
    if (!isNaN(idW)) {
      this.getWorkflowByIdW(idW, idSelectedStep, idSelectedTask);
    }
    else if (!isNaN(idWorkflow)) {
      this.getWorkflowByIdW(idWorkflow, idSelectedStep, idSelectedTask);
    }
    else if (!isNaN(urlIdDoc)) {
      this.getProjetByIdDoc(urlIdDoc, idSelectedStep, idSelectedTask);
    }
    else {
      this.getTaskEnCoursUser();
    }
    this.getAllBanette();
    this.getAllModelsWf();
  }

  componentDidUpdate(prevProps, prevState) {
    // if (Store.reload.form == true) {
    //   this.reloadDataDoc()
    //   const storeUpdate = Store.reload;
    //   storeUpdate.form = false;
    //   Store.reload = storeUpdate;
    //   if (Store.workflow.dateFinSouhaitee != undefined) {
    //     Store.workflow.dateFinSouhaitee = Store.dateProjectEndEstimated
    //   }
    // }

    // Récupération de l'id du doc dans l'url, si il ni en a pas on check les taches de l'utilisateur et on récupére le docment de sa premiére taches
    if (this.props.location.search != prevProps.location.search || Store.reload.form) {
      const query = useQuery();
      // Rajout jj pour l'onglet
      const ongletSelected: number = parseInt(query.get("o"));
      if (ongletSelected != undefined && !isNaN(ongletSelected)) {
        this.setState({ ongletSelect: ongletSelected });
      }
      const urlIdDoc: number = parseInt(query.get("idDoc"));
      const idWorkflow: number = parseInt(query.get("idWorkflow"));
      const idW: number = parseInt(query.get('w'));

      const idSelectedStep: number = parseInt(query.get("s"));
      const idSelectedTask: number = parseInt(query.get("t"));
      // Si il y a un id on récupére le projet avec cet id
      if (isNaN(idW) == false) {
        this.getWorkflowByIdW(idW, idSelectedStep, idSelectedTask);
      }
      else if (isNaN(idWorkflow) == false) {
        this.getWorkflowByIdW(idWorkflow, idSelectedStep, idSelectedTask);
      }
      else if (isNaN(urlIdDoc) == false) {
        this.getProjetByIdDoc(urlIdDoc, idSelectedStep, idSelectedTask);
      }
      else {
        this.getTaskEnCoursUser();
      }
      Store.reload.form = false;
    }

    // Fonction qui récupère les bannettes et leur contenu
    if (Store.reload.banette == true) {
      this.getAllBanette();
      Store.reload.banette = false;
    }

    // Test jj visionneuse
    if (Store.workflow.id != undefined && Store.workflow.Document.id != this.state.dataVisionneuse.idDocLyflow && Store.modeSharepoint == true && Store.workflow.id != this.state.dataVisionneuse.idProject) {
      const projectEnd: boolean = Store.workflow.state == "Terminé" || Store.workflow.state == "Refusé" ? true : false;
      // if(Store.workflow.Document.responseFileLink != null ){
      //   if (Store.workflow.isGRC == true || Store.workflow.isForm == true) {
      //     this.formatUrlV2(true, projectEnd, Store.workflow.Document.dataSharepoint, false);
      //   }
      //   else if (projectEnd == true && Store.workflow.Document.dataSharepoint != undefined && Store.workflow.Document.dataSharepoint.finishDoc != undefined) {
      //     this.formatUrlV2(false, projectEnd, Store.workflow.Document.dataSharepoint.finishDoc, false);
      //   }
      //   else if (Store.workflow.Document.dataSharepoint != undefined && Store.workflow.Document.dataSharepoint.copyDocEdit != undefined) {
      //     this.formatUrlV2(false, projectEnd, Store.workflow.Document.dataSharepoint.copyDocEdit, false);
      //   }
      // }
      // else 
      if (Store.workflow.isGRC == true || Store.workflow.isForm == true) {
        this.formatUrlV2(true, projectEnd, Store.workflow.Document.dataSharepoint, false);
      }
      else if (projectEnd == true && Store.workflow.Document.dataSharepoint != undefined && Store.workflow.Document.dataSharepoint.finishDoc != undefined) {
        this.formatUrlV2(false, projectEnd, Store.workflow.Document.dataSharepoint.finishDoc, false);
      }
      else if (Store.workflow.Document.dataSharepoint != undefined && Store.workflow.Document.dataSharepoint.copyDocEdit != undefined) {
        this.formatUrlV2(false, projectEnd, Store.workflow.Document.dataSharepoint.copyDocEdit, false);
      }
    }
  }
  public getAllBanette = () => {
    axios.post(Store.wsPath + '/1/workflows/getBannettesAndContentForm', {
      id: Store.userConnected.id
    }).then((response) => {
      this.setState({
        allBannetteAndContent: response.data
      })
    })
  }

  private userIsIn = (workflow: IDBWorkflow): boolean => {
    let isIn = false;
    if (workflow != undefined && workflow != null && workflow != "") {
      let allSteps = workflow.WfSteps;
      let idCurrentUser = Store.userConnected.id;
      allSteps.map(step => {
        step.WfTasks.map(task => {
          if (task.User != undefined && task.User != null && task.User.id == idCurrentUser) {
            isIn = true;
          } else if (task.User != undefined && task.User != null && task.User.isGroup) {
            if (task.editedGroup != undefined && task.editedGroup != null) {
              task.editedGroup.forEach(userId => {
                if (userId === idCurrentUser) {
                  isIn = true;
                }
              })
            } else if (task.User.usersOfGroup != undefined && task.User.usersOfGroup != null) {
              task.User.usersOfGroup.forEach(userId => {
                if (userId === idCurrentUser) {
                  isIn = true;
                }
              })
            }
          }
        });
      });
    }
    return isIn;
  }
  public getTaskEnCoursUser = () => {
    axios.post(Store.wsPath + '/1/workflows/getBannettesAndContentForm', {
      id: Store.userConnected.id
    }).then((response) => {
      const result: any[] = response.data;
      const query = useQuery();
      let b = 1;
      const idBanetteUrl = query.get("b")
      let resultFiltered = [];
      if (idBanetteUrl != undefined && idBanetteUrl != null && idBanetteUrl.length > 0) {
        b = parseInt(idBanetteUrl);
        resultFiltered = result.filter(sea => (sea.id == b && sea.projects.length > 0));
      }
      if (resultFiltered.length == 0) {
        resultFiltered = result.filter(sea => (sea.projects.length > 0));
        if (resultFiltered.length != 0) {
          b = resultFiltered[0].id;
        }
      }
      if (resultFiltered.length == 0) {
        this.props.history.push('/mydashboard');
      }
      else {
        // Si il y a une tache on récupère l'id du doc dans la tache et on redirige sur la route en lui passaant l'id du doc
        this.props.history.push("/document?w=" + resultFiltered[0].projects[0].id + "&b=" + b);
      }
    })
  }


  public reloadDataDoc = () => {
    axios.get(Store.wsPath + '/1/documents/getDocumentSharepointById', { params: { id: Store.workflow.Document.matriculeDoc } })
      .then(response => {
        Store.selectedDoc = response.data;
        Store.workflow.Document = response.data;
      }).then(() => {
        // this.setState({
        //    colorImportance :
        // })
      })
  }
  public getAllModelsWf = () => {
    axios.get(Store.wsPath + '/1/workflows/getAllModelWf').then((response => {
      if (response.status == 200) {
        Store.allModelWf = response.data;
      }
    }))
  }
  componentDidMount() {
    this.onResizeWindow();
    window.addEventListener("resize", this.onResizeWindow.bind(this));
    // if (Store.userConnected.typeUserLydoc != "ged" && Store.userConnected.typeUserLydoc != "admin" && Store.userConnected.typeUserLydoc != "contributeur") {
    //   Store.consulting = true
    // }

    Store.contextualmenu.content = "ActionsWorkflow";
    Store.contextualmenu.open = "open";

    // Pour selectionner l'onglet à ouvrir si présent dans l'url
    const query = useQuery();
    const ongletSelected: number = parseInt(query.get("o"));
    if (ongletSelected != undefined && !isNaN(ongletSelected)) {
      this.setState({ ongletSelect: ongletSelected });
    }
  }

  componentWillUnmount() {
    // Remove event listener
    window.removeEventListener("resize", this.onResizeWindow.bind(this));
    Store.selectedDoc = Store.selectedDocDefaultValue;
  }

  public formatDate = (date) => {
    if ((date.getMonth() + 1) < 10) {
      return date.getFullYear() + "-0" + (date.getMonth() + 1) + "-" + date.getDate();
    } else {
      return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    }
  }

  public onChangeValue = name => event => {
    if (Store.modeSharepoint == false) {
      if (name == "natureselect") {
        Store.idNature = event.target.value;
      } else if (name == "title") {
        Store.selectedDoc.title = event.target.value;
        Store.workflow.Document.title = event.target.value;
      } else if (name == "reference") {
        Store.selectedDoc.reference = event.target.value;
        Store.workflow.Document.reference = event.target.value;
      } else if (name == "object") {
        Store.selectedDoc.object = event.target.value;
        Store.workflow.Document.object = event.target.value;
      } else if (name == "version") {
        Store.selectedDoc.numVersion = event.target.value;
        Store.workflow.Document.numVersion = event.target.value;
      } else if (name == "object") {
        Store.selectedDoc.object = event.target.value;
        Store.workflow.Document.object = event.target.value;
      } else if (name == "priorité") {
        Store.selectedDoc.priorite = event.target.value;
        Store.workflow.Document.priorite = event.target.value;
      }
    } else {
      if (name == "title") {
        Store.selectedDoc.dataSharepoint.Title = event.target.value;
        Store.workflow.Document.dataSharepoint.Title = event.target.value;
      } else if (name == "object") {
        Store.selectedDoc.dataSharepoint.Texte = event.target.value;
        Store.workflow.Document.dataSharepoint.Texte = event.target.value;
      }
    }
  };

  updateDocument = () => { };
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };
  cancelCreationDocument = () => {
    // Store.divDisplayed = "List" // TODO SUPPRIMER LE DOC CREE !
  };

  public getAllDocumentsFromData = () => {
    axios
      .get(Store.wsPath + `/1/documents/`, { params: { state: "all" } })
      .then(response => {
        Store.listDocsDisplaying = response.data;
        Store.consulting = false;
        Store.isNeedActionToDocument = false;
        // Store.testtttt = response.data
      })
      .catch(function (error) {
        if (error.response) {
          console.error("Error data : ", error.response.data);
          console.error("Error status : ", error.response.status);
          console.error("Error headers : ", error.response.headers);
        } else if (error.request) {
          console.error("Error request : ", error.request);
        } else {
          console.error("Error message : ", error.message);
        }
        console.error(error.config);
      });
  };

  public onSubmit = async (item: Document, valueSearch: any) => {
    const _idDoc: number = Store.workflow.Document.id;
    axios.post(Store.wsPath + "/1/documents/updateDocumentNew", {
      respDoc: Store.userConnected.id,
      id: _idDoc,
      reference: Store.selectedDoc.reference,
      title: Store.selectedDoc.title,
      fileName: Store.selectedDoc.fileName,
      object: Store.selectedDoc.object,
      numVersion: Store.selectedDoc.numVersion,
      natureId: Store.idNature,
      publicTop: Store.selectedDoc.privateDocument,
      priorite: Store.selectedDoc.priorite,
      // referencialIdx: _idxReferencial, TODO JJ
      // publicDoc: this.state.docPublic, TODO
      state: "En préparation" // TODO rendre dynamique
    })
      .then(() => {
        Store.snackBar.open = true
        Store.snackBar.message = "Le document a été mis à jour"
        this.getAllDocumentsFromData();
        Store.licorne = true
        this.setTimerDaberUnicorn()
        //
      });
  };

  public onChangeExpansion = name => event => {
    if (name == "formulaire") {
      Store.defaultValueExpansionFormulaire = !Store.defaultValueExpansionFormulaire;
    } else if (name == "wf") {
      Store.defaultValueExpansionWf = !Store.defaultValueExpansionWf;
    } else if (name == "group") {
      Store.defaultValueExpansionGroup = !Store.defaultValueExpansionGroup;
    } else if (name == "echanges") {
      Store.defaultValueExpansionEchanges = !Store.defaultValueExpansionEchanges;
    } else if (name == "fiche") {
      Store.defaultValueExpansionFiche = !Store.defaultValueExpansionFiche;
    } else if (name == "docAsso") {
      Store.defaultValueExpansionDocAsso = !Store.defaultValueExpansionDocAsso;
    }
  };

  public getWorkflowByIdW = (idWorkflow: number, idSelectedStep?: number, idSelectedTask?: number) => {
    const startUrl = window.location.href;
    axios.get(Store.wsPath + `/1/workflows/getProjectById`, { params: { id: idWorkflow } }).then(async (responseWorkflow) => {
      Store.modeDocument = 2;
      const workflow: IDBWorkflow = responseWorkflow.data;
      const isIn = this.userIsIn(workflow);
      const gestionnaireDossierId = workflow.Document.userId;
      if (!isIn && Store.userConnected.typeUserLydoc != "ged" && Store.userConnected.typeUserLydoc != "admin" && Store.userConnected.id != gestionnaireDossierId) {
        this.props.history.push('/refresh?redir=document');
      }
      if (workflow.isForm == true && (workflow.Document.dataSharepoint.idForm == undefined || workflow.Document.dataSharepoint.idForm == null)) {
        const endUrl = window.location.href;
        if (startUrl == endUrl) {
          this.getTaskEnCoursUser();
          console.error("Error 'getWorkflowByIdW' : Ancien format de formulaire non pris en charge");
          Store.snackBar = {
            error: true,
            open: true,
            message: " Ancien format de formulaire non pris en charge, redirection en cours..."
          }

        }
      }
      Store.selectedDoc = workflow.Document;
      Store.workflow = workflow;
      if (Store.modeSharepoint == false) {
        Store.contextualmenu.open = "extended";
        Store.contextualmenu.content = "documentVersion";
      }
      Store.isNewDocument = false;
      Store.stateDocumentSelected = "En préparation";
      Store.reloadWorkFlow = true;
      let form: IFormInfo = undefined;
      if (workflow.isForm == true) {
        const result = await axios.get(Store.wsPath + "/1/formulaire/GetFormById", {
          params: {
            idForm: workflow.Document.dataSharepoint.idForm
          }
        });
        form = result.data;

      }
      if (idSelectedStep != undefined && !isNaN(idSelectedStep)) {
        if (idSelectedTask != undefined && !isNaN(idSelectedTask)) {
          if (form != undefined) {
            this.setState({ idSelectedStep: idSelectedStep, idSelectedTask: idSelectedTask, formInfo: form, formFields: form.form_Columns });
          } else {
            this.setState({ idSelectedStep: idSelectedStep, idSelectedTask: idSelectedTask });
          }

        }
        else {
          if (form != undefined) {
            this.setState({ idSelectedStep: idSelectedStep, formInfo: form, formFields: form.form_Columns });
          } else {
            this.setState({ idSelectedStep: idSelectedStep });
          }

        }
      }
      else if (form != undefined) {
        this.setState({ formInfo: form, formFields: form.form_Columns });
      }
      const query = useQuery();
      const idSelectedBannette: string = query.get("b");
      idSelectedBannette != null ? query.set("b", idSelectedBannette) : '';
      (idSelectedStep != undefined && !isNaN(idSelectedStep)) ? query.set("s", idSelectedStep.toString()) : '';
      (idSelectedTask != undefined && !isNaN(idSelectedTask)) ? query.set('t', idSelectedTask.toString()) : '';
      query.get("w") == null ? query.append("w", idWorkflow.toString()) : query.set("w", idWorkflow.toString());
      //const toString = "w=" + idWorkflow + s + t + b;
      window.history.replaceState(null, null, '/document?' + query);
    }).catch(error => {
      const endUrl = window.location.href;
      if (startUrl == endUrl) {
        console.error("Error 'getWorkflowByIdW' : ", error);
        Store.snackBar = {
          error: true,
          open: true,
          message: "Le document avec l'id n'existe pas, redirection en cours..."
        }
        this.getTaskEnCoursUser();
      }

    });
  }

  public getProjetByIdDoc = (idDoc: number, idSelectedStep?: number, idSelectedTask?: number) => {
    axios.get(Store.wsPath + `/1/documents/getVersionDocumentById`, { params: { id: idDoc } }).then(responseDoc => {
      const projetId = responseDoc.data.WfProject.id;
      this.getWorkflowByIdW(projetId, idSelectedStep, idSelectedTask);
    }).catch(error => {
      console.error("Error 'getDocById' : ", error);
      Store.snackBar.error = true;
      Store.snackBar.open = true;
      Store.snackBar.message = "Le document avec l'id '" + idDoc + "' n'existe pas, redirection en cours...";
      this.getTaskEnCoursUser();
    });
  }

  // public getWorkflowByIdWorkflow = (idWorkflow: number,idSelectedStep?:number,idSelectedTask?:number) => {
  //   axios.get(Store.wsPath + `/1/workflows/getProjectById`, { params: { id: idWorkflow } }).then(responseWorkflow => {
  //     Store.modeDocument = 2;
  //     const workflow: IDBWorkflow = responseWorkflow.data;
  //     this.userIsIn(workflow);
  //     Store.selectedDoc = workflow.Document;
  //     Store.workflow = workflow;
  //     if (Store.modeSharepoint == false) {
  //       Store.contextualmenu.open = "extended";
  //       Store.contextualmenu.content = "documentVersion";
  //     }
  //     Store.isNewDocument = false;
  //     Store.stateDocumentSelected = "En préparation";
  //     Store.reloadWorkFlow = true;
  //     if(idSelectedStep != undefined && isNaN(idSelectedStep) == false){
  //       if(idSelectedTask != undefined && isNaN(idSelectedTask)== false){
  //         this.setState({ idSelectedStep: idSelectedStep,idSelectedTask:idSelectedTask });
  //       }
  //       else{
  //         this.setState({ idSelectedStep: idSelectedStep });
  //       }
  //     }
  //     const idSelectedBannette: number = parseInt(this.useQuery().get("b"));
  //     const b = (!isNaN(idSelectedBannette)) ? '&b=' + idSelectedBannette : '';
  //     const s = (!isNaN(idSelectedStep)) ? '&s=' + idSelectedStep : '';
  //     const t = (!isNaN(idSelectedTask)) ? '&t=' + idSelectedTask : '';
  //     const toString = "w=" + idWorkflow + s + t + b;
  //     window.history.replaceState(null, null,'/document?'+ toString);
  //   }).catch(error => {
  //     console.error("Error 'getDocById' : ", error);
  //     Store.snackBar = {
  //       error: true,
  //       open: true,
  //       message: "Le document avec l'id n'existe pas, redirection en cours..."
  //     }
  //     this.getTaskEnCoursUser();
  //   });
  // }

  public convertDateFromDataBaseToDisplaying = (date) => {
    const newDate = new Date(date)
    if ((newDate.getMonth() + 1) < 10) {
      if (newDate.getDate() < 10) {
        return "0" + newDate.getDate() + "/0" + (newDate.getMonth() + 1) + "/" + newDate.getFullYear();
      }
      else {
        return newDate.getDate() + "/0" + (newDate.getMonth() + 1) + "/" + newDate.getFullYear();
      }
    } else {
      if (newDate.getDate() < 10) {
        return "0" + newDate.getDate() + "/" + (newDate.getMonth() + 1) + "/" + newDate.getFullYear();
      }
      else {
        return newDate.getDate() + "/" + (newDate.getMonth() + 1) + "/" + newDate.getFullYear();
      }
    }
  }
  public onResizeWindow = () => {
    if (this.container.current != null) {
      this.setState({ containerSize: this.container.current.clientWidth })
    }
  }
  setTimerDaberUnicorn = () => {
    Store.licorne = true
    setTimeout(() => {
      Store.licorne = false
    }, 2000);
  }



  public renderModeNormal = () => {
    return (
      <div>
        <ValidatorForm
          ref="form"
          onError={errors => console.error("errors", errors)}
          onSubmit={this.onSubmit}
          className={styles.formStyle}
        >

          <div className={styles.groupFilter}>
            <div>
              <TextField
                InputLabelProps={{ shrink: true }}
                label="Libellé"
                variant="outlined"
                className={styles.groupInput}
                hidden
              />
            </div>

            <div>
              <TextField
                InputLabelProps={Store.selectedDoc.title == undefined || Store.selectedDoc.title == null || Store.selectedDoc.title == "" ? { shrink: false, classes: { root: styles.labelFix } } : { shrink: true, classes: { root: styles.labelFix } }}
                // InputProps={{classes: {input : styles.test}}}
                label="Titre"
                variant="outlined"
                className={styles.groupInput}
                value={Store.selectedDoc.title}
                onChange={this.onChangeValue("title")}
                disabled={Store.consulting == true ? true : false}
              />
            </div>

            {/* TODO Gestion des erreurs à mettre en place */}
            <div>
              <TextField
                InputLabelProps={Store.selectedDoc.reference == undefined || Store.selectedDoc.reference == null || Store.selectedDoc.reference == "" ? { shrink: false, classes: { root: styles.labelFix } } : { shrink: true, classes: { root: styles.labelFix } }}
                label="Référence"
                variant="outlined"
                className={styles.groupInput}
                value={Store.selectedDoc.reference}
                onChange={this.onChangeValue("reference")}
                disabled={Store.consulting == true ? true : false}
              />
            </div>

            {/* TODO Gestion des erreurs à mettre en place */}
            <div>
              <TextField
                InputLabelProps={Store.selectedDoc.object == undefined || Store.selectedDoc.object == null || Store.selectedDoc.object == "" ? { shrink: false, classes: { root: styles.labelFix } } : { shrink: true, classes: { root: styles.labelFix } }}
                label="Objet"
                variant="outlined"
                className={styles.groupInput}
                value={Store.selectedDoc.object}
                onChange={this.onChangeValue("object")}
                disabled={Store.consulting == true ? true : false}
              />
            </div>

            {/* TODO Gestion des erreurs à mettre en place */}
            <div>
              <TextField
                InputLabelProps={Store.selectedDoc.numVersion == undefined || Store.selectedDoc.numVersion == null || Store.selectedDoc.numVersion == "" ? { shrink: false, classes: { root: styles.labelFix } } : { shrink: true, classes: { root: styles.labelFix } }}
                label="Version"
                variant="outlined"
                className={styles.groupInput}
                InputProps={{ className: styles.groupInputProps }}
                // InputLabelProps={{ shrink: true }}
                inputProps={{ className: styles.groupInputbackground }}
                value={Store.selectedDoc.numVersion}
                onChange={this.onChangeValue("version")}
                disabled={Store.consulting == true ? true : false}
              />
            </div>

            {/* TODO Gestion des erreurs à mettre en place */}
            {/*TODO JJ ARBRE REFERENCIEL */}

            {/* TODO Gestion des erreurs */}
          </div>

          <div>
            <TextField
              label="Priorité"
              variant="outlined"
              className={styles.groupInput}
              type="number"
              value={Store.selectedDoc.priorite}
              onChange={this.onChangeValue("priorité")}
              disabled={Store.consulting == true ? true : false}
              InputLabelProps={{
                classes: { root: styles.labelFix }
              }}
            />
          </div>


          <div className={Store.modeSharepoint ? styles.contentFormSharepoint : ""}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={Store.consulting == true ? true : false}
            >
              VALIDER
            </Button>
            <Button
              onClick={this.cancelCreationDocument}
              className={styles.btnUser}
              variant="contained"
              color="default"
              disabled={Store.consulting == true ? true : false}
            >
              Annuler
            </Button>
          </div>
        </ValidatorForm>
      </div>
    )
  }
  reloadIframe = isVisible => {
    if (isVisible == true) {
      const iFrame = document.getElementById('sharePointIframe') as HTMLIFrameElement;
      const src = iFrame.src;
      iFrame.src = src;
    }
  }

  editDocNewTab = (doc: any) => {
    // https://yesdev.sharepoint.com/_layouts/15/Doc.aspx?sourcedoc={3d892d12-be67-4c7b-b138-f06f30f6e6d9}&action=
    if (doc.ServerRedirectedEmbedUrl != undefined && doc.ServerRedirectedEmbedUrl != null) {
      const linkEdit = doc.ServerRedirectedEmbedUrl.replace('action=interactivepreview', 'action=edit');
      window.open(linkEdit, "_blank");
    }
  }

  // public updateStepOrientationActive = () => {
  //   let _id = Store.idStepOr
  //   axios.post(Store.wsPath + '/1/Workflows/updateStepOrientationActive', {
  //     id :_id
  //   }).then((response)=>{
  //     Store.reloadWorkFlow
  //   })
  // }
  onchangeOnglet = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ ongletSelect: newValue });

    const query = useQuery();
    const ongletSelected: string = query.get("o");
    if (ongletSelected != undefined && ongletSelected != null && ongletSelected.length > 0) {
      query.set("o", newValue.toString());
    }
    else {
      query.append("o", newValue.toString());
    }

    window.history.replaceState(null, null, '/document?' + query.toString());
  }

  public showPJOnClick = (doc: any, isDocPrincipal: boolean, isResponseDoc: boolean) => {
    const projectEnd: boolean = Store.workflow.state == "Terminé" || Store.workflow.state == "Refusé" ? true : false;
    const isForm: boolean = Store.workflow.isGRC == true || Store.workflow.isForm == true ? true : false;
    if (isResponseDoc == true) {
      doc.site = Store.selectedDoc.dataSharepoint.site;
      doc.Name = doc.File.Name;
      doc.editFileRef = doc.File.ServerRelativeUrl;
      this.formatUrlV2(false, projectEnd, doc, true);
    } else {
      if (isDocPrincipal) {
        if (isForm == true) {
          this.formatUrlV2(isForm, projectEnd, doc, false);
        }
        else {
          const docConcern = projectEnd == true ? doc.finishDoc : doc.copyDocEdit;
          this.formatUrlV2(isForm, projectEnd, docConcern, false);
        }
      }
      else {
        doc.site = Store.selectedDoc.dataSharepoint.site;
        doc.Name = doc.File.Name;
        doc.editFileRef = doc.File.ServerRelativeUrl;
        this.formatUrlV2(isForm, projectEnd, doc, true);
      }
    }
  }
  public formatUrlV2 = (isForm: boolean, projectEnd: boolean, docInfo: any, isPJ?: boolean) => {
    let extensionFichier = "";
    if (isForm == true && isPJ == false) {
      extensionFichier = "Form";
    }
    else if (docInfo.Name != undefined) {
      const extensionFichierSplit = docInfo.Name.split('.');
      extensionFichier = extensionFichierSplit[extensionFichierSplit.length - 1].toUpperCase();
    }

    const myObject: any = { masquerModifDocButton: false };

    switch (extensionFichier) {
      case "PNG":
      case "JPG":
      case "JPEG":
      case "GIF":
      case "TIFF":
      case "SVG":
        const splitSite: string[] = docInfo.site.split("/");
        const urlSite: string = splitSite[0] + "//" + splitSite[2];

        const lastCaract = urlSite.substring(urlSite.length - 1, urlSite.length);
        const site = lastCaract == "/" ? urlSite.substring(0, urlSite.length - 1) : urlSite;
        myObject.link = site + docInfo.editFileRef;
        myObject.masquerModifDocButton = true;
        myObject.format = "image";
        break;

      case "PDF":
        if (docInfo.ServerRedirectedEmbedUrl != undefined && docInfo.ServerRedirectedEmbedUrl.length > 0) {
          myObject.link = docInfo.ServerRedirectedEmbedUrl;
          myObject.masquerModifDocButton = true;
          myObject.format = "autre";
        }
        else {
          myObject.link = "";
          myObject.format = "error";
        }
        break;

      case "Form":
        myObject.link = "form";
        myObject.format = "form";
        break;

      default:
        if (docInfo.ServerRedirectedEmbedUrl != undefined && docInfo.ServerRedirectedEmbedUrl.length > 0) {
          myObject.link = docInfo.ServerRedirectedEmbedUrl;
          myObject.format = "autre";
        }
        else {
          myObject.link = "";
          myObject.format = "error";
          myObject.masquerModifDocButton = true;
        }
        break;
    }
    if (projectEnd || isForm == true) {
      myObject.masquerModifDocButton = true;
    }

    // Paramétres valable pour tous les cas
    myObject.doc = docInfo;
    myObject.idDocLyflow = Store.workflow.Document.id;
    myObject.idProject = Store.workflow.id;

    if (this.state.dataVisionneuse.link != myObject.link || this.state.dataVisionneuse.masquerModifDocButton != myObject.masquerModifDocButton) {
      this.setState({
        dataVisionneuse: myObject
      });
    }
  }



  public onModifDateFinSouhaitee = () => {
    Store.contextualmenu.open = "extended"
    Store.contextualmenu.content = "editDatedeFinsouhaitee"
  }
  public goToHistoricAction = () => {
    this.props.history.push("/HistoricActions/" + Store.selectedDoc.id)
  }

  getColorFlag = (importance: string) => {
    const toReturn = {
      color: "grey",
      description: "Non renseigné"
    };
    switch (importance) {
      case ("Normal"):
        toReturn.color = "limegreen"
        toReturn.description = "Normal"
        break;
      case ("Urgent"):
      case ("Important"):
        toReturn.color = "orange"
        toReturn.description = "Important"
        break;
      case ("Critique"):
      case ("Très important"):
        toReturn.color = "red"
        toReturn.description = "Très important"
        break;
    }
    return toReturn;
  }


  public formatOrientation = (project: IDBWorkflow, allModels: IModelWf[]) => {
    if (project != undefined && project != null && project.id != undefined && allModels.length > 0) {
      project.WfSteps.map(step => step.idModel);
      const allModelStepId:number[] = [];
      const allModelStepName:string[] = [];
      project.WfSteps.forEach((step, pos) => {
        if (allModelStepId.indexOf(step.idModel) == -1) {
          allModelStepId.push(step.idModel);
          const findModel = allModels.find(model => model.id == step.idModel);
          if (findModel != undefined) {
            allModelStepName.push(findModel.name);
          }
        }
      });

      const orientationModel = allModelStepName.join(" - ");
      return orientationModel;
    }
    else {
      return "";
    }
  }

  public render() {
    // TEST AS JJ
    const tempParamUrgence = {
      color: "grey",
      description: "Non renseigné"
    }

    const tempParamImportance = {
      color: "grey",
      description: "Non renseigné"
    }

    if (Store.workflow != undefined && Store.workflow.Document != undefined && Store.workflow.Document.dataSharepoint != undefined) {
      let colorDescriptionUrgence = {
        color: "grey",
        description: "Non renseigné"
      };
      let colorDescriptionImportance = {
        color: "grey",
        description: "Non renseigné"
      };
      // Gestion de l'urgence
      // Si il a été modifié
      if (Store.workflow.Document.dataSharepoint.editMetadata != undefined && Store.workflow.Document.dataSharepoint.editMetadata.sherpa_WorkflowUrgence != undefined) {
        colorDescriptionUrgence = this.getColorFlag(Store.workflow.Document.dataSharepoint.editMetadata.sherpa_WorkflowUrgence);
        // Si il a pas été modifié
      } else if (Store.workflow.Document.dataSharepoint.sherpa_WorkflowUrgence != undefined) {
        colorDescriptionUrgence = this.getColorFlag(Store.workflow.Document.dataSharepoint.sherpa_WorkflowUrgence);
      }
      // Gestion de l'importance 
      // Si il a été modifié
      if (Store.workflow.Document.dataSharepoint.editMetadata != undefined && Store.workflow.Document.dataSharepoint.editMetadata.sherpa_WorkflowImportance != undefined) {
        colorDescriptionImportance = this.getColorFlag(Store.workflow.Document.dataSharepoint.editMetadata.sherpa_WorkflowImportance);
        // Si il a pas été modifié
      } else if (Store.workflow.Document.dataSharepoint.sherpa_WorkflowImportance != undefined) {
        colorDescriptionImportance = this.getColorFlag(Store.workflow.Document.dataSharepoint.sherpa_WorkflowImportance)
      }
      tempParamUrgence.color = colorDescriptionUrgence['color'];
      tempParamUrgence.description = colorDescriptionUrgence['description'];
      tempParamImportance.color = colorDescriptionImportance['color'];
      tempParamImportance.description = colorDescriptionImportance['description'];
    }

    let userIsIn = false;
    // GRC Hide Fields "grc_Objet","grc_traiteParOrganiseur", "grc_DateDeRelance", "grc_MotifRefus", "grc_StatutPrecedent", "grc_Montant", "grc_NumeroFacture", "grc_Statut", "grc_IdUserGRCDB"
    // Recrutement Hide Fields  "recruit_Objet","recruit_traiteParOrganiseur", "recruit_DateDeRelance", "recruit_MotifRefus", "recruit_StatutPrecedent", "recruit_Statut", "recruit_IdUserDB"

    const hideFields = ["Title", "PJ_IdentiteCard", "sherpa_WorkflowBouton", "sherpa_WorkflowEtat", "sherpa_WorkflowModele", "sherpa_WorkflowPriorite", "sherpa_WorkflowPJ", "sherpa_WorkflowUrgence", "sherpa_WorkflowImportance", "sherpa_WorkflowContientDocumentsOriginaux", "sherpa_WorkflowGestionnaireDuDossier", "sherpa_WorkflowIdChrono", "sherpa_WorkflowDesignation"];
    if (Store.allTypeForm != undefined && Store.allTypeForm.length > 0) {
      Store.allTypeForm.forEach(formType => {
        hideFields.push(...formType.params.hideFields);
      });
    }
    if (Store.workflow != undefined && Store.workflow.id != undefined && Store.workflow.id != null) {
      userIsIn = this.userIsIn(Store.workflow);
    }
    if (Store.workflow.id != undefined) {
      return (
        <div className={styles.wrapperContaint}>
          {Store.differe ?
            <div className={styles.banettes}>
              Différés
            </div>
            : ""}

          <div className={styles.listDzGlobal}>
            {/*<ContextualMenu2 />*/}
            {Store.modeSharepoint == false ?
              <UploadZone />
              :
              <div />
            }
            {this.state.allBannetteAndContent != undefined && this.state.allBannetteAndContent.length > 0 ?
              <TaskSlider allBannetteAndContent={this.state.allBannetteAndContent} reloadTable={Store.reloadTab} reload={Store.reloadWorkFlow} userConnectedId={Store.userConnected.id} />
              :
              <Spinner label="Chargement des banettes..." size={32} style={{ margin: "40px" }} />
            }

            <div className={styles.pageContainer}>
              {Store.workflow != undefined && Store.workflow.id != undefined && Store.workflow.id != null ?
                <div className={styles.titleModelDocument} style={{ background: Store.themeParam.backgroundPrimary }}>
                  <Icon style={{ color: tempParamUrgence.color, fontSize: '18px', marginLeft: '10px', marginTop: '3px' }} className={styles.iconStyles} title={tempParamUrgence.description} >flag</Icon>
                  <Icon style={{ color: tempParamImportance.color, fontSize: '18px', marginLeft: '7px', marginRight: '7px', marginTop: '3px' }} title={tempParamImportance.description} className={styles.iconStyles} >folder_open</Icon>
                  <h5 style={{ display: "flex", justifyContent: "space-between", width: "100%" }} title={Store.workflow.name}>
                    <div>
                      {Store.modeSharepoint ? Store.workflow.name : Store.selectedDoc.title} : {Store.workflow.state}
                    </div>
                    <div>
                      <span className={styles.partDateFinSouhaitee}>
                        Date de fin : {Store.workflow.dateFinSouhaitee != undefined ? this.convertDateFromDataBaseToDisplaying(Store.workflow.dateFinSouhaitee) : ""}
                        {
                          Store.workflow.state != "Terminé" && Store.workflow.state != "Refusé" ?
                            <Icon onClick={this.onModifDateFinSouhaitee} style={{ color: "white", fontSize: '16px' }} className={styles.iconStyles} >edit</Icon>
                            :
                            ""
                        }
                      </span>
                    </div>
                  </h5>
                </div>
                :
                <div className={styles.titleModelDocument} style={{ background: Store.themeParam.backgroundPrimary }}><h5 title="Pas de circuit">Pas de circuit</h5></div>
              }
              <Grid container>
                <Grid item lg={7} md={7} xs={12} className={styles.documentContainer}>
                  {!Store.modeSharepoint ?
                    <div style={this.state.containerSize != null && this.state.containerSize <= 630 ? { height: "100%", width: "100%" } : { height: "100%", width: "100%" }} /* style={this.container.current != null && this.container.current.clientWidth <= 630 ? {height: "85vh", width: "100%", marginTop: "30px"}: {height: "85vh", width: "47%", marginTop: "30px"}} */>
                      {
                        Store.selectedDoc != undefined
                          ?
                          <iframe
                            src={Store.OwaPath + encodeURI("http://192.168.1.107:9000/static/" + Store.selectedDoc.fileName)}
                            width="100%"
                            height="100%"
                          />
                          : <div />
                      }
                    </div>
                    :
                    <div className={styles.modeSharepointContainer}>
                      {
                        this.state.dataVisionneuse.link != null && this.state.dataVisionneuse.link != undefined && this.state.dataVisionneuse.link != "" ?
                          <React.Fragment>
                            <div style={{ height: "90%", width: "100%" }}>
                              {/* <Button onClick={() => this.goToHistoricAction()} variant="contained" color="primary">Acceder à l'historique des actions du document</Button> */}
                              {this.state.dataVisionneuse.format == "image" ?
                                <React.Fragment>
                                  {/*<div className={styles.documentImage} style={{ height: "100%", backgroundImage: "url(" + this.state.dataVisionneuse.link + ")" }}></div>*/}
                                  <img style={{ width: "100%", height: "auto" }} src={this.state.dataVisionneuse.link} alt="image" />
                                </React.Fragment>
                                :
                                this.state.dataVisionneuse.format == "form" && Store.selectedDoc.dataSharepoint != undefined ?
                                  <div style={{ maxWidth: "500px", width: "100%", margin: "0 auto" }}>
                                    {this.state.formInfo != undefined ?
                                      <RenderForm controlMode={1} formInfo={this.state.formInfo} fieldsInfo={this.state.formFields} updated={false} dataSharepoint={Store.selectedDoc.dataSharepoint} />
                                      : <React.Fragment></React.Fragment>}

                                  </div>
                                  :
                                  <PageVisibility onChange={this.reloadIframe}>
                                    <iframe
                                      src={this.state.dataVisionneuse.link.replace("WopiFrame.aspx", "WopiFrame2.aspx")}
                                      width="100%"
                                      height="100%"
                                      style={{ border: 0 }}
                                      id="sharePointIframe"
                                    />
                                  </PageVisibility>
                              }
                            </div>
                            {this.state.dataVisionneuse.masquerModifDocButton == false && Store.myStepTaskSelectionned ?
                              <div style={{ margin: "20px 0" }} >
                                <Button disabled={(Store.userConnected.typeUserLydoc != "admin" && Store.userConnected.typeUserLydoc != "ged" && this.userIsIn(Store.workflow) == false) || (Store.myStepTaskSelectionned && Store.myStepTaskSelectionned.state != "En cours") || (Store.myStepTaskSelectionned && Store.myStepTaskSelectionned.typeTask && Store.myStepTaskSelectionned.typeTask.label != "Contribution") ? true : false} onClick={() => this.editDocNewTab(this.state.dataVisionneuse.doc)} variant="contained" color="primary" className={styles.rainbow}>Modifier le document</Button>
                              </div>
                              :
                              <React.Fragment></React.Fragment>
                            }
                          </React.Fragment>
                          :

                          ""
                      }
                    </div>
                  }

                </Grid>
                <Grid item lg={5} md={5} xs={12} className={styles.contentForm} /*style={this.state.containerSize != null && this.state.containerSize <= 630 ? { width: "100%" } : { width: "50%" }}/* style={this.container.current != null && this.container.current.clientWidth <= 630 ? {width:"100%"}: {width:"50%"}} */>
                  <div>
                    <div className={styles.contentAlert}>
                      {Store.workflow.Document != undefined && Store.workflow.Document.dataSharepoint != undefined && Store.workflow.Document.dataSharepoint.sherpa_WorkflowContientDocumentsOriginaux != undefined && Store.workflow.Document.dataSharepoint.sherpa_WorkflowContientDocumentsOriginaux == true ?
                        <Alert severity="error">Ce document contient des documents originaux</Alert>
                        : ""}
                    </div>


                    <div className={styles.contentTabs} style={{ background: Store.themeParam.backgroundPrimary, marginBottom: "10px" }}>
                      <Tabs
                        value={this.state.ongletSelect}
                        onChange={this.onchangeOnglet}
                        aria-label="Onglet"
                        className={styles.onglet}
                      >
                        <Tab label="Circuit" />
                        <Tab label="Dossier" />
                      </Tabs>
                    </div>
                    <TabPanel value={this.state.ongletSelect} index={1}>
                      {Store.modeSharepoint == true ? <FormulaireSharepoint viewMode={Store.workflow.state == "Refusé" || Store.workflow.state == "Terminé" ? true : false} document={Store.workflow.Document} projet={Store.workflow} afficherChampsVueListe={Store.workflow.isGRC == true || Store.workflow.isForm == true ? false : true} isForm={Store.workflow.isGRC == true || Store.workflow.isForm == true ? true : false} authMode={Store.authMode} /> : this.renderModeNormal()}
                      {/* {Store.sharepointDocEditData.Id != undefined ? this.renderModeSharePoint() : this.renderModeNormal()} */}
                    </TabPanel>
                  </div>
                  <TabPanel value={this.state.ongletSelect} index={0}>
                    {Store.modeSharepoint == true ?

                      <DocAssocie showPJOnClick={this.showPJOnClick} selectedDoc={Store.workflow.Document} project={Store.workflow} />

                      : ""}
                    <hr className={styles.circuitSplit} />
                    {Store.isNewDocument == true || (Store.isNewDocument == false /* && (Store.stateDocumentSelected == "En préparation" || Store.stateDocumentSelected == "Refusé")*/) ?
                      <div className={styles.circuitPanel} >
                        <div className={styles.backgroundTable} >
                          <div className={styles.titleElement}>
                            <span>Type de circuit : </span>
                            {
                              Store.allModelWf.length > 0 ? <span style={{ fontSize: "1rem" }}>{this.formatOrientation(Store.workflow, Store.allModelWf)}</span> : <React.Fragment />
                            }
                          </div>
                        </div>
                        <div className={styles.expansionPanelDetailMUi} >
                          {Store.workflow != undefined && Store.workflow.id != undefined ?
                            <div>
                              {/* <Workflow  selectedDoc={Store.workflow.Document} reload={Store.reloadWorkFlow} onChangeTab={this.onchangeOnglet} /> */}
                              <WorkflowNew workflow={Store.workflow} idSelectedStep={this.state.idSelectedStep} idSelectedTask={this.state.idSelectedTask} mode={"full"} />
                            </div>
                            : ""
                          }
                        </div>
                      </div>
                      : ""}
                  </TabPanel>
                  <div style={{ marginTop: "20px" }}>
                    <PostIt
                      postIt={Store.workflow.WfPostIts != undefined && Store.workflow.WfPostIts != null && Store.workflow.WfPostIts.length > 0 ? Store.workflow.WfPostIts[0] : null}
                      taskAndPostIts={
                        Store.myStepTaskSelectionned != undefined && Store.myStepTaskSelectionned.id != undefined &&
                          Store.workflow.WfSteps.filter(st => st.id == Store.myStepTaskSelectionned.WfStep.id).length > 0 &&
                          Store.workflow.WfSteps.filter(st => st.id == Store.myStepTaskSelectionned.WfStep.id)[0].WfTasks.length > 0 &&
                          Store.workflow.WfSteps.filter(st => st.id == Store.myStepTaskSelectionned.WfStep.id)[0].WfTasks.filter(tas => tas.id == Store.myStepTaskSelectionned.id).length > 0 &&
                          Store.workflow.WfSteps.filter(st => st.id == Store.myStepTaskSelectionned.WfStep.id)[0].WfTasks.filter(tas => tas.id == Store.myStepTaskSelectionned.id)[0].TaskPostIts != undefined ? Store.workflow.WfSteps.filter(st => st.id == Store.myStepTaskSelectionned.WfStep.id)[0].WfTasks.filter(tas => tas.id == Store.myStepTaskSelectionned.id)[0] : null
                      }
                      addMessagePostIt={Store.addMessagePostIt}
                      workflowEnd={Store.workflow.state == "Terminé" || Store.workflow.state == "Refusé" ? true : false}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            {Store.licorne ?
              <div style={{ display: "flex", height: "100vh", width: "100vw", alignItems: "center", justifyContent: "center", position: "fixed", top: 0, left: 0, zIndex: 1000 }}>
                <img src="./images/dab.gif" alt="licorne qui dab INCROYABLE"></img>
              </div>
              :
              <div></div>
            }
          </div>
        </div>
      );
    }
    else {
      return ("")
    }


  }
}

export default withRouter(Form);