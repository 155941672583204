import React from 'react';
import Popover from '@material-ui/core/Popover';
import Store from '../store/store'
import { observer } from "mobx-react";
import axios from "axios";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import Icon from '@material-ui/core/Icon';
import Modal from '@material-ui/core/Modal';
import { getAllUsers } from "../store/store";
import SelectReact, { createFilter } from 'react-select';
import { MenuList, customMultipleValue } from './../helper/reactSelectCustomRender'
import Spinner from '../Spinner/Spinner';
import { IDBWfStep, IDBWfTask } from '../Workflow/interfaces/IWorkflow';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
const styles = require('./styles/stylePopoverTask.scss');
const modal = require('../../styles/modal.scss');
import { RouteComponentProps, withRouter } from 'react-router-dom';
import VisibilityOutlined from '@material-ui/icons/VisibilityOutlined';



interface Props {
  step: IDBWfStep;
  titre?: string;
  description?: string;
}

interface State {
  anchorElPopOver?: any;
  isOpenPopover?: boolean;
  openDeleteModal: boolean;
  loadingDelete: boolean;
  selectedTask: IDBWfTask;
}

@observer
class PopoverTaskCollab extends React.Component<RouteComponentProps & Props, State>{
  constructor(props: RouteComponentProps & Props) {
    super(props)
    this.state = {
      anchorElPopOver: null,
      isOpenPopover: false,
      openDeleteModal: false,
      loadingDelete: false,
      selectedTask: null
    }
  }


  // Fonction appelée lors de l'ouverture du PopOver
  onOpenPopover = (event) => {
    event.stopPropagation();
    const currentTarget = event.currentTarget;
    this.setState({ isOpenPopover: true, anchorElPopOver: currentTarget });
  }

  // Fonction appelée lors de la fermeture du PopOver
  closePopover = (event) => {
    event.stopPropagation();
    this.setState({ isOpenPopover: false, anchorElPopOver: event.currentTarget });
  }

  deleteUserInGroup = (row) => { }

  public toolbox = (cell: any, row: any) => {
    let showDelete: boolean = false
    if (this.props.step != undefined && Store.workflow.state != "Refusé" && Store.workflow.state != "Terminé" && this.props.step.state != "Terminée") {
      if (Store.userConnected.typeUserLydoc == "ged" || Store.userConnected.typeUserLydoc == "admin" || (Store.workflow.Document != undefined && Store.userConnected.id == Store.workflow.Document.userId)) {
        // Si la tâche est required
        if (this.props.step.isRequired == true && this.props.step.WfTasks != undefined && this.props.step.WfTasks != null && this.props.step.WfTasks.length > 1) {
          showDelete = true;
        } else if (this.props.step.isRequired == false) { // Si elle ne l'est pas
          showDelete = true;
        }
      }
      else if (Store.userConnected.id == row.userIdx) {
        if (row.typeTask && row.typeTask.label == "Contribution") {
          // Si la tâche est required
          if (this.props.step.isRequired == true && this.props.step.WfTasks != undefined && this.props.step.WfTasks != null && this.props.step.WfTasks.length > 1) {
            showDelete = true;
          }
          // Si elle l'est pas
          else if (this.props.step.isRequired == false) {
            showDelete = true;
          }
        }
      }
    }

    // Si on à les droits pour supprimer on affiche la corbeille sinon rien
    if (showDelete == true) {
      return (
        <div className={styles.listActionContainer}>
          <div onClick={() => this.openModalDeleteTask(row)} title="Supprimer la tâche"><Icon className={styles.iconDelete}>delete</Icon></div>
        </div>
      );
    }
    else {
      return <React.Fragment />
    }
  }

  // Formater l'utilisateur pour l'affichage
  formatUserOnTask = (cell: any, row: IDBWfTask) => {
    if (row.User != undefined && row.User != null) {
      if (row.isDelegate && row.UserFrom != undefined && row.UserFrom != null) {
        const titleHover = "\'" + row.User.email + "\'" + " délégué(e) de " + row.UserFrom.firstname + " " + row.UserFrom.lastname;
        return (
          <div className={styles.userInfo}>
            <span title={titleHover} style={row.isDelegate ? { fontStyle: 'italic' } : {}}>{row.User.firstname + " " + row.User.lastname}</span>
            {row.typeTask.label != "Contribution" ? <VisibilityOutlined className={styles.iconStatutTask} /> : <React.Fragment />}
          </div>
        )
      }
      else {
        return (
          <div className={styles.userInfo}>
            <span title={row.User.email}>{row.User.firstname + " " + row.User.lastname}</span>
            {row.typeTask.label != "Contribution" ? <VisibilityOutlined className={styles.iconStatutTask} /> : <React.Fragment />}
          </div>   
        )
      }
    }
    else {
      return <span>Utilisateur introuvable</span>
    }
  }

  // Fonction de recherche custom pour bootstraptable
  formatUserSearch = (cell: any, row: IDBWfTask) => {
    if (row.User != undefined && row.User != null) {
      return row.User.firstname + " " + row.User.lastname
    }
    else {
      return "Utilisateur introuvable"
    }
  }

  public onDeleteTaskById = async () => {
    this.setState({ loadingDelete: true }, async () => {
      try {
        const dateDoneConvertTodb = new Date().toLocaleString('en', { hour12: false });
        const responseDelete = await axios.post(Store.wsPath + "/1/workflows/deleteTaskProject", {
          id: this.state.selectedTask.id,
          idUserConnected: Store.userConnected.id,
          idDoc: Store.workflow.idxdoc,
          dateDone: dateDoneConvertTodb,
          modeSharePoint: Store.modeSharepoint,
          isGRC: Store.workflow.isGRC,
          isForm: Store.workflow.isForm,
          formType: Store.workflow.formType
        });

        if (responseDelete.status == 200) {
          Store.snackBar = {
            error: false,
            message: "Tâche supprimée",
            open: true
          }
          this.props.history.push("/refresh?redir=document?w=" + Store.workflow.id);
        }
        else {
          Store.snackBar = {
            error: true,
            message: "Une erreur est survenue lors de la suppression de la tâche",
            open: true
          }
        }
      } catch (error) {
        console.error("Error 'deleteTaskProject' : ", error);
        Store.snackBar = {
          error: true,
          message: "Une erreur est survenue lors de la suppression de la tâche",
          open: true
        }
      }
      this.closeModalDeleteTask();
    })
  }

  // Fonction appelée pour fermer la modale de suppression
  public closeModalDeleteTask = () => {
    this.setState({ openDeleteModal: !this.state.openDeleteModal, selectedTask: null, loadingDelete: false });
  }

  // Fonction appelée lors du clic sur la corbeille pour supprimer une tâche
  public openModalDeleteTask = (task:IDBWfTask) => {
    console.log("task selected : ", task);
    this.setState({ openDeleteModal: !this.state.openDeleteModal, selectedTask: task });
  }

  render() {
    const options = {
      sortIndicator: true,
      noDataText: 'Aucun utilisateur à afficher',
      sizePerPage: 10,
    };
    const isStepOrientation:boolean = this.props.step.isStepOrientation;
    return (
      <div className={styles.popOverTaskContainer}>
        <div className={styles.iconEdit} onClick={this.onOpenPopover} title={isStepOrientation ? "Consulter les autres utilisateurs de l'étape d'orientation" : "Consulter les autres utilisateurs de l'étape collaborative"}>
          <Icon style={{ fontSize: 20 }}>people</Icon>
        </div>
        {this.state.anchorElPopOver != null ?
          <Popover
            open={this.state.isOpenPopover}
            anchorEl={this.state.anchorElPopOver}
            onClose={this.closePopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <div className={styles.popOverContent}>
              <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "bold", marginTop: "16px", fontSize: "17px", marginLeft: "2px" }}>
                <div>
                  {
                    this.props.titre != undefined && this.props.titre != null ?
                      this.props.titre
                      :
                      isStepOrientation == true ?
                      "Étape d'orientation collaborative"
                      :
                      "Étape collaborative"
                  }
                </div>
                <div style={{ cursor: "pointer" }} onClick={this.closePopover} title="Fermer">
                  <Icon >close</Icon>
                </div>
              </div>
              {
                this.props.description != undefined && this.props.description != null ?
                  <p style={{ marginTop: "10px" }}>{this.props.description}</p>
                  :
                  <p style={{ marginTop: "10px" }}>{isStepOrientation ? "Toutes les personnes ci dessous peuvent valider l'orientation." : "Toutes les personnes ci dessous peuvent valider cette étape."}</p>
              }
              {
                this.props.step.WfTasks != undefined && this.props.step.WfTasks != null ?
                  <BootstrapTable
                    multiColomnSearch
                    data={this.props.step.WfTasks != undefined && this.props.step.WfTasks != null ? this.props.step.WfTasks : []}
                    searchPlaceholder='Rechercher'
                    search={true}
                    options={options}
                    version='4'
                    bordered={true}
                  >
                    <TableHeaderColumn isKey={true} dataField='id' hidden dataAlign='center' headerAlign='center'>id</TableHeaderColumn>
                    <TableHeaderColumn datasort={true} dataField='userIdx' dataAlign='center' headerAlign='center' filterValue={this.formatUserSearch} dataFormat={this.formatUserOnTask}>Utilisateur</TableHeaderColumn>
                    <TableHeaderColumn width="60px" hidden={this.props.step != undefined && Store.workflow.state != "Refusé" && Store.workflow.state != "Terminé" && this.props.step.state != "Terminée" ? false : true} headerAlign='center' dataField='lastname' tdStyle={{ padding: '0', verticalAlign: 'inherit' }} dataFormat={this.toolbox.bind(this)}></TableHeaderColumn>
                  </BootstrapTable>
                  :
                  <React.Fragment />
              }
            </div>
          </Popover>
          :
          <React.Fragment />
        }

        <Modal
          open={this.state.openDeleteModal}
          onClose={this.closeModalDeleteTask}
          className={modal.modal}>
          <div className={modal.modalContent}>
            {
              this.state.selectedTask != undefined && this.state.selectedTask != null && this.state.selectedTask.User != undefined && this.state.selectedTask.User != null ?
                <Typography variant="h5">
                  Supprimer la tâche de {this.state.selectedTask.User.firstname + " " + this.state.selectedTask.User.lastname}
                </Typography>
              :
              <Typography variant="h5">
                Suppression d'une tâche
              </Typography>
            }
            <br />
            Vous allez supprimer cette tâche. Êtes-vous sûr de cette action ?
            <div className={modal.modalButton}>
              <Button
                onClick={this.closeModalDeleteTask}
                className={modal.button}
                variant="contained"
                color="secondary"
                disabled={this.state.loadingDelete == true ? true : false}
              >
                Annuler
              </Button>
              <Button
                onClick={this.onDeleteTaskById}
                className={modal.button}
                variant="contained"
                color="primary"
                disabled={this.state.loadingDelete == true ? true : false}
              >
                {
                  this.state.loadingDelete == true ?
                    <Spinner className={styles.icon} size={24} />
                    :
                    "Valider"
                }
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

export default withRouter(PopoverTaskCollab);